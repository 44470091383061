// import { TConfig } from './config';
import { RunHealth, RunStatus } from './enums';
import { UUID } from '../../utils/uuidGen';
import moment from 'moment';
import { KeyAsString } from './index';
import { ReactNode } from 'react';

type TGroupRun = {
  group_id: string;
  id: string;
  run_id: string;
};

export type TRunStringKeys = KeyAsString<TRun>;

export interface TRun {
  id: string | NonNullable<ReturnType<typeof UUID>>;
  letter?: string;
  deleted?: boolean;
  calibration?: string | null;
  combined_mass?: null;
  cp_corr?: null;
  crr?: null;
  prefix?: string | null;
  description?: string | null;
  suffix?: string | null;
  gates?: any;
  gates_id?: any;
  cda_ref?: any;
  groups_runs?: TGroupRun[];
  hidden?: boolean | null;
  notes?: string | null;
  power_meter_slope?: null;
  rider_id?: string | null;
  route?: null;
  run_health?: null | RunHealth;
  run_number?: number | null;
  run_ts?: string | null;
  run_ts_tz?: string | null;
  run_tz?: string | null;
  run_status?: RunStatus;
  reprocess?: boolean;
  wheel_circumference?: null;
  xw_slope?: null;
  yaw_offset?: null;
  garmin_id?: string;
  garmin_user_id?: string;
}

export const typedParam =
  <T, K extends keyof Partial<T>>(entity: T) =>
  (param: K): T[K] | null =>
    entity ? entity[param] : null;

export const reactNodeOnly = (value: any): ReactNode => (typeof value !== 'object' ? value : null);
export const defaultRun = (props: Partial<TRun> = {}): TRun => ({
  id: UUID(),
  run_ts: moment(props.run_ts).add(1, 'minute').format(),
  ...props,
});
