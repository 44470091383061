import React, { ReactElement } from 'react';
import SessionConfigsList from '../Sessions/List/SessionConfigsList';
import SessionRunsList from '../Sessions/List/SessionRunsList';
import { StreamlinesRun } from '../../icons/StreamlinesRun';
import { StreamlinesConfig } from '../../icons/StreamlinesConfig';
import { StreamlinesSession } from '../../icons/StreamlinesSession';
import { StreamlinesCalibration } from '../../icons/StreamlinesCalibration';
import SettingRoutes from '../Settings/_routes';
import { StreamlinesRider } from '../../icons/StreamlinesRider';
import { TUser } from '../../common/types/user';

export type CirrusRoute = {
  path: string;
  icon?: ReactElement;
  element: ReactElement | ReactElement[];
  label: string;
  hidden?: boolean;
  nestedRoutes?: CirrusRoute[];
};

const Sessions = React.lazy(() => import('../Sessions' /* webpackChunkName: "sessions" */));
const Runs = React.lazy(() => import('../Runs' /* webpackChunkName: "runs" */));
const Configs = React.lazy(() => import('../Configs' /* webpackChunkName: "configs" */));
const Calibrations = React.lazy(() => import('../Calibrations' /* webpackChunkName: "calibrations" */));
const Garmin = React.lazy(() => import('../Garmin' /* webpackChunkName: "garmin" */));
const ViewRuns = React.lazy(() => import('../ViewRuns') /* webpackChunkName: "view.runs" */);
const EditConfigModal = React.lazy(
  () => import('../Configs/EditConfig/index') /* webpackChunkName: "editConfigModal" */,
);
const EditRuns = React.lazy(() => import('../Runs/EditRun/index') /* webpackChunkName: "editRunsModal" */);
const EditCalibrationsModal = React.lazy(
  () => import('../Calibrations/EditCalibrations/index') /* webpackChunkName: "editCalibrationModal" */,
);

const TeamView = React.lazy(() => import('../TeamView') /* webpackChunkName: "teamview" */);
const EditSession = React.lazy(() => import('../Sessions/EditSession/index') /* webpackChunkName: "editSession" */);

const DashboardModal = React.lazy(() => import('../Dashboard/DashboardModal') /* webpackChunkName: "dashboardModal" */);
const PlotterModal = React.lazy(() => import('../Plotter/PlotterModal') /* webpackChunkName: "plotterModal" */);

const ConfigAndRuns = [
  {
    path: '/configs',
    label: 'Configs',
    icon: <StreamlinesConfig />,
    element: <Configs />,
  },
  {
    path: '/runs',
    label: 'Runs',
    icon: <StreamlinesRun />,
    element: <Runs />,
  },
];

const CirrusRoutes = (): CirrusRoute[] => [
  {
    path: '/session',
    label: 'Sessions',
    icon: <StreamlinesSession />,
    element: <Sessions />,
    nestedRoutes: [
      { path: 'new/:isNew', label: 'Create New Session', element: <></> },
      { path: '/session/edit/:editId', label: 'Edit Session', element: <></> },
      {
        path: '/session/:sessionUuid',
        label: 'Session Configs',
        element: <SessionConfigsList />,
        nestedRoutes: [
          {
            path: 'configs/:configUuid',
            label: 'Session Runs',
            element: <SessionRunsList />,
            nestedRoutes: [{ path: 'runs/:runId', label: 'Run', element: [] }],
          },
        ],
      },
    ],
  },
  ...ConfigAndRuns,
  {
    path: '/calibrations',
    label: 'Calibrations',
    element: <Calibrations />,
    icon: <StreamlinesCalibration />,
  },
  {
    path: '/team',
    label: 'Riders',
    element: <TeamView />,
    icon: <StreamlinesRider />,
    nestedRoutes: [
      { path: 'rider/edit/:riderId', label: 'Rider Edit', element: <></> },
      { path: 'rider/create', label: 'Rider Create', element: <></> },
    ],
  },
];

export const CommonRoutes = (user: TUser): CirrusRoute[] => [
  {
    path: '/session/edit-configs/:id/',
    label: 'Edit Session Configs',
    element: <EditSession />,
  },
  {
    path: '/configs/edit/:configId',
    label: 'Edit Config',
    element: <EditConfigModal />,
  },
  {
    path: '/configs/new/:isNew',
    label: 'Create Config',
    element: <EditConfigModal />,
  },
  {
    path: '/runs/edit/:runId',
    label: 'Edit Run',
    element: <EditRuns />,
  },
  {
    path: '/runs/new/:isNew',
    label: 'Create Run',
    element: <EditRuns />,
  },
  {
    path: '/runs/view/dashboard',
    label: 'View Details',
    element: <DashboardModal />,
  },
  {
    path: '/runs/view/plotter',
    label: 'Plotter',
    element: <PlotterModal />,
  },
  {
    path: '/session/view/plotter',
    label: 'Plotter',
    element: <PlotterModal />,
  },
  {
    path: '/calibrations/edit/:calibrationId',
    label: 'Edit Calibration',
    element: <EditCalibrationsModal />,
  },
  {
    path: '/calibrations/new/:isNew',
    label: 'Create Calibration',
    element: <EditCalibrationsModal />,
  },
  { path: '/view-results', label: 'View Results', element: <ViewRuns /> },
  { path: '/garmin-connect', label: 'Garmin', element: <Garmin /> },
  SettingRoutes(user),
];

export default CirrusRoutes;
