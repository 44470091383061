import { useContext } from 'react';
import { AppModalContext, TModalContext } from './provider';

export default function useSimpleModal(): TModalContext {
  const context = useContext(AppModalContext);

  if (!context) {
    throw Error('useSimpleModal has to be used inside AppModalProvider');
  }

  return context;
}
