import ErrorBoundary from '../../ErrorBoundary';
import { PropsWithChildren } from 'react';
import { ListViewRow } from '../ListViewRow';
import { ListViewCell } from '../index';

export default function ListViewError(props: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallback={
        <table>
          <tbody>
            <ListViewRow>
              <ListViewCell>
                <span>There was a error processing data please contact help@streamlines.aero</span>
              </ListViewCell>
            </ListViewRow>
          </tbody>
        </table>
      }
    >
      {props.children}
    </ErrorBoundary>
  );
}
