import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';

/* eslint-disable max-len */
export const StreamlinesPlotter = ({
  fill = 'currentColor',
  stroke = 'none',
  size,
}: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        {...{ fill, stroke }}
        transform="translate(0,512) scale(0.1,-0.1)"
        d="M21.489,5106.337c-1.04-0.371-2.666-1.479-3.545-2.368c-4.097-3.784-3.862,7.041-3.862-163.125v-157.495l1.704-2.48
		c0.894-1.357,2.817-3.281,4.155-4.18l2.52-1.699h157.705h157.715l2.49,1.699c7.715,5.195,7.881,14.619,0.469,20.107l-2.09,1.553
		l-150.518,0.225l-150.425,0.146v149.258v149.248l-1.631,3.257c-0.967,1.777-2.598,3.931-3.716,4.663
		C30.166,5106.855,24.16,5107.529,21.489,5106.337z"
      />
      <path
        {...{ fill, stroke }}
        transform="translate(0,512) scale(0.1,-0.1)"
        d="M329.922,5029.228c-1.016-0.371-29.736-28.618-63.77-62.646l-61.973-61.914l-26.235,28.926
		c-28.555,31.426-29.004,31.802-35.435,30.835c-2.241-0.293-4.17-1.333-6.309-3.184c-20.029-17.793-73.042-67.095-73.784-68.657
		c-4.375-9.57,5.928-19.951,15.649-15.723c1.338,0.596,13.564,11.338,27.28,23.945c13.73,12.539,27.959,25.586,31.66,28.984
		l6.748,6.157l26.025-28.618c14.39-15.791,27.144-29.277,28.472-30.098c2.744-1.631,6.963-1.855,10.303-0.371
		c1.406,0.596,30.254,28.906,69.238,67.993c58.574,58.711,66.982,67.397,67.764,70.21
		C348.252,5024.189,338.896,5032.714,329.922,5029.228z"
      />
    </Svg>
  );
};
/* eslint-enable max-len */
