import { createContext, Dispatch, ReactElement, useEffect, useReducer } from 'react';
import { runsReducer } from './reducer';
import { TBaseContext } from '../types';
import { LocalStorageHandler } from '../../utils/toLocalStorage';
import { LocalStorageKeys } from '../../common/interfaces';
import { TRunsContext, TRunSort } from './_types';
import { KeyedMutator } from 'swr';
import { useRunsGet } from '../ajax/runs';

//this method is a copy of old method and probably will be removed
const defaultEditedRuns = (): string[] => {
  return LocalStorageHandler.get(LocalStorageKeys.EDITED_RUNS) ?? [];
};

const defaultMergedRuns = (): Record<string, any> => {
  return LocalStorageHandler.get(LocalStorageKeys.MERGED_RUNS) ?? {};
};

const defaultState: TRunsContext = {
  runs: [],
  unprocessedRuns: [],
  showHidden: true,
  runsById: {},
  selectedRuns: defaultEditedRuns(),
  mergedSelectedRuns: defaultMergedRuns(),
  isLoading: false,
  sortBy: TRunSort.DATE,
  dataLoading: new Set(),
  drawers: {},
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-empty-function
export const RunsContext = createContext<[TRunsContext, Dispatch<any>, KeyedMutator<any>] | null>(null);
RunsContext.displayName = 'RunsContext';

// preload(RunsFetcher.key, RunsFetcher.fetcher);

export default function RunsProvider({ children, ...props }: TBaseContext): ReactElement {
  const [state, runsDispatch] = useReducer(runsReducer, defaultState);

  const { mutate } = useRunsGet(runsDispatch);

  useEffect(() => {
    LocalStorageHandler.save(LocalStorageKeys.EDITED_RUNS, state.selectedRuns);
    LocalStorageHandler.save(LocalStorageKeys.MERGED_RUNS, state.mergedSelectedRuns);
  }, [state.selectedRuns]);

  return <RunsContext.Provider value={[state, runsDispatch, mutate]} {...{ ...props, children }} />;
}
