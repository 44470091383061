import ErrorBoundary from '../../ErrorBoundary';
import { PropsWithChildren } from 'react';
import { ListViewCell } from '../index';

export default function ListCellError(props: PropsWithChildren<{ colSpan?: number; rowSpan?: number }>) {
  return (
    <ErrorBoundary
      fallback={
        <ListViewCell {...props}>
          <div>
            <p>This value had an unexpected error!</p>
          </div>
        </ListViewCell>
      }
    >
      {props.children}
    </ErrorBoundary>
  );
}
