import { CSSProperties, ReactElement } from 'react';

interface TChevronProps extends CSSProperties {
  width?: number;
  height?: number;
  svgHeight?: number;
  svgWidth?: number;
}
const Chevron = (props: TChevronProps): ReactElement => {
  const {
    width = 26,
    height = 26,
    fill = '#000000',
    strokeDashoffset,
    strokeDasharray,
    fillOpacity,
    svgHeight = 40,
    svgWidth = 39,
    transform = undefined,
  } = props;

  const xTranslation = (svgWidth - width) / 2;
  const yTranslation = (svgHeight - height) / 2;

  return (
    <svg
      style={{ width, height, display: 'flex', transform }}
      width={svgWidth}
      height={svgHeight}
      viewBox={'0 0 40 40'}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform={`translate(${xTranslation}, ${yTranslation})`}
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={strokeDasharray}
        fillOpacity={fillOpacity}
        d="M22.1406 16L25.1406 13L13 0.859376L0.859377 13L3.85938 16L13 6.85938L22.1406 16Z"
      />
    </svg>
  );
};

export default Chevron;
