import { Dispatch, useContext } from 'react';
import { validate } from '../utils/validate';
import { CalibrationsContext } from './provider';
import { TConfigsContext } from './_types';

export function useCalibrations(): [TConfigsContext, Dispatch<any>, any, TConfigsContext | null] {
  return validate<[TConfigsContext, Dispatch<any>, any, TConfigsContext | null]>(
    useCalibrations.name,
    useContext(CalibrationsContext),
  );
}
