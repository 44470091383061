import ListEditButton from '../ListEditButton';
import setUrlSearchParams from '../../../../utils/setUrlSearchParams';
import MoreButton from '../../../../components/Buttons/MoreButton';
import { useNavigate } from 'react-router-dom';

type Props<T> = {
  item: T;
  name?: string;
  index: number;
  title: string;
  toggle?: (index?: number | undefined) => void;
  on?: boolean;
  onEdit?: (itemId: string | number) => void;
  moreButtons: any;
  query: any;
  pathParent: any;
  pathname: string;
};
export default function SessionEllipsisButton<T extends { [key: string]: any }>(props: Props<T>) {
  const { toggle, on, pathname, onEdit, moreButtons = [], query, pathParent, ...otherProps } = props;
  const navigate = useNavigate();
  const toggled =
    pathname?.includes(`${pathParent}/${props.item.id}`) || pathname?.includes(`${pathParent}/new/create`);
  return (
    <MoreButton
      id={props.item.id}
      MenuItems={[
        {
          element: (
            <ListEditButton
              data-testid={'edit-session-btn'}
              {...{
                color: 'success',
                ...otherProps,
              }}
            />
          ),
          disabled: !toggled && on,
          color: 'success',
          onClick: (): void => {
            if (props.item.id) {
              toggle?.();
              if (onEdit) onEdit(otherProps.item.id);
              navigate(`/${pathParent}/edit/${otherProps.item.id}${setUrlSearchParams(query)}`);
            }
          },
        },
        ...moreButtons,
      ]}
    />
  );
}
