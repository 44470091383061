import { TRidersContext } from './_types';
import { TRider } from '../../common/types/rider';
import { TRun } from '../../common/types/run';

export const getSelectedRiders = ({ ridersById, selectedRiders }: TRidersContext): TRider[] =>
  selectedRiders.map((riderId) => ridersById[riderId]);

export const getRiderById =
  (ridersById: TRidersContext['ridersById']) =>
  (riderId: string): TRider =>
    ridersById[riderId];

export const getRunRiders = (runs: TRun[], ridersById: TRidersContext['ridersById']): TRider[] => {
  const hash: string[] = [];
  return runs.reduce((acc: TRider[], run) => {
    if (run.rider_id && !hash.includes(run.rider_id)) {
      hash.push(run.rider_id);
      return [...acc, getRiderById(ridersById)(run.rider_id)];
    }
    return acc;
  }, []);
};

export const riderFullName = (rider: TRider): string => {
  let name = rider.nickname;
  if (rider.first_name || rider.last_name)
    name += ` (${rider.last_name?.toUpperCase() || ''}, ${rider.first_name || ''})`;

  return name ?? '';
};
