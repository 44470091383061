import { useContext, useEffect } from 'react';
import { AppFooterContext, TAppFooterContext } from './provider';
import CircularProgress from '@mui/material/CircularProgress';

type useAppFooterProps = {
  isLoading: boolean;
  printList: any[];
  selected: any[];
  objectName: string;
};
export default function useAppFooter(props?: useAppFooterProps): TAppFooterContext {
  const context = useContext(AppFooterContext);

  if (!context) {
    throw Error('useAppFooter has to be used inside AppFooterProvider');
  }

  const { isLoading = false, printList = [], selected = [], objectName = 'item' } = props ? props : {};

  useEffect(() => {
    context.setMessage(
      <>
        {isLoading && <CircularProgress size={'20px'} />}
        {!isLoading && printList.length === 0 && `No ${objectName}s.`}
        {printList.length > 0 && `${printList.length} ${objectName}${printList.length > 1 ? 's' : ''}`}
        {selected.length > 0 && ` (${selected.length}) selected`}
      </>,
    );
    return (): void => context.clearMessage();
  }, [selected.length, isLoading]);
  return context;
}
