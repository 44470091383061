import {
  Avatar,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from '@mui/material';
import { Logout, Palette, ChangeHistory } from '@mui/icons-material';
import { useState, Fragment, MouseEvent, ReactElement, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../stores/user/useUser';
import { StreamlinesThemeContext } from '../theme/context';
import { themeTypes } from '../pages/Settings/ThemeTab/themeTypes';
import { useRuns } from '../stores/runs/useRuns';
import { setShowHiddenRuns } from '../stores/runs/setters';

export default function ProfileMenu(props: { signOut: any }): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [userProfile] = useUser();
  const [{ showHidden }, runsDispatch] = useRuns();
  const themeUpdate = useContext(StreamlinesThemeContext);

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const formatProfileAvatar = (profile: any): string => {
    if (!profile) {
      return '';
    }
    const riderName = profile.user_name;
    const regex = /[A-Z]/g;
    const initials = riderName?.match(regex);
    return initials?.slice(0, 3).join('');
  };
  return (
    <Fragment>
      <Tooltip title="Account settings">
        <IconButton
          data-testid={'profile-menu'}
          onClick={handleClick}
          size="small"
          sx={{ ml: 0, padding: 0 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>{formatProfileAvatar(userProfile)}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={(): void => {
            handleClose();
            navigate(`/settings/user/${userProfile.id}`);
          }}
        >
          <Avatar /> {userProfile?.user_name} ( {userProfile?.email_address} )
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            handleClose();
            navigate(`/settings/user/${userProfile.id}/garmin`);
          }}
        >
          <ListItemIcon>
            <ChangeHistory fontSize="small" />
          </ListItemIcon>
          <div>Garmin: {userProfile.garmin_oauth_verifier ? <>Connected</> : <>Not Connected</>} </div>
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            handleClose();
            navigate(`/settings/user/${userProfile.id}/theme`);
          }}
        >
          <ListItemIcon>
            <Palette />
          </ListItemIcon>
          <div>Theme is: {themeTypes.find(({ value }) => value === themeUpdate.themeType)?.label}</div>
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={showHidden}
                onChange={(): void => {
                  setShowHiddenRuns(runsDispatch);
                }}
                focusVisibleClassName=".Mui-focusVisible"
                disableRipple
              />
            }
            label={showHidden ? 'With invalid runs' : 'No invalid runs'}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(): void => {
            handleClose();
            props.signOut();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
