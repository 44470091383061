import { Theme } from '../../../common/interfaces';

export const dark: Theme = {
  colors: {
    primary: '#24a3fa',
    secondary: '#ff7e01',
    danger: '#ee8a8a',
    success: '#29bead',
    text: '#fff',
    background: '#000',
    lighterGray: '#fff',
  },
  textColors: {
    primary: '#fff',
    secondary: '#fff',
    danger: '#fff',
    success: '#0ea18e',
    text: '#000',
    background: '#fff',
    lighterGray: '#000',
  },
  bckgColors: {
    primary: '#fff',
    secondary: '#fff',
    danger: '#fff',
    success: '#0ea18e',
    text: '#000',
    background: '#fff',
    lighterGray: '#000',
  },
};
