import { TConfig } from '../../common/types/config';

export type TConfigsContext = {
  configs: any[];
  // newConfigs: TConfig[];
  // newConfigsHasChanged: boolean;
  configsById: Record<string | number, any>;
  configsByTestSessionId: Record<string | number, TConfig[]>;
  selectedConfigId: any[];
  currentIndexConfigId: number;
  selectedCalibrationId: string;
  addRuns: string[];
  removeRuns: string[];
  isLoading: boolean;
  dataLoading: Set<string | number>;
  isNewItem?: string;
};

export enum ConfigActionTypes {
  SET_DATA = 'SET_DATA',
  SET_ALL_CONFIGS = 'SET_ALL_CONFIGS',
  SET_CONFIGS = 'SET_CONFIGS',
  SET_NEW_CONFIGS = 'SET_NEW_CONFIGS',
  SET_CALIBRATIONS = 'SET_CALIBRATIONS',
  SET_EDITING_CONFIG = 'SET_EDITING_CONFIG',
  SET_SELECTED_CONFIGS = 'SET_SELECTED_CONFIGS',
  SET_RUNS_TO_ADD = 'SET_RUNS_TO_ADD',
  SET_RUNS_TO_REMOVE = 'SET_RUNS_TO_REMOVE',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_DATA_LOADING = 'SET_DATA_LOADING',
  CLEAR_EDITING_CONFIG = 'CLEAR_EDITING_CONFIG',
  SET_CONFIG_FILTER = 'SET_CONFIG_FILTER',
  SET_CALIBRATION_FILTER = 'SET_CALIBRATION_FILTER',
  SET_CURRENT_INDEX_CONFIG = 'SET_CURRENT_INDEX_CONFIG',
}
