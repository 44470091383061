import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { FormRow } from '../../../layouts/Row';

const factoryDate = (strDate?: string | number) => (typeof strDate === 'undefined' ? new Date() : new Date(strDate));
const years = function (start: number, count: number): number[] {
  let currentYear = start;
  const arr = [start];
  while (currentYear <= start + count) {
    currentYear++;
    arr.push(currentYear);
  }
  return arr;
};
const isLeapYear = (year: number): boolean => year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const daysArray = (year: number, month: number): number[] => {
  if ([3, 5, 8, 10].includes(month)) {
    return Array.from({ length: 30 }, (_, i) => i + 1);
  }
  if (month === 1) {
    return Array.from({ length: isLeapYear(year) ? 29 : 28 }, (_, i) => i + 1);
  }

  return Array.from({ length: 31 }, (_, i) => i + 1);
};

type Props = {
  disabled?: boolean;
  defaultDate?: string | number;
  name: string;
  onChange: (date: Date) => void;
};

export default function DateSelect(props: Props): ReactElement {
  const [year, setYear] = useState(factoryDate(props.defaultDate).getFullYear());
  const [month, setMonth] = useState(factoryDate(props.defaultDate).getMonth());
  const [day, setDay] = useState(factoryDate(props.defaultDate).getDate());
  const days = daysArray(year, month);
  const lastDayInAMonth = days.slice(-1)[0];

  useEffect(() => {
    if (day > lastDayInAMonth) {
      setDay(lastDayInAMonth);
    }
  }, [month, year]);

  return (
    <FormRow columnWidth={'25% minmax(100px,1fr) 20%'} columnGap={'.575em'}>
      <FormControl>
        <InputLabel id={`${props.name}-label-year`}>Year</InputLabel>
        <Select
          id={`${props.name}-year`}
          disabled={props.disabled}
          labelId={`${props.name}-label-year`}
          value={year}
          label={'Year'}
          onChange={({ target }) => {
            setYear(+target.value);
            props.onChange(new Date(`${target.value}-${month + 1}-${day}`));
          }}
          MenuProps={{ PaperProps: { style: { maxHeight: '300px' } } }}
        >
          {years(2017, 50).map((year, index) => (
            <MenuItem
              data-testid={year === factoryDate().getFullYear() ? 'current-year' : undefined}
              key={`${props.name}-${year}_${index}`}
              value={year}
            >
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id={`${props.name}-label-month`}>Month</InputLabel>
        <Select
          id={`${props.name}-month`}
          disabled={props.disabled}
          labelId={`${props.name}-label-month`}
          value={month}
          label={'Month'}
          onChange={({ target }) => {
            setMonth(+target.value);
            props.onChange(new Date(`${year}-${+target.value + 1}-${day}`));
          }}
        >
          {months.map((month, index) => (
            <MenuItem
              data-testid={month === months[factoryDate().getMonth()] ? 'current-month' : undefined}
              key={`${props.name}-${month}_${index}`}
              value={index}
            >
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id={`${props.name}-label-day`}>Day</InputLabel>
        <Select
          id={`${props.name}-day`}
          disabled={props.disabled}
          labelId={`${props.name}-label-day`}
          value={day > lastDayInAMonth ? lastDayInAMonth : day}
          label={'Day'}
          onChange={({ target }) => {
            setDay(+target.value);
            props.onChange(new Date(`${year}-${month + 1}-${target.value}`));
          }}
        >
          {daysArray(year, month).map((day, index) => (
            <MenuItem
              data-testid={day !== factoryDate().getDate() ? `day-${index}` : undefined}
              key={`${props.name}-${day}_${index}`}
              value={day}
            >
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormRow>
  );
}
