import { ListViewItem, TListViewContext } from './types';
import { useContext } from 'react';
import { ListViewContext } from './index';

export function useListView(): TListViewContext {
  const context = useContext<TListViewContext<ListViewItem> | null>(ListViewContext);

  if (!context) {
    throw new Error('useListView is an internal hook for ListView and must be used within a ListViewProvider');
  }
  return context;
}
