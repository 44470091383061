import { createContext, Dispatch, ReactElement, useReducer } from 'react';
import { ridersReducer } from './reducer';
import { TBaseContext } from '../types';
import { TRidersContext } from './_types';
import { useRidersGet } from '../ajax/riders';

const defaultState: TRidersContext = {
  riders: [],
  defaultRider: { id: '' },
  ridersById: {},
  selectedRiders: [],
  isLoading: false,
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-empty-function
export const RidersContext = createContext<[TRidersContext, Dispatch<any>, any]>([defaultState, () => {}, null]);
RidersContext.displayName = 'RidersContext';

// preload(RidersFetcher.key, RidersFetcher.fetcher);

export default function RidersProvider({ children, ...props }: TBaseContext): ReactElement {
  const [state, ridersDispatch] = useReducer(ridersReducer, defaultState);

  const { mutate } = useRidersGet(ridersDispatch);

  return (
    <RidersContext.Provider value={[state, ridersDispatch, mutate]} {...{ ...props }}>
      {children}
    </RidersContext.Provider>
  );
}
