import { ConfigActionTypes, TConfigsContext } from './_types';
import { TConfig } from '../../common/types/config';
import { toggleList } from '../utils/getUniqueList';

export default function configsReducer(
  state: TConfigsContext,
  { type, payload }: { type: ConfigActionTypes; payload: any | boolean; hasChanges?: boolean },
): TConfigsContext {
  switch (type) {
    case ConfigActionTypes.SET_DATA:
    case ConfigActionTypes.SET_ALL_CONFIGS: {
      const configs: TConfig[] = payload || [];
      configs.sort((a, b) => (a.run_ts === b.run_ts ? 0 : a.run_ts && b.run_ts && a.run_ts > b.run_ts ? 1 : -1));
      return {
        ...state,
        configs,
        configsById: configs.reduce((acc: any, item: any) => ({ ...acc, [item.id]: item }), {}),
        configsByTestSessionId: configs.reduce((acc: any, item: any) => {
          if (item.testsession_all) return acc;
          const key = item.testsession_id ?? 'unassigned';
          acc[key] = [...(acc[key] ?? []), item];
          return acc;
        }, {}),
      };
    }
    case ConfigActionTypes.SET_CONFIGS: {
      const configs = payload || [];
      return {
        ...state,
        configs,
        configsById: configs.reduce((acc: any, item: any) => ({ ...acc, [item.id]: item }), {}),
      };
    }
    case ConfigActionTypes.SET_SELECTED_CONFIGS: {
      if (Array.isArray(payload)) {
        return { ...state, selectedConfigId: payload };
      }
      const index = state.selectedConfigId.indexOf(payload);
      const newSelection =
        index > -1 ? state.selectedConfigId.filter((id) => id !== payload) : state.selectedConfigId.concat([payload]);

      return { ...state, selectedConfigId: [...newSelection] };
    }
    case ConfigActionTypes.SET_EDITING_CONFIG: {
      const newState = { ...state };

      newState.isNewItem = !state.configsById[payload] ? payload : undefined;

      if (state.configsById[payload]) {
        const index = state.selectedConfigId.indexOf(payload);
        if (index > -1) {
          newState.selectedConfigId.splice(index, 1);
        } else {
          newState.selectedConfigId = state.selectedConfigId.concat([payload]);
        }
      }

      return newState;
    }
    case ConfigActionTypes.CLEAR_EDITING_CONFIG:
      return { ...state, selectedConfigId: [] };
    case ConfigActionTypes.SET_RUNS_TO_ADD:
      return {
        ...state,
        addRuns: payload,
      };
    case ConfigActionTypes.SET_RUNS_TO_REMOVE:
      return {
        ...state,
        removeRuns: payload,
      };
    case ConfigActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case ConfigActionTypes.SET_DATA_LOADING: {
      return {
        ...state,
        dataLoading: toggleList(state.dataLoading, payload),
      };
    }
    case ConfigActionTypes.SET_CURRENT_INDEX_CONFIG: {
      return {
        ...state,
        currentIndexConfigId: payload,
      };
    }
    case ConfigActionTypes.SET_NEW_CONFIGS:
      return {
        ...state,
        // newConfigsHasChanged: hasChanges ? !state.newConfigsHasChanged : state.newConfigsHasChanged,
        // newConfigs: payload,
      };
    default:
      return state;
  }
}
