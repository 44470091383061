import { Theme } from '../../../common/interfaces';
import { colors } from '../../colors';

export const light: Theme = {
  colors: {
    primary: colors.aeroGreen,
    secondary: colors.aeroBlue,
    danger: colors.aeroDarkRed,
    success: colors.aeroGreen,
    text: colors.aeroTextDarkGrey,
    background: '#fff',
    lighterGray: colors.aeroGrey,
  },
  textColors: {
    primary: colors.aeroNavyBlue,
    secondary: colors.aeroTextDarkGrey,
    success: colors.aeroNavyGreen,
    danger: colors.aeroDarkRed,
    text: '#fff',
    background: '#000',
    lighterGray: '#000',
  },
  bckgColors: {
    primary: colors.aeroNavyBlue,
    secondary: colors.aeroTextDarkGrey,
    success: colors.aeroDarkGreen,
    danger: colors.aeroDarkRed,
    text: '#fff',
    background: '#000',
    lighterGray: '#000',
  },
};
