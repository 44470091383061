import { useCallback, useEffect, useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { CirrusRoute } from '../pages/_Routes/CirrusRoutes';
import { useNav } from '../components/Nav/useNav';

type MuiTabs = [string | null, (event: React.SyntheticEvent<Element>, newValue: string) => void];
type MuiTabsProps = { initialValue: string | null; navigate?: NavigateFunction; navigationTabs?: CirrusRoute[] };

export default function useMuiTabs({ initialValue = null, navigate, navigationTabs }: MuiTabsProps): MuiTabs {
  const [value, setValue] = useNav();

  const paths = useMemo(() => navigationTabs?.map(({ path }) => path) ?? [], []);
  if (paths) {
    if (!paths.includes(initialValue ? initialValue : '')) {
      initialValue = null;
    }
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback((event: React.SyntheticEvent<Element>, newValue: string): void => {
    setValue(newValue);
    if (navigate) navigate(newValue);
  }, []);

  return [value, handleChange];
}
