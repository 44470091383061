import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';
/* eslint-disable max-len */

export const StreamlinesSave = ({ fill = 'none', stroke = 'currentColor', size }: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        {...{ stroke: 'none', fill: stroke }}
        d="M35.212,9.351c-0.005,6.593-0.01,13.186-0.014,19.78c-0.098,1.302-0.484,1.911-1.916,1.912
				c-7.939-0.006-15.881-0.011-23.821-0.018C8,30.708,7.778,30.117,7.549,29.108c0.005-7.94,0.012-15.88,0.018-23.82
				C7.649,4.27,8.61,3.458,9.483,3.376c6.593,0.005,13.185,0.01,19.779,0.015C31.244,5.377,33.229,7.363,35.212,9.351z
				 M12.174,29.029c0.001-1.981-0.003-3.925,0.006-5.867c0.011-2.156,1.043-3.188,3.194-3.19c3.861-0.004,7.723,0.003,11.587,0.008
				c2.737,0.001,3.613,0.88,3.614,3.62c0,1.833-0.002,3.664-0.004,5.578c0.456,0,0.771,0,1.085,0.001
				c1.568,0.008,1.678-0.099,1.681-1.698c0.004-5.457,0.027-10.912-0.016-16.368c-0.005-0.523-0.29-1.152-0.649-1.547
				c-1.115-1.233-2.368-2.342-3.492-3.567c-0.626-0.684-1.288-0.923-2.26-0.626c-0.001,1.661,0.006,3.355-0.008,5.047
				c-0.011,1.495-0.651,2.152-2.127,2.161c-2.232,0.015-4.464,0.009-6.695-0.002c-1.608-0.01-2.225-0.644-2.23-2.275
				c-0.005-1.654,0-3.309,0.001-5.061c-1.703-0.002-3.29-0.006-4.876-0.003C9.55,5.242,9.43,5.365,9.428,6.844
				C9.421,13.54,9.417,20.236,9.413,26.932c-0.002,2.273,0.123,2.389,2.421,2.212C11.9,29.14,11.967,29.103,12.174,29.029z
				 M28.757,29.113c0.001-2.14,0.047-4.224-0.044-6.3c-0.015-0.336-0.642-0.922-0.992-0.928c-4.213-0.067-8.428-0.068-12.641-0.016
				c-0.358,0.005-1.006,0.498-1.018,0.785c-0.088,2.14-0.05,4.287-0.052,6.448C18.974,29.107,23.812,29.11,28.757,29.113z
				 M17.794,5.293c0,1.873-0.002,3.622-0.004,5.388c2.445,0.001,4.819,0.003,7.195,0.005c0.002-1.841,0.004-3.592,0.005-5.389
				C22.566,5.296,20.216,5.294,17.794,5.293z"
      />
      <path
        {...{ stroke, fill }}
        d="M35.212,9.351c-1.983-1.988-3.968-3.974-5.95-5.96c1.983,0.001,3.971,0.003,5.954,0.004
				C35.215,5.38,35.213,7.364,35.212,9.351z"
      />
      <path
        {...{ stroke, fill }}
        d="M12.174,29.029c-0.209,0.073-0.275,0.11-0.343,0.116c-2.298,0.177-2.423,0.06-2.421-2.212
				c0.005-6.697,0.008-13.393,0.016-20.089c0.001-1.479,0.122-1.602,1.557-1.606c1.586-0.003,3.173,0.001,4.877,0.003
				c-0.001,1.752-0.008,3.406-0.002,5.06c0.005,1.634,0.622,2.267,2.23,2.276c2.232,0.012,4.464,0.017,6.695,0.003
				c1.477-0.01,2.116-0.667,2.129-2.161c0.013-1.692,0.004-3.385,0.006-5.048c0.971-0.296,1.633-0.057,2.259,0.627
				c1.125,1.225,2.379,2.335,3.494,3.567c0.359,0.396,0.644,1.023,0.647,1.547c0.044,5.457,0.021,10.912,0.017,16.369
				c-0.001,1.6-0.109,1.706-1.681,1.698c-0.312-0.003-0.628-0.001-1.086-0.002c0.002-1.914,0.005-3.745,0.005-5.577
				c0.002-2.741-0.876-3.619-3.614-3.621c-3.863-0.004-7.726-0.011-11.587-0.008c-2.152,0.002-3.184,1.036-3.195,3.189
				C12.171,25.104,12.176,27.048,12.174,29.029z"
      />
      <path
        {...{ stroke, fill }}
        d="M28.757,29.113c-4.945-0.003-9.783-0.006-14.746-0.01c0.001-2.161-0.036-4.309,0.052-6.448
				c0.012-0.288,0.66-0.78,1.018-0.785c4.213-0.052,8.428-0.052,12.64,0.016c0.352,0.006,0.979,0.592,0.993,0.928
				C28.804,24.89,28.758,26.974,28.757,29.113z"
      />
      <path
        {...{ stroke, fill }}
        d="M17.794,5.293c2.422,0.001,4.774,0.003,7.196,0.004c-0.001,1.796-0.003,3.547-0.005,5.389
				c-2.376-0.002-4.75-0.003-7.195-0.005C17.792,8.915,17.792,7.166,17.794,5.293z"
      />
    </Svg>
  );
};
/*eslint-enable max-len*/
