import { useGetAndDispatch } from './getData';
import { PublicConfiguration } from 'swr/_internal';
import { requestWithAxios } from '../../utils/requestWithAxios';
import { TRider } from '../../common/types/rider';

export class RidersFetcher {
  static get key() {
    return {
      endpoint: '/riders',
    };
  }

  static fetcher = async (args: typeof RidersFetcher.key) => {
    const { data } =
      (await requestWithAxios({
        ...args,
        verb: 'GET',
      })) || {};
    return data;
  };
}

export const useRidersGet = (dispatch: any, swrOptions?: Partial<PublicConfiguration>) => {
  return useGetAndDispatch(dispatch)<TRider[]>({
    ...RidersFetcher.key,
    swrOptions,
  });
};
