import { useState } from 'react';
import { AlertProps } from '@mui/material';

export interface Feedback {
  setFeedback(isSuccess: AlertProps['severity'], message?: string): void;
  getFeedback(): string | null;
  isSuccess: AlertProps['severity'];
}

export default function useFormFeedback(): Feedback {
  const [success, setSuccess] = useState<AlertProps['severity']>(undefined);
  const [message, setMessage] = useState<string | null>(null);

  const setFeedback = (isSuccess: AlertProps['severity'], message?: any): void => {
    setSuccess(isSuccess);
    !message ? setMessage(null) : setMessage(message);
  };

  const getFeedback = (): string | null => {
    return message;
  };

  return {
    setFeedback,
    getFeedback,
    isSuccess: success,
  };
}
