import { ReactElement } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CirrusRoutes, { CommonRoutes } from './CirrusRoutes';
import PageNotFound from './PageNotFound';
import { useUser } from '../../stores/user/useUser';
import { routerMap } from './routerMap';

export default function AppRoutes(): ReactElement {
  const [user] = useUser();
  const firstPage = '/session';

  return (
    <Routes>
      <Route path="/" element={<Navigate to={firstPage} replace />} />
      {CirrusRoutes().map(routerMap)}
      {CommonRoutes(user).map(routerMap)}
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  );
}
