/** @jsxImportSource @emotion/react */
import { ReactElement } from 'react';
import { Button } from '@mui/material';
import { TButtonProps } from '../../common/types/buttonProps';

export default function BaseButton({
  icon,
  onClick,
  color,
  disabled = false,
  type = 'button',
  variant = 'outlined',
  children,
  style,
  fullWidth,
  className,
  css: serializedStyle,
  ...rest
}: TButtonProps): ReactElement {
  return (
    <Button
      css={serializedStyle}
      variant={variant}
      startIcon={icon}
      color={color ?? 'primary'}
      sx={style}
      {...{ onClick, disabled, type, fullWidth, ...rest }}
      className={`classicButton ${!disabled ? '' : 'default'} ${className ?? ''}`}
    >
      {children !== 'none' ? children : null}
    </Button>
  );
}
