import { ReactElement, useRef, useEffect } from 'react';

type TPageProps = {
  children: ReactElement | ReactElement[];
  label: string;
};

export default function Page({ children, label }: TPageProps): ReactElement {
  const prefix = 'Streamlines';
  useDocumentTitle(`${prefix} | ${label}`);
  useDocumentDescription(label);
  return <>{children}</>;
}

function useDocumentTitle(title: string, prevailOnUnmount = false): void {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => (): void => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
}

function useDocumentDescription(desc: string, prevailOnUnmount = false): void {
  const descDefault = document.querySelector('meta[name="description"]')?.getAttribute('content');
  const descRef = useRef(descDefault + desc);

  useEffect(() => {
    document.querySelector('meta[name="description"]')?.setAttribute('content', descDefault + desc);
  }, [desc]);

  useEffect(
    () => (): void => {
      if (!prevailOnUnmount) {
        document
          .querySelector('meta[name="description"]')
          ?.setAttribute('content', descRef.current || 'Streamlines Portal: ');
      }
    },
    [],
  );
}
