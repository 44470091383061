import { CirrusRoute } from '../_Routes/CirrusRoutes';
import ThemeTab from './ThemeTab';
import User from './User';
import { isAdminUser } from '../../common/types/enums';
import { TUser } from '../../common/types/user';
import { lazy } from 'react';

const Garmin = lazy(() => import('../Garmin' /* webpackChunkName: "garmin" */));
const Admin = lazy(() => import('./Admin' /* webpackChunkName: "garmin" */));
const Settings = lazy(() => import('./index' /* webpackChunkName: "settings" */));

const SettingRoutes = (user: TUser): CirrusRoute => ({
  path: '/settings/user/:userId',
  label: 'Settings',
  element: <Settings user={user} />,
  nestedRoutes: [
    { path: '', label: 'User', element: <User /> },
    {
      path: 'admin',
      label: 'Admin',
      hidden: !isAdminUser(user),
      element: <Admin />,
      nestedRoutes: [
        { path: 'createUser', label: 'Create User', element: <></> },
        { path: 'createTeam', label: 'Create Team', element: <></> },
        { path: 'editTeam/:teamUuid', label: 'Edit Team', element: <></> },
        { path: 'editUser/:cognitoUuid', label: 'Edit User', element: <></> },
      ],
    },
    { path: 'garmin', label: 'Garmin', element: <Garmin /> },
    {
      path: 'theme',
      label: 'Theme',
      element: <ThemeTab />,
    },
  ],
});

export default SettingRoutes;
