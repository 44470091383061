import { ListViewItemExtended } from '../../../components/ListView/types';
import { NavigateFunction } from 'react-router-dom';

type listOnClickProps<T> = {
  parentPath: string;
  pathname: string;
  item: ListViewItemExtended<T>;
  stepBack?: boolean;
};

export const goTo =
  (navigate: NavigateFunction) =>
  <T>({ item, pathname, parentPath, stepBack }: listOnClickProps<T>) =>
  () => {
    if (pathname.includes(`${parentPath}/${item.id ?? ''}`)) {
      stepBack ? navigate?.(-1) : navigate?.('');
    } else {
      navigate?.(`${parentPath}/${item.id ?? ''}`);
    }
  };

// export const toggleRoute = goTo(useNavigate);
export default function toggleListOnClick<T>(
  { item, pathname, parentPath, stepBack }: listOnClickProps<T>,
  navigate?: NavigateFunction,
): void {
  if (pathname.includes(`${parentPath}/${item.id ?? ''}`)) {
    stepBack ? navigate?.(-1) : navigate?.('');
  } else {
    navigate?.(`${parentPath}/${item.id ?? ''}`);
  }
}
