import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormProps, ValidationMode } from 'react-hook-form';
import useFormFeedback, { Feedback } from '../hooks/useFormFeedback';
import FormAlertBox from '../FormAlertBox';

export interface FormContextInterface extends Feedback {
  submitStart(): void;

  checkSubmitSuccess(args: { data: Record<string, any>; messageSuccess?: string; messageError?: string }): boolean;

  setLoading(value: boolean): void;

  isLoading: boolean;
}

interface FormProviderProps {
  children: React.ReactNode | React.ReactNode[];
  defaultValues?: Record<string, any>;
  mode?: keyof ValidationMode;
}

export const FormContext = createContext<FormContextInterface | null>(null);
FormContext.displayName = 'FormContext';

export default function FormContextProvider({
  children,
  defaultValues,
  mode = 'onBlur',
}: FormProviderProps): ReactNode {
  //this is still interesting issue where  interface FormProviderProps
  // has defaultValues as Record<string, any> as recommended by docs
  // but if you try to destruct defaultValues in this way:
  // defaultValues: {order: 1, ...defaultValues}
  // TS will complain

  const defaultForm = useMemo(() => defaultValues, [defaultValues]);

  const formMethods = useForm<UseFormProps>({
    defaultValues: defaultForm,
    reValidateMode: 'onChange',
    mode,
  });

  useEffect(() => {
    formMethods.reset(defaultForm);
  }, [defaultForm]);

  const [isLoading, setLoading] = useState(false);

  const { setFeedback, getFeedback, isSuccess } = useFormFeedback();

  const value: FormContextInterface = {
    setFeedback,
    getFeedback,
    isSuccess,
    isLoading,
    setLoading,
    submitStart() {
      setLoading(true);
      setFeedback('info', 'Awaiting response!');
    },
    checkSubmitSuccess({ data, messageSuccess = 'Submitting was successful.', messageError }) {
      if (data?.error || data?.errors) {
        setLoading(false);
        setFeedback('error', messageError ?? `${data.error}: ${data.display_message || data.errors}`);
        return false;
      }
      setLoading(false);
      setFeedback('success', messageSuccess);
      return true;
    },
  };

  return (
    <FormContext.Provider value={{ ...value }}>
      <FormProvider {...formMethods}>
        {!!getFeedback() && <FormAlertBox />}
        {children}
      </FormProvider>
    </FormContext.Provider>
  );
}
