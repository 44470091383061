import { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import { QueryStats } from '@mui/icons-material';

export default function ViewResults(): ReactElement {
  return (
    <>
      <IconButton color={'success'}>
        <QueryStats />
      </IconButton>
      View Results
      {/*<Typography color={'success'}>View Results</Typography>*/}
    </>
  );
}
