import { TRun, typedParam } from '../types/run';
import { RunStatus } from '../types/enums';
import { TConfig } from '../types/config';
import moment from 'moment/moment';

export function sort<T extends TRun>(
  param: Extract<keyof T, string>,
  order?: 'ASC' | 'DESC',
): <Te extends T>(itemA: Te, itemB: Te) => 0 | 1 | -1;
export function sort<T extends TConfig>(
  param: Extract<keyof T, string>,
  order?: 'ASC' | 'DESC',
): <Te extends T>(itemA: Te, itemB: Te) => 0 | 1 | -1;
export function sort<T extends Record<string, any>>(
  param: Extract<keyof T, string>,
  order?: 'ASC' | 'DESC',
): <Te extends T>(itemA: Te, itemB: Te) => 0 | 1 | -1;
export function sort<T extends TRun | TConfig | Record<string, any>>(
  param: Extract<keyof T, string>,
  order: 'ASC' | 'DESC' = 'ASC',
): <Te extends T>(itemA: Te, itemB: Te) => 0 | 1 | -1 {
  const asc = order === 'ASC' ? 1 : -1;
  return (itemA, itemB): 0 | 1 | -1 => {
    if (['date', 'time', 'run_ts'].includes(param)) {
      const dateA = typedParam<T, 'run_ts'>(itemA)('run_ts') ?? '';
      const dateB = typedParam<T, 'run_ts'>(itemB)('run_ts') ?? '';
      return ((new Date(dateA).getTime() - new Date(dateB).getTime()) * asc) as 1 | -1;
    }

    if (itemA[param] === itemB[param]) {
      return 0;
    }
    return (itemA[param] ?? 0) > (itemB[param] ?? 0) ? ((1 * asc) as 1 | -1) : ((-1 * asc) as 1 | -1);
  };
}

export const sortRunsByTime = sort<TRun>('run_ts', 'DESC');
export const sortRunsByNumber = sort<TRun>('run_number');

export const findById =
  <T extends { id: string }>(list: T[]) =>
  (id: string) =>
    list.find((run) => run.id === id) ?? ({} as T);

export const findByParam =
  <T extends { id: string }>(list: T[], param: keyof T) =>
  (value: string | number | CallableFunction) =>
    list.find((item) => {
      return typeof value === 'function' ? value(item[param]) : item[param] === value;
    }) ?? ({} as T);

export const swapRunNumber = (runs: TRun[]): typeof runNumberSwap => {
  function runNumberSwap(newRunNumber: number, item: TRun): any;
  function runNumberSwap(newRunNumber: number, itemId: TRun['id']): any;
  function runNumberSwap(newRunNumber: number, itemOrItemId: TRun | TRun['id']): any {
    const item: TRun = typeof itemOrItemId === 'string' ? findById(runs)(itemOrItemId) : itemOrItemId;
    const existingRun = runs.find((run) => run.run_number === newRunNumber);

    const list: TRun[] = [{ ...item, run_number: newRunNumber }];

    if (existingRun) list.push({ ...existingRun, run_number: item.run_number });

    return list;
  }

  return runNumberSwap;
};

export function cleanRunNumbers<T extends TRun>(runs: T[]) {
  return (initialRunNumber?: number, run_ts_delta?: number) => {
    if (!initialRunNumber && runs[0].run_number) initialRunNumber = runs[0].run_number;
    return runs.map(
      (run, index, array): T => ({
        ...run,
        run_number: initialRunNumber ? +initialRunNumber + index : run.run_number,
        run_ts:
          run_ts_delta && run.run_ts
            ? moment(
                (array[index - 1]?.run_status || 0) >= RunStatus.PROCESSED_ERROR && array[index - 1].run_ts
                  ? array[index - 1].run_ts
                  : undefined,
              )
                .add(run_ts_delta * (index + 1), 'minutes')
                .format('YYYY-MM-DD hh:mm:ss')
            : run.run_ts,
      }),
    );
  };
}

abstract class EntityList<T extends { id: string }> {
  protected readonly idList: string[];

  constructor() {
    this.idList = [];
  }

  abstract set(runs: T[]): void;

  abstract add(run: T): void;

  abstract get all(): T[];

  abstract get last(): T | null;

  get ids(): string[] {
    return this.idList;
  }

  findById = (id: string): T => {
    return findById([...this.all])(id);
  };
}

export class RunList<Type extends TRun = TRun> extends EntityList<TRun> {
  protected _sessionId: string | number | undefined;
  protected readonly _futureRuns: Type[];
  protected readonly _dataRuns: Type[];
  protected readonly idList: string[];

  constructor(runs?: Type[]) {
    super();
    this._sessionId = undefined;
    this._futureRuns = [];
    this._dataRuns = [];
    this.idList = [];
    if (runs) {
      this.set(runs);
    }
  }

  set setSessionId(sessionId: string | number) {
    this._sessionId = sessionId;
  }

  get sessionId() {
    return this._sessionId;
  }

  get lastCalibration(): TRun['calibration'] {
    return this.last?.calibration;
  }

  get dataRuns(): Type[] {
    this._dataRuns.sort(sortRunsByNumber);
    return this._dataRuns;
  }

  get dataRunCount(): number {
    return this._dataRuns.length;
  }

  get futureRuns(): Type[] {
    this._futureRuns.sort(sortRunsByNumber);
    return this._futureRuns;
  }

  get nextPlannedRun(): Type | undefined {
    return this.futureRuns[0];
  }

  get hasFutureRuns(): boolean {
    return !!this._futureRuns.length;
  }

  get all(): Type[] {
    return [...this.dataRuns, ...this.futureRuns];
  }

  findRunByRunNumber = (run_number: number): Type => {
    return findByParam([...this.dataRuns, ...this.futureRuns], 'run_number')(run_number);
  };

  findConfigRuns = (configId: string | number): Type[] => {
    return this.all.filter(({ groups_runs }) => groups_runs?.find(({ group_id }) => group_id === configId));
  };

  getNextRunConfigs = (): Type['groups_runs'] => {
    return this.nextPlannedRun?.groups_runs ?? [];
  };

  add(run: Type): void {
    if (this.idList.includes(run.id)) {
      return undefined;
    }
    if (run.run_status && run.run_status >= RunStatus.UPLOADED_MATCHED) {
      this._dataRuns.push(run);
    } else {
      this._futureRuns.push(run);
    }
    this.idList.push(run.id);
  }

  set(runs: Type[]): void {
    runs.forEach((run) => this.add(run));
  }

  get last(): Type | null {
    if (this.dataRuns.length) {
      return this.dataRuns.slice(-1)[0] ?? null;
    } else {
      return this.all.slice(-1)[0] ?? null;
    }
  }

  get newRunNumber(): number {
    const n = this.all.slice(-1)[0]?.run_number ?? 0;
    return n + 1;
  }

  get calibrations(): Set<string | null | undefined> {
    return new Set(this.all.map(({ calibration }) => calibration));
  }

  get configs(): Type['groups_runs'][] {
    return this.all.map(({ groups_runs }) => groups_runs);
  }

  get nextRunNumber(): number {
    return this.nextPlannedRun?.run_number ?? 1;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get swapRunNumber() {
    return swapRunNumber(this.futureRuns);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get cleanRunNumbers() {
    return cleanRunNumbers(this.all);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  cleanFutureRunNumber(newRun?: Type) {
    return cleanRunNumbers(newRun ? [newRun, ...this.futureRuns] : this.futureRuns);
  }
}
