import { Dispatch } from 'react';
import { RunsActionTypes, TDrawerPayload, TRunSort } from './_types';
import axios from '../../utils/axios';
import { getGroupsRuns } from './getters';

export const setData = (
  payload: Record<string, any> | Record<string, any>[],
  dispatch: Dispatch<any>,
  sort?: any,
): void => {
  if (payload.sort && sort) {
    payload = payload.sort(sort);
  }
  dispatch({ type: RunsActionTypes.SET_DATA, payload });
};

const setShowHiddenRuns = (dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.SET_SHOW_HIDDEN });
};
const setUnprocessedRuns = (payload: any[], dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.SET_RUNS_NEED_PROCESSING, payload });
};
const setEditedRuns = (payload: (string | number)[], dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.SET_EDITING_RUNS, payload });
};
const setPlottedRuns = (payload: string[] | undefined, dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.SET_PLOTTED_RUNS, payload });
};
const setIsLoading = (payload = false, dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.SET_IS_LOADING, payload });
};
const setDataLoading = (payload: string, dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.SET_DATA_LOADING, payload });
};

export const addToDrawer = (payload: { drawerName: string; id: string }, dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.ADD_TO_DRAWER, payload });
};

export const removeFromDrawer = (payload: TDrawerPayload, dispatch: Dispatch<any>): void => {
  dispatch({ type: RunsActionTypes.REMOVE_FROM_DRAWER, payload });
};

export const setSort = (payload: string, dispatch: Dispatch<any>): void => {
  switch (payload) {
    case 'Description':
      dispatch({ type: RunsActionTypes.SORT_RUNS, payload: TRunSort.NAME });
      break;
    case 'Rider':
      dispatch({ type: RunsActionTypes.SORT_RUNS, payload: TRunSort.RIDER });
      break;
    default:
      dispatch({ type: RunsActionTypes.SORT_RUNS, payload: TRunSort.DATE });
      break;
  }
};
const removeSelectionFromGroup = (dispatch: Dispatch<any>) => {
  return async ({
    groupsToRemove,
    selectedRuns,
  }: {
    groupsToRemove: string[];
    selectedRuns: any[];
  }): Promise<any[]> => {
    try {
      dispatch({ type: RunsActionTypes.SET_IS_LOADING, payload: true });

      const groupsRuns: string[] = getGroupsRuns(selectedRuns, groupsToRemove).map((groupRun) => groupRun.id);

      const response = await Promise.all(
        groupsRuns.map(async (groupRunId) => await axios.delete(`/groups-runs/${groupRunId}`)),
      );
      dispatch({ type: RunsActionTypes.SET_IS_LOADING, payload: false });

      return response;
    } catch (e: any) {
      dispatch({ type: RunsActionTypes.SET_IS_LOADING, payload: false });
      console.error(e);

      throw e;
    }
  };
};
export { removeSelectionFromGroup };
export { setIsLoading };
export { setDataLoading };
export { setEditedRuns };
export { setPlottedRuns };
export { setUnprocessedRuns };
export { setShowHiddenRuns };
