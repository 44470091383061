import { ReactElement } from 'react';
import { IconButton, Typography } from '@mui/material';
import { StreamlinesCaliper } from '../../../../icons/StreamlinesCaliper';

type Props = {
  [key: string]: any;
};
export const AutoCalibration = (props: Props): ReactElement => {
  return (
    <>
      <IconButton color={'success'} {...props}>
        <StreamlinesCaliper />
      </IconButton>
      <Typography color={'success'}>Auto calibrate</Typography>
    </>
  );
};
