import moment from 'moment/moment';
import { TRun } from '../../../../common/types/run';
import { FilterCondition } from '../../../../components/ListView/types';
import { TConfig } from '../../../../common/types/config';

export const matchConfigRiderId = function (runsById?: Record<string, TRun>): FilterCondition<any> {
  return (item, filter) => {
    if (filter.includes(item.rider_id)) return true;
    return (
      item.run_ids?.reduce(
        (acc: boolean, runId: string) => (runsById ? filter.includes(runsById[runId]?.rider_id) : acc),
        false,
      ) ?? false
    );
  };
};

export const matchSessionId = function (configsById?: Record<string, TConfig>): FilterCondition<any> {
  return (item, filter) => {
    if (filter.includes(item.testsession_id)) return true;

    return item.groups_runs?.find(({ group_id }: any) =>
      configsById ? filter.includes(configsById[group_id].testsession_id) : false,
    );
  };
};

export const matchRunRiderId = function (): FilterCondition<any> {
  return (item, filter) => filter.includes(item.rider_id) ?? false;
};

export const matchConfigRunTs = function (): FilterCondition<any> {
  return (item, filter) => {
    const { startDate = null, endDate = null } = filter ?? {};
    if ((!startDate && !endDate) || !filter.dateEnabled) {
      return true;
    } else if (!filter.startDate) {
      return moment(item.run_ts) <= moment(endDate);
    } else if (!filter.endDate) {
      return moment(item.run_ts) >= moment(startDate);
    }
    return moment(item.run_ts) >= moment(startDate) && moment(item.run_ts) <= moment(endDate);
  };
};
