import { useContext } from 'react';
import { ButtonContext } from './index';

export type ButtonAction = {
  call: CallableFunction;
  disabled?: boolean;
  hidden?: boolean;
};
export type ButtonActions = Record<string, ButtonAction>;
export type ButtonContextType = {
  buttonActions: ButtonActions;
  setButtonActions: React.Dispatch<ButtonActions>;
};

export const useButtonContext = (): ButtonContextType => {
  const context = useContext(ButtonContext);
  if (!context) {
    throw Error('useButtonContext inside ButtonContextProvider');
  }
  return context;
};
