import { useGetAndDispatch } from './getData';
import { PublicConfiguration } from 'swr/_internal';
import { requestWithAxios } from '../../utils/requestWithAxios';
import { TRun } from '../../common/types/run';

export class RunsFetcher {
  static get key() {
    return {
      endpoint: '/runs',
      scope: 'with_groups_runs',
      order: 'DESC',
      order_by: 'run_ts',
    };
  }

  static fetcher = async (args: typeof RunsFetcher.key) => {
    const { data } =
      (await requestWithAxios({
        ...args,
        verb: 'GET',
      })) || {};
    return data;
  };
}

export const useRunsGet = (dispatch: any, swrOptions?: Partial<PublicConfiguration>) => {
  return useGetAndDispatch(dispatch)<TRun[]>({
    ...RunsFetcher.key,
    swrOptions,
  });
};
