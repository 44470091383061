/** @jsxImportSource @emotion/react */
import Row from '../../../layouts/Row';
import { CSSProperties, ReactElement } from 'react';
import { css } from '@emotion/react';
import Column from '../../../layouts/Column';
import { colors } from '../../../theme/colors';

export type ListWarning = { ERROR?: Set<string>; WARNING?: Set<string>; DIFFERENT_CALIBRATION: boolean };
export default function ListWarnings({
  listWarning,
  isHidden,
}: {
  listWarning: ListWarning;
  isHidden?: boolean | null;
}): ReactElement {
  const beanStyle: CSSProperties = {
    whiteSpace: 'nowrap',
    padding: '0 0.5rem',
    height: '1.25rem',
    lineHeight: '1.25rem',
    fontSize: '0.75rem',
    borderRadius: '0.57rem',
  };
  const missing = [...(listWarning?.ERROR || [])];
  const different = [...(listWarning?.WARNING || [])];

  return (
    <Column style={{ color: isHidden ? '#959595' : 'inherit' }} rows={missing.length && different.length ? 2 : 1}>
      {missing.length > 0 && (
        <Row columns={'auto-fill'} columnWidth={'minmax(min-content, 4.6875em)'}>
          {missing.map((key: string) => (
            <p
              key={`error_${key}`}
              css={css({
                ...beanStyle,
                color: colors.aeroDarkRed,
                backgroundColor: colors.aeroLightRed,
              })}
            >
              {key.split('_').slice(0, 2).join(' ')}
            </p>
          ))}
        </Row>
      )}
      {different.length > 0 && (
        <Row columns={'auto-fill'} columnWidth={'minmax(min-content, 75px)'}>
          {different.map((key: string) => (
            <p
              key={`warning_${key}`}
              css={css({
                ...beanStyle,
                color: colors.jumboDarkYellow,
                backgroundColor: colors.jumboLightYellow,
              })}
            >
              {key.split('_').slice(0, 2).join(' ')}
            </p>
          ))}
        </Row>
      )}
    </Column>
  );
}
