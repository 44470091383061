import { LocalStorageKeys, ThemeTypes } from '../common/interfaces';
import cognito from '../config/client_cognito.json' assert { type: 'json' };

function isDarkModeEnabled(): boolean {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export class LocalStorageHandler {
  private static userId = '';

  static setUser(userId: string): void {
    this.userId = userId;
  }

  private static getCognitoLastAuthUser(): void {
    this.userId =
      localStorage.getItem(`CognitoIdentityServiceProvider.${cognito.userPoolWebClientId}.LastAuthUser`) ?? '';
  }

  static getUser(): string {
    if (!this.userId) this.getCognitoLastAuthUser();
    return this.userId;
  }

  private static lsKey(key: string): string {
    return `${key}:${this.getUser()}`;
  }

  static save<T = string>(key: LocalStorageKeys | ThemeTypes | string, value?: any): T {
    if (value === null || typeof value === 'undefined') {
      localStorage.removeItem(this.lsKey(key));
      return value;
    }
    if (typeof value === 'object') {
      if (Array.isArray(value) && !value.length) {
        localStorage.removeItem(this.lsKey(key));
      }
      if (!Object.keys(value).length && Object.getPrototypeOf(value) === Object.prototype) {
        localStorage.removeItem(this.lsKey(key));
      }
      localStorage.setItem(this.lsKey(key), JSON.stringify(value));
      return value;
    }

    localStorage.setItem(this.lsKey(key), value);
    return value;
  }

  private static isJsonString(str: string | null): any {
    if (!str) return str;
    try {
      return JSON.parse(str);
    } catch {
      return str;
    }
  }

  static get<T = string>(key: LocalStorageKeys | ThemeTypes | string): T {
    const content = this.isJsonString(localStorage.getItem(this.lsKey(key)));

    switch (key) {
      case ThemeTypes.CONTRAST:
      case ThemeTypes.DARK:
      case ThemeTypes.DEFAULT:
        return content ?? (isDarkModeEnabled() ? ThemeTypes.DARK : ThemeTypes.DEFAULT);
      case LocalStorageKeys.FILTERS:
        return content ?? {};
      default:
        return content ?? null;
    }
  }
}
