import { ReactElement, useContext } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { StreamlinesThemeContext } from '../../../theme/context';
import Column from '../../../layouts/Column';
import { themeTypes } from './themeTypes';

export default function ThemeTab(): ReactElement {
  const themeUpdate = useContext(StreamlinesThemeContext);
  return (
    <Column justifyItems={'start'} style={{ paddingLeft: '24px' }}>
      <FormControl>
        <FormLabel>Choose your preferred theme:</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={themeUpdate.themeType}
          onChange={themeUpdate.setType}
        >
          {themeTypes.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={
                <Radio
                  sx={{
                    padding: '3px 9px 3px 5px',
                    '& .MuiSvgIcon-root': {
                      fontSize: 16,
                    },
                  }}
                />
              }
              label={<Typography sx={{ fontSize: 14 }}>{label}</Typography>}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Column>
  );
}
