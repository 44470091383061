/** @jsxImportSource @emotion/react */
import { ReactElement } from 'react';
import { Edit } from '../../icons/Edit';
import { TButtonProps } from '../../common/types/buttonProps';
import { IconButton } from '@mui/material';

export default function EditButton({
  onClick,
  disabled,
  hidden = false,
  style = { visibility: undefined, color: undefined, justifySelf: 'end' },
  color,
  icon,
  ...rest
}: TButtonProps): ReactElement {
  return (
    <IconButton
      {...{
        disabled,
        sx: { ...style, visibility: hidden ? 'hidden' : 'visible', color },
        color,
        onClick,
        ...rest,
      }}
      disableRipple
    >
      {icon ?? <Edit />}
    </IconButton>
  );
}
