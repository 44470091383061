import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import settings from '../config/client_settings.json';
import { LocalStorageHandler } from './toLocalStorage';

// copied from another project, currently not used !!!

const instance = axios.create({
  // where we make our configurations
  baseURL: settings.api_url,
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = 'Bearer ' + LocalStorageHandler.get('token');
// instance.defaults.headers.common["Accept"] = "application/json";
instance.defaults.headers.patch['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.delete['Content-Type'] = 'application/json';

instance.interceptors.request.use(function (config: any) {
  return new Promise((resolve) => {
    fetchAuthSession({ forceRefresh: true })
      .then((session) => {
        const date = new Date();
        date.setSeconds(Math.round(date.getSeconds() / 60));

        config.headers.Authorization = 'Bearer ' + session.tokens?.idToken?.toString();
        LocalStorageHandler.save('token', session.tokens?.idToken?.toString());
        resolve(config);
      })
      .catch((error) => {
        // No logged-in user: don't set auth header
        config.headers.Authorization = null;
        LocalStorageHandler.save('token', null);
        console.error(error);
        resolve(config);
      });
  });
});

// Add a 401 response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      console.error('401 - Authentication failed.');
      LocalStorageHandler.save('token', null);
      return Promise.resolve(null);
    } else if (400 === error?.response?.status) {
      console.error('400 - Bad request.');
      LocalStorageHandler.save('token', null);
      return Promise.resolve(error.response.data);
    } else if (500 === error?.response?.status) {
      console.error('500 - Server error.');
      LocalStorageHandler.save('token', null);
      return Promise.resolve(error.response.data);
    } else {
      return Promise.reject(error);
    }
  },
);

export default instance;
