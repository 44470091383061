import React, { ReactElement, useEffect } from 'react';
import { Alert, AlertProps, AlertTitle } from '@mui/material';
import Column from '../../layouts/Column';
import { useCustomFormContext } from './FormContext/useCustomFormContext';

const AlertTitleMessage = ({ isSuccess }: { isSuccess?: AlertProps['severity'] }): ReactElement | null => {
  const message = (): string => {
    switch (isSuccess) {
      case 'success':
        return 'Success saving changes.';
      case 'error':
        return 'Error saving changes.';
      case 'info':
        return 'Please wait: saving changes.';
      case 'warning':
        return 'Warning!';
      default:
        return '';
    }
  };

  return <AlertTitle>{message()}</AlertTitle>;
};

export default function FormAlertBox(): JSX.Element {
  const { isSuccess, getFeedback, setFeedback } = useCustomFormContext();
  const message = getFeedback();

  useEffect(() => {
    const timer = setTimeout(() => setFeedback(undefined), 3500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
      {message && (
        <Alert
          severity={isSuccess}
          style={{
            position: 'fixed',
            bottom: 0,
            width: 'calc(100% - 20px)',
            border: 'solid 2px grey',
            zIndex: 9999,
          }}
          onClose={(): void => {
            setFeedback(undefined);
          }}
        >
          <AlertTitleMessage isSuccess={isSuccess} />
          <Column textAlign={'start'} style={{ whiteSpace: 'break-spaces' }}>
            {message}
          </Column>
        </Alert>
      )}
    </React.Fragment>
  );
}
