import { TCirrusIconProps } from './types';

export const iconSize = (sx: TCirrusIconProps['size']): string => {
  switch (sx) {
    case 'sm':
      return '12px';
    case 'md':
      return '24px';
    case 'lg':
      return '40px';
    case 'xl':
      return '75px';
    default:
      return '24px';
  }
};
