import ErrorBoundary from '../../ErrorBoundary';
import { PropsWithChildren } from 'react';
import { ListViewRow } from '../ListViewRow';
import { ListViewCell } from '../index';

export default function ListRowError(props: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallback={
        <tbody>
          <ListViewRow>
            <ListViewCell colSpan={0}>
              <div>
                <p>
                  This item was delivered with an error please contact help@streamlines.aero if you like us investigate
                </p>
              </div>
            </ListViewCell>
          </ListViewRow>
        </tbody>
      }
    >
      {props.children}
    </ErrorBoundary>
  );
}
