interface Colors {
  primary: string;
  secondary: string;
  success: string;
  danger: string;
  text: string;
  background: string;
  lighterGray: string;
}

export enum ThemeTypes {
  DEFAULT = 'light',
  DARK = 'dark',
  CONTRAST = 'contrast',
}

export interface Theme {
  colors: Colors;
  textColors: Colors;
  bckgColors: Colors;
}
