import { Dispatch, useContext, useMemo } from 'react';
import { RunsContext } from './provider';
import { TRunsContext } from './_types';
import { validate } from '../utils/validate';
import { useConfigs } from '../configs/useConfigs';
import { TRun } from '../../common/types/run';
import { RunStatus } from '../../common/types/enums';
import { RunList } from '../../common/classes/RunList';
import { TConfig } from '../../common/types/config';
import { isEmpty } from 'lodash';

function useRuns(): [TRunsContext, Dispatch<any>, any] {
  return validate<[TRunsContext, Dispatch<any>, any]>(useRuns.name, useContext(RunsContext));
}

export const sortSessionRuns = (itemA: TRun, itemB: TRun): -1 | 1 | 0 => {
  if (itemA.run_status && itemA.run_status >= RunStatus.UPLOADED_MATCHED) {
    return itemA.run_ts && itemB.run_ts && itemA.run_ts > itemB.run_ts ? 1 : -1;
  }
  return itemA.run_number && itemB.run_number && itemA.run_number > itemB.run_number ? 0 : -1;
};

function useSessionRuns(sessionId?: string | number): RunList<TRun & { configId: TConfig['id'] }> {
  if (!sessionId) {
    return new RunList();
  }

  const [{ configsByTestSessionId }] = useConfigs();
  const [{ runsById }] = useRuns();

  return useMemo(() => {
    const list = new RunList<TRun & { configId: TConfig['id'] }>();
    list.setSessionId = sessionId;
    if (isEmpty(configsByTestSessionId)) return list;

    for (const config of configsByTestSessionId[sessionId] ?? []) {
      if (Array.isArray(config.run_ids)) {
        for (const runId of config.run_ids) {
          if (runsById[runId]) {
            list.add({ ...runsById[runId], configId: config.id });
          }
        }
      }
    }
    return list;
  }, [configsByTestSessionId, runsById]);
}

export { useRuns, useSessionRuns };
