import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';

/* eslint-disable max-len */
export const StreamlinesRefresh = ({
  fill = 'currentColor',
  stroke = 'none',
  size,
}: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        {...{ fill, stroke }}
        transform="translate(0,512) scale(0.1,-0.1)"
        d="M164.878,5108.417c-32.881-3.091-67.466-17.3-91.904-37.676c-50.425-42.051-72.417-108.467-56.753-170.908
		c12.1-48.057,43.516-87.686,87.28-109.932c23.062-11.689,45.225-17.295,71.445-18.105c21.851-0.664,38.013,1.631,58.306,8.281
		c68.936,22.725,115.449,87.197,115.449,160.029c0,10.063-0.234,12.178-1.621,14.854c-4.541,9.014-17.529,9.258-22.51,0.41
		c-1.68-2.837-1.846-4.307-1.846-14.858c0-22.739-4.16-41.157-13.799-61.47c-14.453-30.518-37.119-53.174-67.646-67.705
		c-19.082-9.102-35.215-12.91-56.997-13.555c-24.834-0.82-45.869,3.799-67.949,14.775c-15.508,7.715-27.285,16.387-39.785,29.551
		c-10.234,10.635-17.461,21.025-24.116,34.58c-14.609,29.482-18.672,60.571-12.261,92.329
		c10.068,49.36,46.602,90.605,94.263,106.519c42.627,14.126,87.842,8.037,125.361-16.89c4.951-3.33,9.658-6.738,10.469-7.715
		l1.543-1.704h-9.902c-11.289,0-14.277-0.889-17.373-5.2c-2.598-3.647-2.52-11.206,0.078-14.932
		c3.906-5.356,4.238-5.444,33.047-5.444c24.775,0,26.152,0.088,28.818,1.709c1.543,0.879,3.652,2.837,4.639,4.385
		c1.865,2.676,1.865,3.008,1.865,29.556c0,25.239-0.088,27.031-1.621,29.224c-3.652,5.688-11.055,7.637-16.973,4.551
		c-4.873-2.437-6.572-6.011-7.139-14.458l-0.488-7.231l-8.203,5.933c-21.592,15.513-47.5,25.977-73.809,29.883
		C193.623,5108.827,175.195,5109.389,164.878,5108.417z"
      />
    </Svg>
  );
};
/* eslint-enable max-len */
