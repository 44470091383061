import { Dispatch, useContext, useEffect } from 'react';
import { validate } from '../utils/validate';
import { ConfigsContext } from './provider';
import { TConfigsContext } from './_types';

export function useConfigs(): [TConfigsContext, Dispatch<any>, any, TConfigsContext | null] {
  return validate<[TConfigsContext, Dispatch<any>, any, TConfigsContext | null]>(
    useConfigs.name,
    useContext(ConfigsContext),
  );
}

export function useConfigEffects(): void {
  const [, , refreshConfigs] = useConfigs();

  useEffect(() => {
    return (): void => {
      refreshConfigs();
    };
  }, []);
}
