import { TUser } from './user';

export enum RunStatus {
  CREATED = 1000,
  UPLOADED_MATCHED = 2000,
  PROCESSING = 2200,
  PROCESSED_ERROR = 3000,
  PROCESSED_SUCCESS = 4000,
}

export enum UserRole {
  RIDER_ONLY = 1000,
  TEAM_WIDE = 4000,
  TEAM_ADMIN = 5000,
  OMNISCIENT = 8000,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEnumKey = (en: any, key: string | number): number => en[key];
export const getUserRole = (key: string | number): number => getEnumKey(UserRole, key);
export const isAdminUser = (user: TUser): boolean => user.superuser || user.user_role >= UserRole.OMNISCIENT;

export enum RunHealth {
  GOOD = 0,
  NONE = 1,
  AVERAGE = 50,
  WARNING = 100,
  ERROR = 255,
}
