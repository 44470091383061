/** @jsxImportSource @emotion/react */
import dashboardIcon from '../icons/logo-streamlines.png';
import ProfileMenu from './ProfileMenu';
import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useUser } from '../stores/user/useUser';

export default function Header(props: { signOut: any; nav?: ReactElement }): ReactElement {
  const { signOut, nav } = props;
  const [userProfile] = useUser();

  return (
    <header
      css={css({ contain: 'layout' })}
      className="header cr-relative cr-z-5000 cr-grid cr-h-[42px]
      cr-w-full cr-grid-cols-header cr-items-center cr-justify-between cr-shadow-md"
    >
      <div className="cr-flex cr-content-center cr-items-center cr-justify-between">
        <Link to={'/'} className={'cr-h-[42px]'}>
          <img className={'cr-mx-1.5 cr-h-[24px] md:cr-h-[42px] '} src={dashboardIcon} alt="Dashboard icon" />
        </Link>

        {nav ?? (
          <h3 className={'font-forma header-font-size'}>
            <span className={'cr-mr-2'}>|</span>
            {userProfile && userProfile.user_role > 0 ? (
              <>
                <span>{userProfile.show_config ? 'CIRRUS' : 'RIDER'}</span> Portal
              </>
            ) : (
              <>Loading user...</>
            )}
          </h3>
        )}
      </div>
      <div className="cr-self-center">
        <ProfileMenu signOut={signOut} />
      </div>
    </header>
  );
}
