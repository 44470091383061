import { TTableColumn } from '../../components/ListView/types';

export const columnCollapseMdClass = 'cr-collapse md:cr-visible';
export const columnCollapseSmClass = 'cr-collapse sm:cr-visible';
export const columnHiddenSmClass = 'cr-hidden sm:cr-table-cell';
export const columnHiddenMdClass = 'cr-hidden md:cr-table-cell';

export const riderColumns: TTableColumn[] = [
  { label: 'First Name', param: 'first_name' },
  { label: 'Last Name', param: 'last_name' },
  { label: 'Nickname', param: 'nickname' },
  { label: 'Display Name', param: 'displayName' },
  { label: 'Mass', param: 'mass' },
  { label: 'Time Ref', param: 'time_ref' },
  { label: 'Dist Ref', param: 'distance_ref' },
  { label: 'Speed Ref', param: 'speed_ref' },
  { label: 'Power Ref', param: 'power_ref' },
  { label: '_btn_edit', param: 'edit' },
];
