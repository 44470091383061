import { ListViewHeader, ListViewHeaderCell } from '../../../../components/ListView';
import { componentKey } from '../../../../utils/componentKeyGen';
import { columnHiddenMdClass } from '../../../TeamView/menuColumns';
import { runColumns } from './columns';

export default function HeaderRow() {
  return (
    <ListViewHeader className={'font-head cr-z-30 cr-border-b cr-border-solid cr-border-b-blue-900'}>
      <>
        {runColumns.map((column) => {
          switch (column.param) {
            case 'edit':
              return null;
            default: {
              const hiddenParams = ['description'];
              return (
                <ListViewHeaderCell
                  key={componentKey('th-config', column.param)}
                  className={
                    (hiddenParams.includes(column.param) ? columnHiddenMdClass : '') +
                    ' font-head cr-z-30 cr-border-b cr-border-solid cr-border-b-blue-900'
                  }
                  param={column.param}
                >
                  {column.label[0] === '_' ? null : column.label}
                </ListViewHeaderCell>
              );
            }
          }
        })}
      </>
    </ListViewHeader>
  );
}
