/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CSSProperties, forwardRef, ReactElement } from 'react';

type TSimpleButtonProps = {
  onClick?: (e?: any) => void;
  children?: ReactElement | ReactElement[];
  height?: CSSProperties['height'];
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
};

const Button = forwardRef<HTMLButtonElement, TSimpleButtonProps>(
  ({ onClick = (): void => undefined, children, height = '1.75rem', style, className, disabled }, ref) => {
    const cssStyle = css({
      height,
      ...style,
    });

    return (
      <button
        ref={ref}
        disabled={disabled}
        className={className}
        css={cssStyle}
        onClick={(e?): void => {
          e?.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </button>
    );
  },
);
Button.displayName = 'Button';
export default Button;
