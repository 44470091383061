import LinearProgress from '@mui/material/LinearProgress';
import { ReactElement } from 'react';

import { useCustomFormContext } from './FormComponents/FormContext/useCustomFormContext';

type TLoadingBarProps = {
  isLoading?: boolean;
};
export default function LoadingBar({ isLoading = true }: TLoadingBarProps): ReactElement | null {
  return isLoading ? <LinearProgress style={{ marginBottom: '20px' }} /> : null;
}

export function FormLoadingBar(): ReactElement {
  const { isLoading } = useCustomFormContext();
  return <LoadingBar isLoading={isLoading} />;
}
