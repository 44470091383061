import { TRun } from '../../../../common/types/run';
import { ListWarning } from '../ListWarnings';

export default function RunListWarnings<T extends Record<string, any>>(
  list: Omit<T, 'listWarning'>[] = [],
): (T & {
  listWarning: ListWarning;
})[] {
  let firstRun: Record<keyof TRun | string, any> = list[0];
  let addWarning = true;
  const referenceProp = 'calibration';

  return (
    list.reduce((acc: any[], run: any, index: number, array) => {
      const listWarning: ListWarning = { ERROR: undefined, WARNING: undefined, DIFFERENT_CALIBRATION: false };
      if (index > 0 && run.parent) {
        addWarning = array[index - 1].parent === 'first' || run.parent === array[index - 1].parent;

        firstRun = list.find((item) => run.parent === item.letter) ?? firstRun;
      }
      if (index > 0 && addWarning) {
        if (
          !run[referenceProp] ||
          !firstRun[referenceProp] ||
          (!run.parent && run[referenceProp] !== firstRun[referenceProp])
        ) {
          listWarning.ERROR = new Set([referenceProp]);
        } else if (run[referenceProp] !== firstRun[referenceProp]) {
          listWarning.WARNING = new Set([referenceProp]);
        }
      }

      if (run.hasDifferentCalibration) {
        listWarning.DIFFERENT_CALIBRATION = true;
      }

      if (run) {
        run.listWarning = listWarning;
      }
      acc.push(run);
      return acc;
    }, []) ?? []
  );
}
