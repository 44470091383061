import { ReactElement } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';

export default function LockSession({ locked, ...props }: any): ReactElement {
  return (
    <>
      <IconButton color={locked ? 'success' : 'error'} {...props}>
        <Lock />
      </IconButton>
      <Typography color={locked ? 'success' : 'error'}>{locked ? 'Open' : 'Close'}</Typography>
    </>
  );
}
