import { useRef, useEffect, MutableRefObject } from 'react';

export default function usePrevious<T>(value: T | null, getRef?: false): T | null;
export default function usePrevious<T>(value: T | null, getRef?: true): MutableRefObject<T | null>;
export default function usePrevious<T>(
  value: T | null = null,
  getRef?: boolean,
): MutableRefObject<T | null> | T | null {
  const ref = useRef<T | null>(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return getRef ? ref : ref.current;
}
