import { light } from './types/default';
import { contrast } from './types/contrast';
import { dark } from './types/dark';
import { Theme, ThemeTypes } from '../common/interfaces';
import { LocalStorageHandler } from '../utils/toLocalStorage';

const lsKey = 'streamlines_css';

const ThemeHandler = {
  getType(): ThemeTypes {
    return LocalStorageHandler.get<ThemeTypes>(lsKey);
  },
  toDarkTheme(): ThemeTypes {
    return LocalStorageHandler.save<ThemeTypes>(lsKey, ThemeTypes.DARK);
  },
  toLightTheme(): ThemeTypes {
    return LocalStorageHandler.save<ThemeTypes>(lsKey, ThemeTypes.DEFAULT);
  },
  toContrastTheme(): ThemeTypes {
    return LocalStorageHandler.save<ThemeTypes>(lsKey, ThemeTypes.CONTRAST);
  },
  current(): Theme {
    switch (LocalStorageHandler.get<ThemeTypes>(lsKey)) {
      case ThemeTypes.DARK:
        return dark;
      case ThemeTypes.CONTRAST:
        return contrast;
      default:
        return light;
    }
  },
};

export default ThemeHandler;
