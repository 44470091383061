import { format } from 'date-fns';

export const isDateFuture = (date?: string | null) => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return date ? checkDateFormatValid(date) > d : false;
};

export const checkDateFormatValid = (date: string | number) => {
  try {
    return new Date(date);
  } catch {
    return typeof date === 'string' ? new Date(date.replace(/-/g, '/')) : 0;
  }
};

const formatDate = (
  date: Date | string | number | null | undefined,
  type: 'dateOnly' | 'timeOnly' | 'dateTime' | 'shortDate' = 'dateTime',
): string => {
  if (typeof date === 'undefined') {
    return '-';
  }
  if (date === null) {
    return '-';
  }

  if (date instanceof Date) {
    date = new Date(date);
  }

  if (typeof date === 'string' || typeof date === 'number') {
    date = checkDateFormatValid(date);
  }

  switch (type) {
    case 'shortDate':
      return format(date, 'yy-MM-dd HH:mm');
    case 'dateOnly':
      return format(date, 'yyyy-MM-dd');
    case 'timeOnly':
      return format(date, 'HH:mm');
    default:
    case 'dateTime':
      return format(date, 'yyyy MMM d HH:mm');
  }
};
export default formatDate;
