import React, { createContext, CSSProperties, ReactElement, useCallback, useReducer, useRef } from 'react';

type TModalState = {
  modalContent?: ReactElement;
  header?: ReactElement;
  show?: boolean;
  modalStyle?: CSSProperties;
  afterClearModal?: CallableFunction;
};

type TSetContentProps = Omit<TModalState, 'modalComponent' | 'show'> & {
  component?: TModalState['modalContent'];
};

export interface TModalContext extends TModalState {
  setContent: (args: TSetContentProps) => void;
  toggleShow: () => void;
  clearModal: (callback?: CallableFunction) => void;
  callbacks: React.MutableRefObject<Record<string, CallableFunction>>;
}

interface ModalProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const AppModalContext = createContext<TModalContext | null>(null);
AppModalContext.displayName = 'AppModal';

function appModalReducer(
  state: TModalState,
  {
    payload,
    type,
    modalStyle,
    afterClearModal,
    component,
    header,
  }: {
    payload?: ReactElement;
    type: 'set' | 'clear' | 'toggle';
  } & TSetContentProps,
): TModalState {
  switch (type) {
    case 'set':
      return { modalContent: payload ?? component, header, show: true, modalStyle, afterClearModal };
    case 'clear':
      return {
        ...state,
        modalContent: undefined,
        header: undefined,
        show: false,
        modalStyle: undefined,
        afterClearModal: undefined,
      };
    case 'toggle':
      return { ...state, show: !state.show };
    default:
      return state;
  }
}

export default function AppModalProvider({ children }: ModalProviderProps): ReactElement {
  const [state, dispatch] = useReducer(appModalReducer, { modalContent: undefined, show: false });

  const callbackRefs = useRef({});

  const setContent = useCallback(
    (args: TSetContentProps): void => {
      const { component, header, ...rest } = args;
      dispatch({
        type: 'set',
        payload: component,
        header,
        ...rest,
      });
    },
    [dispatch],
  );

  const toggleShow = useCallback((): void => dispatch({ type: 'toggle' }), [dispatch]);
  const clearModal = useCallback(
    (callback?: CallableFunction): void => {
      callback?.();
      dispatch({ type: 'clear' });
    },
    [dispatch],
  );

  return (
    <AppModalContext.Provider value={{ ...state, setContent, toggleShow, clearModal, callbacks: callbackRefs }}>
      {children}
    </AppModalContext.Provider>
  );
}
