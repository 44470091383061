import { TConfig } from '../../../../common/types/config';
import { useConfigs } from '../../../../stores/configs/useConfigs';
import { useRuns } from '../../../../stores/runs/useRuns';
import { getRunsWithData } from '../../../../stores/runs/getters';
import { ResultRunsType } from '../../../../pages/ViewRuns/hooks/useViewResults';
import { useSWRCalcDataList } from './getCalcDataList';

type Run = {
  average: Record<string, number[]>;
  description: string;
  group_id: string;
  id: string;
  run_ts: string | null;
  traverse: Record<string, number[]>;
};
type Config = {
  average: Run['average'];
  baseline: any;
  deltas: any;
  description: string;
  id: string;
  letter: string;
  runs: Run[];
};
type CalcData = {
  errors?: string;
  configs: Config[];
  date: string;
  description: string;
  id: string;
};
const runReducer = <T>(acc: T[], { runs }: { runs: T[] }): T[] => [...acc, ...runs];

type TOptions = {
  only?: 'configs' | 'runs' | 'traverses';
  dependencies?: any[];
};
export default function useGetCalcDataList(sessionUuid?: string, options?: TOptions): any[] {
  const [{ configsByTestSessionId }] = useConfigs();
  const [{ selectedRuns, runsById }] = useRuns();

  const resultConfigs = sessionUuid
    ? configsByTestSessionId[sessionUuid]?.sort((a, b) => (a.letter > b.letter ? 1 : -1))
    : [];

  const resultRuns: ResultRunsType = resultConfigs?.length
    ? resultConfigs.reduce(
        (acc: any, config: TConfig) =>
          config.run_ids
            ? {
                all: [...acc.all, ...config.run_ids],
                byConfigId: { ...acc.byConfigId, [config.id]: config.run_ids },
                configIds: !acc.configIds.includes(config.id) ? [...acc.configIds, config.id] : acc.configIds,
              }
            : acc,
        { all: [], byConfigId: {}, configIds: [] },
      )
    : { all: selectedRuns, byConfigId: {}, configIds: [] };

  const runsWithData = getRunsWithData(resultRuns.all, runsById, true);

  const {
    data: calcDataDictChannels,
    error,
    isLoading,
  } = useSWRCalcDataList<CalcData>({
    selectedRuns: runsWithData,
    sessionUuid,
  });

  if (calcDataDictChannels && options?.only) {
    switch (options.only) {
      case 'configs':
        return [
          calcDataDictChannels[options.only].reduce(
            (acc, config) => ({
              ...acc,
              [config.id]: config,
            }),
            {},
          ),
          isLoading,
          error,
        ];
      case 'runs':
        return [calcDataDictChannels.configs.reduce<Run[]>(runReducer, []), isLoading, error];
      case 'traverses':
        return [
          calcDataDictChannels.configs.reduce<Run[]>(runReducer, []).map(({ traverse }) => traverse),
          isLoading,
          error,
        ];
    }
  }
  return [calcDataDictChannels, isLoading, error];
}
