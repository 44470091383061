import { requestWithAxios, TRequestProps } from '../../utils/requestWithAxios';

interface TAjaxGroup extends Record<string, any> {
  sessionId?: string;
  verb?: TRequestProps['verb'];
  controller?: AbortController;
}

export async function updateSession({
  sessionId,
  controller,
  verb = 'PUT',
  ...rest
}: TAjaxGroup): Promise<Record<string, any>> {
  const options: TRequestProps = {
    endpoint: !sessionId ? '/test-sessions' : `/test-sessions/${sessionId}`,
    verb,
    abortSignal: controller?.signal,
    ...rest,
  };

  try {
    const resp = await requestWithAxios(options);

    if (resp && resp.status === 200) {
      return resp.data;
    }
    return resp || {};
  } catch (e: any) {
    const message = `URL: ${options.endpoint}! ${e}`;
    console.error(message);
    console.warn(options);
    return new Promise((resolve) => resolve({ errors: message }));
  }
}
