import Row from '../../layouts/Row';
import logo from '../../icons/logo-streamlines.png';
import { ReactElement } from 'react';
import useAppFooter from './useAppFooter';

export default function AppFooter(): ReactElement {
  const { footerMessage } = useAppFooter();
  return (
    <Row style={{ position: 'relative' }}>
      <Row
        justifyItems={'start'}
        style={{ position: 'absolute', padding: '0 1rem' }}
        columns={1}
        columnWidth={'minmax(min-content, 25%)'}
      >
        {footerMessage}
      </Row>
      <img className="logo" width="273" src={logo} alt="Streamlines logo" />
    </Row>
  );
}
