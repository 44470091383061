import { CalibrationActionTypes, TConfigsContext } from './_types';
import { sortCalibrationByDateAndDefault } from '../../utils/state/calibrations';

export default function calibrationReducer(
  state: TConfigsContext,
  { type, payload }: { type: CalibrationActionTypes; payload: any | boolean },
): TConfigsContext {
  switch (type) {
    case CalibrationActionTypes.SET_DATA:
    case CalibrationActionTypes.SET_ALL_CALIBRATIONS: {
      const calibrations = payload || [];
      calibrations.sort(sortCalibrationByDateAndDefault);
      return {
        ...state,
        calibrations,
        calibrationsById: calibrations.reduce((acc: any, item: any) => ({ ...acc, [item.id]: item }), {}),
        calibrationGates: calibrations.reduce(
          (acc: any, item: any) =>
            item.gates_id
              ? {
                  ...acc,
                  [item.gates_id]: item.gates,
                }
              : acc,
          {},
        ),
      };
    }
    case CalibrationActionTypes.SET_NEW_CALIBRATION: {
      return {
        ...state,
        newConfigs: payload,
      };
    }

    case CalibrationActionTypes.SET_SELECTED_CALIBRATIONS: {
      if (Array.isArray(payload)) {
        return { ...state, selectedCalibrationId: payload };
      }
      const index = state.selectedCalibrationId.indexOf(payload);
      const newSelection =
        index > -1
          ? state.selectedCalibrationId.filter((id) => id !== payload)
          : state.selectedCalibrationId.concat([payload]);
      return { ...state, selectedCalibrationId: [...newSelection] };
    }

    case CalibrationActionTypes.CLEAR_EDITING_CALIBRATIONS:
      return { ...state, selectedCalibrationId: [] };

    case CalibrationActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
}
