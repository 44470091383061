import React, { createContext, ReactElement, useReducer } from 'react';

type TAppFooterState = {
  footerMessage?: ReactElement;
};

export interface TAppFooterContext extends TAppFooterState {
  setMessage: (component?: ReactElement) => void;
  clearMessage: () => void;
}

interface AppFooterProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const AppFooterContext = createContext<TAppFooterContext>({
  setMessage: () => undefined,
  clearMessage: () => undefined,
});
AppFooterContext.displayName = 'AppFooter';

function appFooterReducer(
  state: TAppFooterState,
  { payload, type }: { payload?: ReactElement; type: 'set' | 'clear' },
): TAppFooterState {
  switch (type) {
    case 'set':
      return { footerMessage: payload };
    case 'clear':
      return { footerMessage: undefined };
    default:
      return state;
  }
}

export default function AppFooterProvider({ children }: AppFooterProviderProps): ReactElement {
  const [{ footerMessage }, dispatch] = useReducer(appFooterReducer, { footerMessage: undefined });

  const setMessage = (component?: ReactElement): void => dispatch({ type: 'set', payload: component });
  const clearMessage = (): void => dispatch({ type: 'clear' });

  return (
    <AppFooterContext.Provider value={{ footerMessage, setMessage, clearMessage }}>
      {children}
    </AppFooterContext.Provider>
  );
}
