import { ReactElement } from 'react';
import { Button, FormControl } from '@mui/material';
import { ListViewItem } from '../types';
import Column from '../../../layouts/Column';
import Accordion, { AccordionWrapper } from '../../Accordion';
import Grid from '../../../layouts/Grid';
import Row from '../../../layouts/Row';
import { FilterAlt } from '@mui/icons-material';
import FilterTitle from './FilterTitle';
import { useListView } from '../useListView';

type TFilterProps = {
  onClose?: () => void;
  customFilterField?: ReactElement;
};
export default function Filter({ onClose = (): void => undefined, customFilterField }: TFilterProps): ReactElement {
  const { filterFields, filter, setFilter } = useListView();
  const noTopLine = true;

  const resetFilter = (): void => {
    setFilter({
      hidden: false,
      riders: [],
      rider_id: [],
      run_ts: { startDate: null, endDate: null, dateEnabled: false },
    });
    onClose();
  };

  return (
    <div className={'cr-flex cr-h-full cr-w-full cr-flex-1 cr-pt-[7px] '}>
      <Grid
        columns={1}
        columnGap={'0.5em'}
        rowGap={'0.5em'}
        columnWidth={'1fr 30%'}
        rowHeight={'max-content'}
        style={{
          /* eslint-disable */
          gridTemplateAreas: `
          ${noTopLine ? '' : 'filterTopLine filterTopLine'} 
          'customFilter filterTitle'
          ${!filterFields ? '' : "'filterContent filterContent'"} 
          `,
          /* eslint-enable */
        }}
      >
        {customFilterField && (
          <Row display={'flex'} style={{ gridArea: 'customFilter' }} justifyContent={'start'}>
            {customFilterField}
          </Row>
        )}
        {filterFields && (
          <AccordionWrapper>
            <Accordion
              {...{ noTopLine }}
              noBottomLine
              icon={<FilterAlt />}
              title={<FilterTitle {...filter} />}
              content={({ onToggleOpen }): ReactElement | undefined => {
                return (
                  filterFields && (
                    <div className={'cr-overflow-scroll'} style={{ padding: '0.5rem 1rem 1rem', width: '100%' }}>
                      <Column
                        justifyItems={'stretch'}
                        justifyContent={'start'}
                        rowGap={'0.725rem'}
                        style={{ padding: '1rem 1rem' }}
                      >
                        {Object.keys(filterFields).map((filterKey) => (
                          <FormControl key={filterKey} component="fieldset">
                            {filterFields[filterKey as keyof ListViewItem]?.(filter, setFilter)}
                          </FormControl>
                        ))}
                      </Column>
                      <br />
                      <div className="line">
                        <Button variant="contained" onClick={resetFilter} className="classicButton" color="secondary">
                          Reset
                        </Button>
                        <Button variant="contained" onClick={onToggleOpen} className="classicButton ">
                          Hide
                        </Button>
                      </div>
                    </div>
                  )
                );
              }}
              topLineStyle={{ gridArea: 'filterTopLine' }}
              bottomLineStyle={{ gridArea: 'filterBottomLine' }}
              contentStyle={{ gridArea: 'filterContent', overflow: 'hidden' }}
              titleStyle={{ gridArea: 'filterTitle' }}
            />
          </AccordionWrapper>
        )}
      </Grid>
    </div>
  );
}
