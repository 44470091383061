/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
import { css, CSSObject } from '@emotion/react';
import { CSSProperties, ReactElement, ReactNode } from 'react';

type TColumn = {
  style?: CSSObject;
  display?: string;
  rows?: number | 'auto-fill' | 'auto-fit';
  rowHeight?: string;
  columnStart?: number;
  columnEnd?: number;
  rowStart?: number;
  rowEnd?: number;
  justifyContent?: string;
  justifyItems?: string;
  textAlign?: CSSProperties['textAlign'];
  columnGap?: string;
  rowGap?: string;
  children: ReactNode | ReactNode[] | null | undefined;
  [key: string]: any;
};

const Column = forwardRef<HTMLDivElement | null, TColumn>((props, ref): ReactElement => {
  const {
    style = {},
    display,
    rows = 1,
    rowHeight = 'auto',
    columnStart = undefined,
    columnEnd = undefined,
    rowStart = undefined,
    rowEnd = undefined,
    justifyContent = 'center',
    justifyItems = 'center',
    textAlign = 'center',
    columnGap,
    rowGap,
    className = '',
    ...otherProps
  } = props;

  const newStyle: CSSObject = {
    display,
    columnGap,
    rowGap,
    justifyContent,
    justifyItems,
    textAlign,
    gridAutoFlow: display === 'grid' ? 'row' : undefined,
    gridColumnStart: display === 'grid' ? columnStart : undefined,
    gridColumnEnd: display === 'grid' ? columnEnd : undefined,
    gridRowStart: display === 'grid' ? rowStart : undefined,
    gridRowEnd: display === 'grid' ? rowEnd : undefined,
    gridTemplateRows: display === 'grid' ? `repeat( ${rows}, ${rowHeight} )` : undefined,
    gridTemplateColumns: display === 'grid' ? '1fr' : undefined,
    width: '100%',
    ...style,
  };

  const columnStyle = css({ ...newStyle });
  return <div ref={ref} className={`column ${className}`} css={columnStyle} {...otherProps} />;
});
Column.displayName = 'Column';

export default Column;
