import { useGetAndDispatch } from './getData';
import { PublicConfiguration } from 'swr/_internal';
import { requestWithAxios } from '../../utils/requestWithAxios';
import { TTestSession } from '../../common/types/session';
import { sortSessions } from '../../pages/Sessions/List/utils';

export class SessionsFetcher {
  static get key() {
    return { endpoint: '/test-sessions', scope: 'with_runs' };
  }

  static fetcher = async (args: typeof SessionsFetcher.key) => {
    const { data } =
      (await requestWithAxios({
        ...args,
        verb: 'GET',
      })) || {};
    return data;
  };
}

export const useSessionsGet = (dispatch: any, swrOptions?: Partial<PublicConfiguration>) => {
  return useGetAndDispatch(dispatch)<TTestSession[]>(
    {
      ...SessionsFetcher.key,
      swrOptions,
    },
    sortSessions,
  );
};
