import { RunStatus } from '../../common/types/enums';
import { TRun } from '../../common/types/run';
import { matchConfigRunTs, matchRunRiderId, matchSessionId } from '../../pages/Configs/ConfigsList/filterConditions';
import { ReactElement } from 'react';
import RiderIdSelect from '../../pages/Configs/ConfigsList/FilterFields/RiderIdSelect';
import FilterDateRange from '../../pages/Configs/ConfigsList/FilterFields/FilterDateRange';
import SessionSelect from '../../pages/Configs/ConfigsList/FilterFields/SessionSelect';
import { TConfig } from '../../common/types/config';

export const isRunStatus =
  (status: RunStatus) =>
  (checkStatus: RunStatus = RunStatus.CREATED): boolean =>
    checkStatus === status;
export const runsWithStatus =
  (status: RunStatus) =>
  (runs: TRun[] = []) =>
    runs.filter((run) => isRunStatus(status)(run?.run_status));
export const runsWithStatusSuccess = runsWithStatus(RunStatus.PROCESSED_SUCCESS);
export const runFromId = (runsById: Record<string, TRun>) => (id: string) => runsById[id];
export const runsFromMap = (list: string[] = [], map: (item: string) => TRun): TRun[] => list.map(map);
export const hasSuccessfulRuns = (runs: TRun[]): boolean => !!runsWithStatusSuccess(runs).length;

export const runsFilterConditions = (configsById?: Record<string, TConfig>): any => ({
  rider_id: matchRunRiderId(),
  run_ts: matchConfigRunTs(),
  testsession_id: matchSessionId(configsById),
  run_status: (item: TRun, showStatus: RunStatus[] = []): boolean =>
    item.run_status ? showStatus.includes(item.run_status) : false,
});
export const runFilter = {
  rider_id: (filter: any, setFilter: any): ReactElement => <RiderIdSelect {...{ filter, setFilter }} />,
  testsession_id: (filter: any, setFilter: any): ReactElement => <SessionSelect {...{ filter, setFilter }} />,
  run_ts: (filter: any, setFilter: any): ReactElement => <FilterDateRange {...{ filter, setFilter }} />,
};
