import { FC, ReactElement } from 'react';

export const withInjectedKeys = <Props, InjectedKeys extends keyof Props>(
  Element: FC<Props>,
  injectKeys: Pick<Props, InjectedKeys>,
): ((props: Omit<Props, InjectedKeys>) => ReactElement) => {
  const HOC = (props: Omit<Props, InjectedKeys>): ReactElement => <Element {...(props as Props)} {...injectKeys} />;
  HOC.displayName = 'withInjectedKeys';
  return HOC;
};
