import { useRuns } from '../../stores/runs/useRuns';
import { ReactElement } from 'react';
import Row from '../../layouts/Row';
import { ArrowDownwardOutlined } from '@mui/icons-material';
import LineDivider from '../../layouts/LineDivider';
import { setSort } from '../../stores/runs/setters';
import { getRunIds } from '../../stores/runs/getters';
import { Plus } from '../../icons/Plus';
import EditButton from '../Buttons/EditButton';
import { Tooltip } from '@mui/material';
import { IconWrap } from '../Nav';
import { useListView } from './useListView';

export default function Headers(): ReactElement {
  const [state, runsDispatch] = useRuns();
  const { sortBy } = state;
  const {
    onClick = (): undefined => undefined,
    columns,
    classes,
    list,
    isSelected,
    selectAll = false,
    icon,
    onAddLabel = 'Add new',
    onAdd,
    columnWidths,
  } = useListView();

  const sorted = <ArrowDownwardOutlined sx={{ fontSize: '12px' }} />;
  const allSelected = list?.reduce((acc, item) => (isSelected ? isSelected(item) && acc : acc), true);
  return (
    <>
      <LineDivider style={{ position: 'sticky', zIndex: '999' }} />
      <Row
        className={`font-head ${classes?.header}`}
        justifyItems={'start'}
        alignItems={'center'}
        gridAutoFlow={'column'}
        columnGap={'0.25em'}
        style={{
          overflow: 'visible auto',
          background: 'inherit',
          position: 'sticky',
          zIndex: '999',
          top: 0,
          minHeight: '3em',
          height: 'auto',
          span: { fontWeight: 400, textTransform: 'uppercase', letterSpacing: '1px' },
          gridTemplateColumns: columnWidths({ columns, withIcon: !!icon, withSelectAll: selectAll }),
        }}
      >
        {icon ? <>&nbsp;</> : null}
        {selectAll ? (
          <input
            readOnly
            id="selectAllRuns"
            type="checkbox"
            checked={allSelected}
            style={{ zoom: 1.5 }}
            data-current={getRunIds(state.runs)}
            onClick={(): any => {
              onClick(allSelected || !list ? [] : list);
            }}
          />
        ) : null}
        {columns.map(({ label, param }) =>
          label.includes('_') ? (
            label === '_btn_edit' ? null : (
              <span key={label + param}></span>
            )
          ) : (
            <span key={label + param} onClick={(): void => setSort(label, runsDispatch)}>
              <span>{label}</span>
              {sortBy === label.toLowerCase() ? sorted : null}
            </span>
          ),
        )}
        {!!onAdd && (
          <Tooltip title={onAddLabel} sx={{ zIndex: '9999' }}>
            <IconWrap>
              <EditButton
                data-testid={`btn-add-${onAddLabel}`}
                color="success"
                icon={<Plus />}
                onClick={() => onAdd?.()}
                label={onAddLabel}
                disabled={onAddLabel === 'Add disabled'}
              />
            </IconWrap>
          </Tooltip>
        )}
      </Row>
      <LineDivider style={{ position: 'sticky', zIndex: '999' }} />
    </>
  );
}
