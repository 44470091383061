import { Theme } from '../../../common/interfaces';

export const contrast: Theme = {
  colors: {
    primary: '#000',
    secondary: '#646262',
    danger: '#00ff00',
    success: '#29bead',
    text: '#fff',
    background: '#3b3b3b',
    lighterGray: '#F8F8F8',
  },
  textColors: {
    primary: '#fff',
    secondary: '#fff',
    danger: '#fff',
    success: '#0ea18e',
    text: '#000',
    background: '#fff',
    lighterGray: '#000',
  },
  bckgColors: {
    primary: '#fff',
    secondary: '#fff',
    danger: '#fff',
    success: '#0ea18e',
    text: '#000',
    background: '#fff',
    lighterGray: '#000',
  },
};
