export const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
};
