import { TTableColumn } from '../../../../components/ListView/types';

export const runColumns: TTableColumn[] = [
  { label: 'Run #', param: 'run_number', className: 'cr-w-[50px]' },
  { label: 'Time', param: 'run_ts_tz', className: 'cr-w-[120px]' },
  { label: 'Description', param: 'description' },
  { label: 'Drag', param: 'average' },
  { label: '+/-', param: 'deltas' },
  { label: '_btn', param: 'is_next', className: 'cr-w-[0px] sm:cr-w-[50px]' },
  { label: '_icon', param: 'icon' },
  { label: '_btn_edit', param: 'edit' },
];
