/** @jsxImportSource @emotion/react */
import { useTheme } from '../theme/context/useThemeUpdate';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { CSSProperties } from 'react';

type TLineDividerProps = {
  style?: CSSProperties;
};
const LineDivider = (props: TLineDividerProps): ReactJSXElement => {
  const { style } = props;
  const theme = useTheme();

  const styled = theme.createStyles({
    hr: {
      display: 'block',
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderStyle: 'inset',
      borderWidth: 'thin',
      alignSelf: 'end',
      width: '99%',
      height: '1px',
      borderColor: 'currentcolor',
      color: 'currentcolor',
      backgroundColor: 'currentcolor',
      ...style,
    },
  });

  return <hr css={styled.hr} />;
};

export default LineDivider;
