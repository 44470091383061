import { useReducer, useRef } from 'react';

interface TToggleState {
  on: boolean;
  index?: number;
  indices?: number[];
}

interface TToggleStateWithPrevState extends TToggleState {
  prevState?: TToggleState;
}

enum EToggleActionTypes {
  TOGGLE = 'toggle',
  RESET = 'reset',
}

type TToggleAction = {
  type: EToggleActionTypes;
  index?: number;
  initialState?: TToggleState | undefined;
};

function toggleReducer(state: TToggleStateWithPrevState, action: TToggleAction): TToggleStateWithPrevState {
  const { type, index, initialState } = action;
  switch (type) {
    case EToggleActionTypes.TOGGLE: {
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      const { prevState, ...rest } = { ...state };
      if (index === undefined) {
        return { on: !state.on, index, prevState: rest };
      }

      const newIndices = state.indices?.includes(index)
        ? state.indices.filter((item) => item !== index)
        : state.indices?.concat([index]);

      return { on: rest.index !== index ? true : !state.on, index, prevState: rest, indices: newIndices };
    }
    case EToggleActionTypes.RESET: {
      return initialState || state.prevState || state;
    }
    default: {
      throw new Error(`Unsupported type: ${type}`);
    }
  }
}

type TSimpleToggleProps = {
  initial?: boolean;
  reducer?: (state: TToggleStateWithPrevState, action: TToggleAction) => TToggleStateWithPrevState;
};

export interface TSimpleToggle extends TToggleState {
  prevState: () => TToggleState | undefined;
  toggle: (index?: number) => void;
  reset: () => void;
}

const useSimpleToggle = (props: TSimpleToggleProps = {}): TSimpleToggle => {
  const { initial = false, reducer = toggleReducer } = props;
  const { current: initialState } = useRef<TToggleStateWithPrevState>({ on: initial, indices: [] });
  const [state, dispatch] = useReducer(reducer, initialState);

  const { on, index, prevState, indices } = state;
  return {
    on,
    index,
    indices,
    prevState(): TToggleState | undefined {
      return prevState;
    },
    toggle(index): void {
      dispatch({ type: EToggleActionTypes.TOGGLE, index });
    },
    reset(): void {
      dispatch({ type: EToggleActionTypes.RESET, initialState });
    },
  };
};

export default useSimpleToggle;
