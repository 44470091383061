import { createContext, Dispatch, ReactElement, useReducer } from 'react';
import { sessionReducer } from './reducer';

import { TBaseContext } from '../types';
import { TTestSessionContext } from './_types';
import { useSessionsGet } from '../ajax/sessions';

const defaultState: TTestSessionContext = {
  sessions: [],
  byId: {},
  newSession: { id: '', default_calibration: null, isNew: true },
  selected: [],
  isLoading: false,
};

export const TestSessionsContext = createContext<[TTestSessionContext, Dispatch<any>, any] | null>(null);
TestSessionsContext.displayName = 'TestSessionsContext';

export default function TestSessionsProvider({ children, ...props }: TBaseContext): ReactElement {
  const [state, sessionsDispatch] = useReducer(sessionReducer, defaultState);
  const { mutate } = useSessionsGet(sessionsDispatch);

  return <TestSessionsContext.Provider value={[state, sessionsDispatch, mutate]} {...{ ...props, children }} />;
}
