import { ListViewHeader, ListViewHeaderCell } from '../../../../components/ListView';
import { componentKey } from '../../../../utils/componentKeyGen';
import { columnHiddenSmClass } from '../../../TeamView/menuColumns';
import { configTestSessionColumns } from './columns';

export default function HeaderRow() {
  return (
    <ListViewHeader>
      <>
        {configTestSessionColumns.map((column) => {
          switch (column.param) {
            default: {
              const hiddenParams = ['baseline', 'calibration_status', 'icon'];
              return (
                <ListViewHeaderCell
                  key={componentKey('th-config', column.param)}
                  className={
                    (hiddenParams.includes(column.param) ? columnHiddenSmClass : '') +
                    ' font-head cr-z-40 cr-border-b cr-border-solid cr-border-b-blue-900 cr-bg-blue-100 cr-text-blue-800 '
                  }
                  param={column.param}
                >
                  {column.label[0] === '_' ? null : column.label}
                </ListViewHeaderCell>
              );
            }
          }
        })}
      </>
    </ListViewHeader>
  );
}
