import { ReactElement } from 'react';
import { Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useRiders } from '../../../../stores/riders/useRiders';
import { FilterFieldProps } from '../../../../components/ListView/types';

export default function RiderIdSelect({ filter, setFilter }: FilterFieldProps): ReactElement {
  const [{ riders, ridersById }] = useRiders();
  const onRiderChange = (event: any): void => {
    const value = event.target.value;

    if (value?.includes('all') || value.length === riders.length) {
      const hasAll = filter.rider_id?.includes('all') ? [] : ['all'];
      setFilter({
        ...filter,
        rider_id: hasAll,
        riders: hasAll,
      });
    } else {
      setFilter({ ...filter, rider_id: value, riders: value?.map((id: string) => ridersById[id]?.displayName) });
    }
  };
  return (
    <>
      <InputLabel shrink id="select-four">
        Rider
      </InputLabel>
      <Select
        labelId="select-one"
        id={'riders'}
        input={<OutlinedInput notched label={'Rider'} />}
        value={(filter.rider_id?.includes('all') ? riders.map((rider) => rider.id) : filter.rider_id) ?? []}
        fullWidth
        multiple
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={(selected): string => {
          return selected.indexOf('all') > -1 || selected.length === riders.length
            ? 'All'
            : selected.map((riderId: string) => ridersById[riderId].displayName).join(', ');
        }}
        onChange={onRiderChange}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={filter.rider_id?.indexOf('all') > -1}
              indeterminate={filter.rider_id?.length > 0 && filter.rider_id?.length < riders.length}
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {riders.map((item, index: number) => {
          return (
            <MenuItem key={`riders_${index}`} value={item.id}>
              <ListItemIcon>
                <Checkbox checked={filter.rider_id?.indexOf(item.id) > -1 || filter.rider_id?.indexOf('all') > -1} />
              </ListItemIcon>
              <ListItemText primary={item.displayName} />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}
