import { forwardRef, PropsWithChildren, ReactElement } from 'react';
import ListRowError from './ErrorBoundry/ListRowError';

export const ListViewRow = forwardRef<
  HTMLTableRowElement,
  PropsWithChildren<{
    className?: string;
    index?: number;
    onClick?: CallableFunction;
    isSelected?: boolean;
  }>
>(function ListViewRow(props, ref): ReactElement {
  return (
    <ListRowError>
      <tr
        onClick={() => props.onClick?.()}
        ref={ref}
        className={
          (props.className ?? '') + (props.isSelected ? ' cr-bg-green-200 cr-font-semibold cr-text-green-600' : '')
        }
      >
        {props.children}
      </tr>
    </ListRowError>
  );
});
