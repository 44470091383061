import { ReactElement, Suspense } from 'react';
import { Route } from 'react-router-dom';
import LoadingBar from '../../components/LoadingBar';
import Page from './Page';
import { CirrusRoute } from './CirrusRoutes';

export const routerMap = (
  { path, element, nestedRoutes, label, hidden }: CirrusRoute,
  index: number,
): ReactElement | null =>
  !hidden ? (
    <Route
      key={path + '-' + index}
      path={path}
      element={
        <Suspense fallback={<LoadingBar isLoading={true} />}>
          <Page label={label}>{element}</Page>
        </Suspense>
      }
    >
      {nestedRoutes ? nestedRoutes.map(routerMap) : null}
    </Route>
  ) : null;
