import { useGetAndDispatch } from './getData';
import { PublicConfiguration } from 'swr/_internal';
import { requestWithAxios } from '../../utils/requestWithAxios';
import { TConfig } from '../../common/types/config';

export class CalibrationsFetcher {
  static get key() {
    return { endpoint: '/calibrations', scope: 'with_run_ids' };
  }

  static fetcher = async (args: typeof CalibrationsFetcher.key) => {
    const { data } =
      (await requestWithAxios({
        ...args,
        verb: 'GET',
      })) || {};
    return data;
  };
}

export const useCalibrationsGet = (dispatch: any, swrOptions?: Partial<PublicConfiguration>) => {
  return useGetAndDispatch(dispatch)<TConfig[]>({
    ...CalibrationsFetcher.key,
    swrOptions,
  });
};
