import { Theme } from '../../common/interfaces';
import { makeStyles } from '@mui/styles';
import { createTheme, Theme as MUITheme } from '@mui/material/styles';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type {} from '@mui/lab/themeAugmentation';

export const useStyles = makeStyles((theme: MUITheme) => ({
  improvement: {
    color: theme.palette.success.main,
  },
  degradation: {
    color: theme.palette.error.main,
  },
}));

export const themeMUI = (t: Theme): any =>
  createTheme({
    spacing: (factor: number) => `${0.5 * factor}rem`,
    components: {
      MuiDrawer: {
        styleOverrides: {
          root: { zIndex: 99999 },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: { zIndex: 999 },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: { zIndex: 9999999, width: '80%' },
        },
      },
      MuiRadio: {
        defaultProps: { classes: { root: 'cr-text-blue-900' } },
        styleOverrides: { root: { padding: '4px' } },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: ({ theme }) => ({
            color: theme.palette.secondary.main,
          }),
          root: ({ theme }) => ({
            padding: 8,
            '& .MuiSwitch-track': {
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 22 / 2,
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
              },
              '&::before': {
                backgroundImage:
                  /* eslint-disable-next-line max-len */
                  'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>\')',
                left: 12,
              },
              '&::after': {
                backgroundImage:
                  /* eslint-disable-next-line max-len */
                  'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="currentColor" d="M19,13H5V11H19V13Z" /></svg>\')',
                right: 12,
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: 16,
              height: 16,
              margin: 2,
            },
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: () => ({
            backgroundColor: '#fff',
          }),
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant && {
              boxShadow: 'none',
              fontSize: '1em',
              padding: '0.25em 1em',
              minWidth: 'min-content',
              minHeight: 'min-content',
            }),
            justifyContent: 'space-evenly',
            ...(ownerState.variant === 'contained' && {
              border:
                ownerState.color !== 'inherit' && ownerState.color
                  ? `solid thin ${theme.palette[ownerState.color].main}`
                  : undefined,
            }),
            ':hover': {
              backgroundColor:
                ownerState.color !== 'inherit' && ownerState.color ? theme.palette[ownerState.color].main : '#fff',
              color: ownerState.color !== 'inherit' && ownerState.color ? theme.palette[ownerState.color].dark : '#fff',
            },
          }),
          startIcon: ({ ownerState }) => ({
            ...(ownerState.variant && {
              marginRight: '0',
              marginLeft: '0',
            }),
            '&>*:nth-of-type(1)': { fontSize: '16px' },
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: () => ({
            fontSize: '0.875em',
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
          }),
        },
      },
      MuiInputLabel: {
        defaultProps: { shrink: true, classes: { root: 'cr-text-blue-900 cr-max-w-full' } },
        styleOverrides: {
          shrink: () => ({
            fontSize: '.675rem',
            transform: 'translate(5px, -14px)',
          }),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: { fontSize: '.875rem' },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          size: 'small',
        },
      },
      MuiSelect: {
        defaultProps: { notched: true },
      },
      MuiOutlinedInput: {
        defaultProps: {
          size: 'small',
          classes: {
            notchedOutline: 'cr-border-blue-900',
            root: 'cr-bg-white cr-text-blue-900',
            focused: 'cr-border-green-500',
          },
        },
        styleOverrides: {
          notchedOutline: () => ({
            top: 0,
            legend: { display: 'none' },
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: () => ({
            width: '100%',
          }),
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: () => ({
            textTransform: 'uppercase',
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: () => ({
            zIndex: 99999,
          }),
          tooltip: () => ({
            backgroundColor: '#03365D',
          }),
        },
      },
      MuiFormControl: {
        defaultProps: {
          classes: { root: 'cr-mt-3 ' },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: { root: { marginLeft: '-5px' } },
      },
      MuiTypography: {
        defaultProps: {
          classes: { root: 'cr-text-sm cr-family cr-font-main' },
        },
      },
    },
    palette: {
      contrastThreshold: 4.5,
      primary: {
        main: t.colors.primary,
        contrastText: t.textColors.primary,
      },
      secondary: {
        main: t.colors.secondary,
        contrastText: t.textColors.secondary,
      },
      error: {
        main: t.colors.danger,
        contrastText: t.textColors.danger,
      },
      success: {
        main: t.colors.success,
        dark: t.bckgColors.success,
        contrastText: t.textColors.success,
      },
      background: {
        default: t.colors.background,
      },
    },
  });
