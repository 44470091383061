import { requestWithAxios, showRequestError, TRequestProps } from '../../utils/requestWithAxios';
import useSWR, { KeyedMutator } from 'swr';
import { PublicConfiguration } from 'swr/_internal';
import { useEffect } from 'react';
import { setData, setIsLoading } from '../runs/setters';
import { useProfileGet } from './profile';

interface TAjaxReprocess extends Record<string, any> {
  endpoint?: string;
  dataType?: 'runs' | 'groups' | 'riders' | 'test-sessions' | 'upload-devices' | 'calibrations';
  controller?: AbortController;
  swrOptions?: Partial<PublicConfiguration>;
  teamId?: string;
  queryParams?: Record<string, any>;
}

type SWRData<T> = {
  data: T | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<T>;
  error: any;
  isValidating: boolean;
};

export function useSWRGet<TData = any | any[]>({
  endpoint,
  dataType,
  controller,
  swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    dedupingInterval: 2000,
  },
  teamId,
  ...queryParams
}: TAjaxReprocess): SWRData<TData> {
  const {
    data: { team_id },
  } = useProfileGet();
  const options: TRequestProps = {
    endpoint: endpoint ?? `/${dataType}`,
    verb: 'GET',
    abortSignal: controller?.signal,
    ...queryParams,
  };

  const { data, isLoading, mutate, error, isValidating } = useSWR(
    teamId || team_id ? { teamId: teamId ?? team_id, endpoint: endpoint ?? `/${dataType}`, ...queryParams } : null,
    async () => {
      const { data } = (await requestWithAxios(options)) || {};
      return data;
    },
    swrOptions,
  );

  return {
    data,
    isLoading,
    mutate,
    error,
    isValidating,
  };
}

export const useGetAndDispatch =
  (dispatch: any) =>
  <T = any>(options: TAjaxReprocess, sort?: NonNullable<any>) => {
    const { data: profile } = useProfileGet();
    options.teamId = profile.team_id;

    const { data, error, isLoading, mutate } = useSWRGet<T>(options);

    useEffect(() => {
      if (data) {
        setData(data, dispatch, sort);
      }
    }, [data]);

    useEffect(() => {
      setIsLoading(isLoading, dispatch);
    }, [isLoading]);

    return { data, error, isLoading, mutate };
  };

export async function getData({
  endpoint,
  dataType,
  controller,
  ...queryParams
}: TAjaxReprocess): Promise<any[] | any> {
  const options: TRequestProps = {
    endpoint: endpoint ?? `/${dataType}`,
    verb: 'GET',
    abortSignal: controller?.signal,
    ...queryParams,
  };

  try {
    const { data } = (await requestWithAxios(options)) || {};
    return data;
  } catch (e: any) {
    return new Promise((resolve) => resolve(showRequestError(e, options)));
  }
}
