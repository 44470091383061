import { TTestSessionWithRuns } from '../../common/types/session';

export type TTestSessionContext = {
  sessions: TTestSessionWithRuns[];
  byId: Record<string, TTestSessionWithRuns>;
  newSession: Partial<TTestSessionWithRuns> & Pick<TTestSessionWithRuns, 'id'>;
  selected: (string | number)[];
  isLoading: boolean;
};

export enum SessionsActionTypes {
  SET_DATA = 'SET_DATA',
  SET_SESSIONS = 'SET_SESSIONS',
  ADD_SESSION = 'ADD_SESSION',
  REMOVE_SESSION = 'REMOVE_SESSION',
  EDIT_SESSION = 'EDIT_SESSION',
  SET_SELECTED = 'SET_SELECTED',
  SET_IS_LOADING = 'SET_IS_LOADING',
}
