import React, { CSSProperties, ReactElement, useEffect } from 'react';
import LineDivider from '../../layouts/LineDivider';
import Row from '../../layouts/Row';
import AccordionButton, { TAccordionButtonProps } from './AccordionButton';
import useSimpleToggle from '../../hooks/useSimpleToggle';
import Content, { TContentProps } from './Content';
import { TAccordionContext, TIsOnFunction, useAccordion, AccordionContext } from './useAccordion';

type TAccordionWrapperProps = {
  children: ReactElement | ReactElement[];
  oneAtATime?: boolean;
};

export function AccordionWrapper({ children, oneAtATime }: TAccordionWrapperProps): ReactElement {
  const { on, toggle, index, indices } = useSimpleToggle({ initial: false });

  children = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      //really this is the only way I could sole it unless someone knows how to fix it for TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return React.cloneElement(child, { index: i });
    }
  });

  const isOn: TIsOnFunction = (childIndex = -1) => {
    if (oneAtATime) {
      if (index !== childIndex) {
        return false;
      }
      return on;
    }
    return childIndex > -1 ? !!indices?.includes(childIndex) : false;
  };

  const value: TAccordionContext = [isOn, toggle, on, index];
  return <AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>;
}

export type TAccordionProps = Partial<{
  content: TContentProps['content'];
  children: TContentProps['content'];
}> & {
  title: string | ReactElement;
  noTopLine?: boolean;
  noBottomLine?: boolean;
  columns?: number;
  columnGap?: string;
  initialToggle?: boolean;
  showFirst?: number;
  topLineStyle?: CSSProperties;
  bottomLineStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  titleClass?: string;
  hideAccordionButton?: boolean;
  customAccordionButton?: ({
    on,
    toggle,
  }: {
    on: boolean;
    toggle: (index?: number | undefined) => void;
  }) => ReactElement;
  btnPositionLeft?: boolean;
  btnDirection?: TAccordionButtonProps['direction'];
  btnPadding?: CSSProperties['padding'];
  index?: number;
  icon?: ReactElement;
  borderColor?: CSSProperties['borderColor'];
  disabled?: boolean;
  isHidden?: boolean;
  [key: string]: any;
};

const Accordion = ({
  title,
  content,
  children,
  noTopLine = true,
  noBottomLine,
  columns = 1,
  columnGap,
  initialToggle,
  showFirst,
  topLineStyle,
  bottomLineStyle,
  contentStyle,
  titleStyle = {},
  titleClass,
  hideAccordionButton,
  customAccordionButton,
  btnPositionLeft,
  btnDirection = 'down',
  btnPadding,
  index,
  icon,
  borderColor,
  disabled,
  isHidden = false,
  ...rest
}: TAccordionProps): ReactElement => {
  const [isOn, toggle, on = false] = useAccordion();

  useEffect(() => {
    if (initialToggle) {
      toggle(index);
    }
    return () => {
      if (initialToggle) toggle(index);
    };
  }, []);
  const { gridArea: tittleGridArea, ...restTitleStyles } = titleStyle;

  return (
    <>
      {!noTopLine ? <LineDivider style={topLineStyle} /> : null}
      <Row
        {...rest}
        columns={1}
        justifyContent={'start'}
        columnWidth={'1fr min-content'}
        className={`cr-rounded cr-border-2 cr-border-solid cr-border-transparent  hover:cr-rounded hover:cr-border-2 hover:cr-border-solid hover:cr-border-blue-500 hover:cr-text-blue-500 ${isOn(index) ? titleClass : ''}`}
        style={{
          cursor: 'pointer',
          overflow: 'visible',
          textAlign: 'start',
          color: disabled ? '#a3a3a3' : 'inherit',
          gridArea: tittleGridArea,
          height: 'auto',
          ...restTitleStyles,
          ':hover': {
            borderRadius: !isOn(index) ? '4px' : undefined,
            zIndex: 2,
          },
        }}
      >
        <span
          style={{ width: '100%', ...restTitleStyles }}
          onClick={(): void => (!disabled ? toggle(index) : undefined)}
        >
          {title}
        </span>
        {!hideAccordionButton && !customAccordionButton ? (
          <AccordionButton
            on={isOn(index)}
            order={btnPositionLeft ? -1 : 1}
            direction={btnDirection}
            padding={btnPadding}
            icon={icon}
            onClick={() => toggle(index)}
          />
        ) : null}
        {customAccordionButton?.({ on, toggle })}
      </Row>
      {isOn(index) || isHidden ? (
        <Content
          {...{
            columns,
            columnGap,
            content: content ?? children,
            showFirst,
            contentStyle: {
              ...contentStyle,
              border: isOn(index) ? `solid thin ${borderColor}` : undefined,
              visibility: isHidden ? 'hidden' : 'visible',
            },
            onToggleOpen: (): void => toggle(index),
          }}
        />
      ) : null}

      {!noBottomLine ? <LineDivider style={bottomLineStyle} /> : null}
    </>
  );
};

export default Accordion;
