import { TRun } from '../common/types/run';
import { KEEP_UNCHANGED } from '../const';
import { RunStatus } from '../common/types/enums';
import { CSSProperties } from 'react';

type TSelectionOption = {
  label: string;
  value: string;
  variables: string[];
};
export const getSelectOptionsForRun = (
  run: Record<string | keyof TRun, any>,
  varName: keyof TRun,
): Omit<TSelectionOption, 'variables'>[] => {
  const selectOptions: TSelectionOption[] = [
    {
      label: 'From Run',
      value: 'device',
      variables: ['gates_id', 'cp_corr', 'xw_slope', 'yaw_offset', 'wheel_circumference'],
    },
    {
      label: 'Manual',
      value: 'manual',
      variables: [
        'wheel_circumference',
        'xw_wheel_compensation',
        'cda_ref',
        'cp_corr',
        'combined_mass',
        'crr',
        'power_meter_slope',
        'xw_slope',
        'yaw_offset',
        'airspeed_ref',
        'airspeed_slope',
        'head_ref',
        'chest_ref',
        'head_slope',
        'chest_slope',
      ],
    },
    {
      label: 'Averaged',
      value: 'auto',
      variables: [
        'wheel_circumference',
        'cp_corr',
        'xw_slope',
        'yaw_offset',
        'gates_id',
        'cda_ref',
        'airspeed_ref',
        'airspeed_slope',
        'head_ref',
        'chest_ref',
        'head_slope',
        'chest_slope',
      ],
    },
    {
      label: 'Calibration',
      value: 'calibration',
      variables: [
        'wheel_circumference',
        'cp_corr',
        'combined_mass',
        'crr',
        'power_meter_slope',
        'xw_slope',
        'yaw_offset',
        'gates_id',
      ],
    },
  ];

  return [
    ...(run[`${varName}`] === KEEP_UNCHANGED ? [{ label: KEEP_UNCHANGED, value: KEEP_UNCHANGED }] : []),
    ...selectOptions.reduce<Omit<TSelectionOption, 'variables'>[]>((acc, { label, value, variables }) => {
      if (variables.includes(varName)) acc.push({ label, value });
      return acc;
    }, []),
  ];
};

export const getRunStatus = (
  item: TRun,
):
  | {
      color: CSSProperties['color'];
      value: RunStatus | 0;
      title: string;
    }
  | undefined =>
  [
    { color: '#2ac42a', value: RunStatus.PROCESSED_SUCCESS, title: 'GOOD' },
    {
      color: 'orange',
      value: RunStatus.PROCESSED_ERROR + 1,
      title: 'DATA WARNING',
    },
    { color: 'red', value: RunStatus.PROCESSED_ERROR, title: 'MISSING DATA' },
    { color: 'black', value: RunStatus.CREATED, title: 'AWAITING DATA' },
    { color: 'pink', value: 0, title: 'NO DATA' },
  ].find((st) => st.value <= (item.run_status ?? 0));
