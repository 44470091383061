function toggleList<T>(list: (T | null)[] | Set<T | null>, value: T | null): Set<T | null> {
  const newSet = new Set(list);
  if (newSet.has(value)) {
    newSet.delete(value);
  } else {
    newSet.add(value);
  }
  return newSet;
}

export { toggleList };
