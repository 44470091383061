import { PropsWithChildren, useRef } from 'react';
import RunStatusModal from './RunStatusModal';
import useSimpleModal from '../SimpleModal/useSimpleModal';
import { TRun } from '../../common/types/run';
import { RunStatus } from '../../common/types/enums';
import { getRunStatus } from '../../utils/dataUtils';
import { getData } from '../../stores/ajax/getData';

type RunStatusProps = {
  item: TRun;
};
export default function RunStatusColors(props: PropsWithChildren<RunStatusProps>): any {
  const status = getRunStatus(props.item);

  const statusRef = useRef<HTMLDivElement>(null);

  const { setContent } = useSimpleModal();

  return status ? (
    <div
      ref={statusRef}
      onClick={async (event): Promise<void> => {
        if (statusRef?.current && (props.item.run_status ?? RunStatus.CREATED) >= 3000) {
          event.stopPropagation();
          const data: any = await getData({
            endpoint: `/runs/${props.item.id}`,
            scope: 'run_health',
          });
          if (data.run_health_obj && !data.errors) {
            setContent({
              component: <RunStatusModal {...{ runHealth: data.run_health_obj }} />,
            });
          }
        }
      }}
      style={{ cursor: status.value > RunStatus.CREATED ? 'pointer' : 'unset', color: status.color }}
    >
      <title>{status.title}</title>
      {props.children}
    </div>
  ) : null;
}
