import { IconButton, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { DoNotTouch, Lock } from '@mui/icons-material';
import { updateGroup } from '../../../../stores/ajax/updateGroup';
import { updateCalibration } from '../../../../stores/ajax/updateCalibration';
import { TConfig } from '../../../../common/types/config';
import { MoreButtonProps } from '../../../../components/Buttons/MoreButton';
import { useConfigs } from '../../../../stores/configs/useConfigs';
import { useRuns } from '../../../../stores/runs/useRuns';
import { useCalibrations } from '../../../../stores/calibrations/useCalibrations';
import ConfirmButton from '../../../../components/Buttons/ConfirmButton';
import { ChangeEvent, useState } from 'react';
import { setDataLoading } from '../../../../stores/configs/setters';

const defaultState = {
  wheel_circumference: false,
  yaw_offset: false,
  cp_corr: false,
  manual_change: false,
};
const calibrationParamChanges = (config: TConfig): MoreButtonProps['MenuItems'] => {
  const [checked, setChecked] = useState<{
    [key: string]: boolean;
  }>({ ...defaultState, ...(config.autocal_branch ?? {}) });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const [, configsDispatch, refreshConfigs] = useConfigs();
  const [, , refreshRuns] = useRuns();
  const [, , refreshCalibrations] = useCalibrations();
  return [
    {
      element: <Typography color={'success'}>Param changed</Typography>,
      color: 'success',
      subMenu: [
        {
          element: (
            <ConfirmButton
              label={'Submit Selection'}
              onClick={async () => {
                if (config) {
                  setDataLoading(config.id, configsDispatch);
                  await updateGroup({
                    groupId: config.id as string,
                    ...config,
                    // now this is an integer but we send bitwise string
                    autocal_branch: checked,
                    verb: 'PUT',
                  });
                  await updateCalibration({
                    verb: 'POST',
                    autocalibrate: true,
                    testsession_id: config.testsession_id,
                  });
                  setDataLoading(config.id, configsDispatch);
                  refreshConfigs();
                  refreshRuns();
                  refreshCalibrations();
                }
              }}
            />
          ),
        },
        ...Object.keys(checked).map((key) => {
          return {
            element: (
              <FormControlLabel
                control={<Checkbox name={key} checked={checked[key] ?? false} onChange={handleChange} />}
                label={key.replace('_', ' ')}
              />
            ),
          };
        }),
      ],
    },
  ];
};

const subMenu = (config: TConfig): MoreButtonProps['MenuItems'] => {
  const [, configsDispatch, refreshConfigs] = useConfigs();
  const [, , refreshRuns] = useRuns();
  const [, , refreshCalibrations] = useCalibrations();
  return [
    ...calibrationParamChanges(config),
    {
      element: (
        <>
          <IconButton color={'success'}>
            <DoNotTouch />
          </IconButton>
          Do Not Use
        </>
      ),
      color: 'success',
      onClick: async () => {
        if (config) {
          setDataLoading(config.id, configsDispatch);
          await updateGroup({
            groupId: config.id as string,
            ...config,
            autocal_skip: !config.autocal_skip,
            verb: 'PUT',
          });
          refreshConfigs();
          await updateCalibration({
            verb: 'POST',
            autocalibrate: true,
            testsession_id: config.testsession_id,
          });
          setDataLoading(config.id, configsDispatch);
          refreshRuns();
          refreshCalibrations();
        }
      },
    },
    {
      element: (
        <>
          <IconButton color={'success'}>
            <Lock />
          </IconButton>
          Lock
        </>
      ),
      onClick: async () => {
        if (config) {
          setDataLoading(config.id, configsDispatch);
          await updateGroup({
            groupId: config.id as string,
            ...config,
            autocal_lock: !config.autocal_lock,
            verb: 'PUT',
          });
          setDataLoading(config.id, configsDispatch);
          refreshConfigs();
        }
      },
      color: 'success',
    },
  ];
};
export { subMenu };
