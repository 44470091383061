import { ReactElement } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Pin } from '@mui/icons-material';

export default function FixRunOrder(props: any): ReactElement {
  return (
    <>
      <IconButton color={'success'} {...props}>
        <Pin />
      </IconButton>
      <Typography color={'success'}>Sort Run Numbers</Typography>
    </>
  );
}
