import { Fragment, ReactElement, useState } from 'react';
import Column from '../../layouts/Column';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { RunHealthObject } from '../../common/types/healthObject';
import { getKeys } from '../../utils/getKeys';
import Row from '../../layouts/Row';

type RunStatusModalProps = {
  runHealth: RunHealthObject;
};

function isNumber(x: any): number | null {
  return typeof x === 'number' ? x : null;
}

export default function RunStatusModal({ runHealth }: RunStatusModalProps): ReactElement {
  const healthObjectKeys = getKeys(runHealth.tolerance_meta).filter((key) => !key.includes('_'));
  const [tabValue, setTabValue] = useState(healthObjectKeys[0]);
  return (
    <Column rows={2}>
      <Tabs
        value={tabValue}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(event, newValue): void => setTabValue(newValue)}
        variant={'scrollable'}
        aria-label=" health navigation tabs for sensors"
      >
        {healthObjectKeys.reduce<ReactElement[]>((acc, key, index) => {
          if (!key.includes('_')) {
            acc.push(<Tab key={key + '-' + index} label={<span className={'label'}>{key}</span>} value={key} />);
          }
          return acc;
        }, [])}
      </Tabs>
      {tabValue && (
        <Row columns={2} justifyContent={'space-evenly'} justifyItems={'start'}>
          {getKeys(runHealth.tolerance_meta[tabValue] || {}).map((key) => {
            const value = runHealth.tolerance_meta[tabValue][key];
            // typescript needed type wrapper vor number|string|boolean
            const n = isNumber(value);
            return (
              <Fragment key={'data' + key}>
                <p
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  {key.replaceAll('_', ' ')}
                </p>
                <p
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  {n ? n.toFixed(2) : value}
                </p>
              </Fragment>
            );
          })}
        </Row>
      )}
    </Column>
  );
}
