import Column from '../../layouts/Column';
import useSimpleToggle from '../../hooks/useSimpleToggle';
import ReadMore from '../ReadMore';
import { findClosestNbspIndex } from '../../utils/findNbspInText';
import { ReactElement, CSSProperties, cloneElement } from 'react';

const getNbspIndex = (Text: string | ReactElement, index: number): string | ReactElement => {
  if ((typeof Text === 'string' && index > Text.length - 1) || !Text) {
    return Text;
  }

  if (typeof Text !== 'string') {
    return cloneElement(Text, { length: index });
  }

  if (Array.isArray(Text)) {
    return Text.splice(0, index) + '...';
  }

  return Text.substring(0, findClosestNbspIndex(Text, index) + 1) + '...';
};

export type TContentProps = {
  columns?: number;
  columnGap?: string;
  showFirst?: number;
  content?:
    | string
    | ReactElement
    | (({ onToggleOpen }: { onToggleOpen: TContentProps['onToggleOpen'] }) => ReactElement | undefined);
  contentStyle?: CSSProperties;
  onToggleOpen: (...rest: any) => void;
};
const Content = ({
  columns = 1,
  columnGap = '0',
  showFirst,
  content,
  contentStyle,
  onToggleOpen,
}: TContentProps): ReactElement => {
  const style: CSSProperties = {
    justifyContent: 'flex-start',
    justifyItems: 'flex-start',
    textAlign: 'start',
    borderRadius: 'inherit',
    columnGap,
    ...contentStyle,
  };

  const { on, toggle } = useSimpleToggle();

  const displayContent = typeof content === 'function' ? content({ onToggleOpen }) : content;

  return (
    <>
      {displayContent ? (
        <Column {...{ columns, columnGap, style }}>
          {!showFirst || on ? displayContent : getNbspIndex(displayContent, showFirst)}
          {showFirst ? <ReadMore {...{ on, toggle }} /> : null}
        </Column>
      ) : null}
    </>
  );
};

export default Content;
