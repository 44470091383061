import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';

/* eslint-disable max-len */
export const StreamlinesCaliper = ({ stroke, size }: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        fill="#FFFFFF"
        stroke={stroke ?? '#020202'}
        strokeWidth="0.3"
        d="M21.414,17.724
	c0.283,0.181,0.408,0.079,0.545-0.167c0.461-0.83,0.959-1.644,1.395-2.487c0.181-0.354,0.379-0.422,0.748-0.341
	c1.951,0.427,3.918,0.825,5.873,1.249c0.396,0.084,0.689,0.06,0.864-0.375c0.164-0.405,0.571-0.83,0.502-1.179
	c-0.058-0.296-0.614-0.523-0.979-0.733c-2.09-1.203-4.19-2.39-6.391-3.645c0.493-0.874,0.994-1.77,1.518-2.703
	c1.479,0.834,2.912,1.646,4.345,2.45c0.968,0.543,1.949,1.061,2.897,1.629c0.291,0.176,0.396,0.028,0.545-0.128
	c0.045-0.047,0.043-0.132,0.076-0.192c0.655-1.231,0.645-1.221-0.272-2.228c-1.197-1.311-2.337-2.675-3.576-3.947
	c-0.437-0.449-1.082-0.697-1.644-1.021c-1.467-0.843-2.937-1.683-4.408-2.512c-0.297-0.166-0.585-0.281-0.789,0.191
	c-0.186,0.432-0.471,0.82-0.749,1.287c-0.675-0.382-1.337-0.751-1.992-1.126c-0.899-0.512-2.239-0.245-2.752,0.565
	c-0.172,0.269-0.234,0.473,0.165,0.686c1.155,0.608,2.273,1.282,3.434,1.944c-0.438,0.762-0.902,1.495-1.281,2.27
	c-0.184,0.367-0.342,0.288-0.592,0.145c-1.004-0.571-2.018-1.13-3.021-1.707c-0.717-0.413-0.888-0.344-1.076,0.476
	c-0.215,0.912,0.282,2.045,1.1,2.518c0.162,0.095,0.318,0.202,0.5,0.315c-0.724,1.287-1.429,2.562-2.153,3.827
	c-0.38,0.656-0.383,0.698,0.247,1.056c0.304,0.173,0.619,0.335,0.949,0.517c-0.764,1.356-1.497,2.654-2.229,3.954
	c-1.402,2.488-2.801,4.979-4.208,7.465c-1.047,1.854-2.096,3.705-3.162,5.548c-0.524,0.905-0.544,0.901,0.366,1.428
	c1.149,0.662,2.31,1.312,3.455,1.979c0.318,0.186,0.462,0.033,0.608-0.226c1.097-1.944,2.194-3.885,3.297-5.83
	c2.029-3.571,4.059-7.143,6.084-10.716c0.177-0.307,0.339-0.621,0.5-0.921C20.611,17.289,21.027,17.483,21.414,17.724z"
      />
      <path
        fill="#FFFFFF"
        stroke={stroke ?? '#020202'}
        strokeWidth="0.5"
        d="M19.643,17.959
	c-2.025,3.574-4.055,7.145-6.083,10.718c-1.103,1.943-2.201,3.886-3.299,5.828c-0.143,0.263-0.289,0.412-0.606,0.226
	c-1.146-0.666-2.307-1.315-3.457-1.979c-0.91-0.524-0.889-0.521-0.364-1.426c1.065-1.843,2.114-3.694,3.161-5.548
	c1.407-2.487,2.806-4.978,4.208-7.466c0.731-1.3,1.466-2.597,2.229-3.955c-0.333-0.182-0.645-0.344-0.949-0.518
	c-0.63-0.358-0.624-0.397-0.248-1.055c0.725-1.264,1.43-2.538,2.155-3.827c-0.181-0.114-0.341-0.22-0.503-0.315
	c-0.816-0.473-1.31-1.606-1.098-2.518c0.188-0.824,0.36-0.888,1.077-0.476c1.003,0.577,2.015,1.135,3.02,1.708
	c0.25,0.141,0.41,0.22,0.592-0.145c0.381-0.774,0.846-1.506,1.28-2.27c-1.155-0.661-2.275-1.335-3.435-1.943
	c-0.399-0.213-0.337-0.417-0.164-0.686c0.51-0.811,1.854-1.079,2.751-0.565c0.659,0.375,1.317,0.743,1.991,1.124
	c0.281-0.465,0.564-0.855,0.752-1.286c0.203-0.471,0.494-0.357,0.789-0.19c1.472,0.828,2.945,1.668,4.406,2.511
	c0.562,0.323,1.207,0.571,1.646,1.02c1.237,1.271,2.378,2.635,3.575,3.948c0.916,1.006,0.929,0.995,0.271,2.227
	c-0.033,0.06-0.031,0.144-0.076,0.192c-0.147,0.158-0.257,0.302-0.543,0.128c-0.947-0.57-1.933-1.086-2.898-1.629
	c-1.432-0.806-2.866-1.617-4.344-2.452c-0.523,0.933-1.023,1.829-1.518,2.704c2.198,1.254,4.301,2.442,6.391,3.646
	c0.365,0.209,0.924,0.435,0.979,0.732c0.063,0.347-0.341,0.772-0.503,1.179c-0.176,0.433-0.465,0.458-0.863,0.374
	c-1.957-0.423-3.918-0.82-5.874-1.247c-0.373-0.081-0.569-0.014-0.75,0.342c-0.437,0.843-0.934,1.655-1.394,2.485
	c-0.139,0.247-0.262,0.346-0.547,0.167c-0.383-0.243-0.801-0.437-1.268-0.685C19.979,17.338,19.816,17.652,19.643,17.959z
	 M19.758,7.806c0.1,0.075,0.201,0.164,0.314,0.228c0.408,0.238,0.464,0.554,0.24,0.954c-1.064,1.873-2.109,3.755-3.188,5.625
	c-0.291,0.507-0.172,0.622-1.226,0.012c-3.278,5.796-6.559,11.59-9.861,17.427c0.615,0.362,1.221,0.729,1.832,1.077
	c0.462,0.264,0.958,0.465,1.386,0.771c0.439,0.318,0.695,0.259,0.897-0.221c0.063-0.154,0.135-0.309,0.221-0.508
	c-0.243-0.142-0.527-0.273-0.763-0.462c-0.109-0.088-0.127-0.296-0.184-0.446c0.154-0.004,0.331-0.059,0.462-0.004
	c0.249,0.102,0.471,0.264,0.77,0.438c0.301-0.523,0.599-1.046,0.935-1.63c-0.456-0.262-0.885-0.492-1.294-0.756
	c-0.117-0.074-0.168-0.248-0.249-0.373c0.164-0.032,0.362-0.141,0.488-0.08c0.431,0.195,0.835,0.447,1.31,0.709
	c0.299-0.504,0.594-1.01,0.879-1.491c-0.111-0.517-1.104-0.298-0.903-1.187c0.439,0.219,0.809,0.402,1.215,0.605
	c0.301-0.523,0.601-1.055,0.915-1.605c-0.442-0.261-0.893-0.502-1.316-0.777c-0.103-0.066-0.132-0.256-0.196-0.389
	c0.145-0.022,0.322-0.108,0.431-0.061c0.44,0.209,0.861,0.461,1.342,0.723c0.301-0.503,0.594-1.011,0.934-1.585
	c-0.275-0.159-0.565-0.302-0.82-0.489c-0.1-0.078-0.126-0.263-0.184-0.396c0.141-0.023,0.301-0.105,0.418-0.062
	c0.266,0.103,0.512,0.267,0.843,0.448c0.3-0.516,0.597-1.034,0.919-1.593c-0.426-0.251-0.852-0.469-1.244-0.742
	c-0.129-0.085-0.169-0.296-0.25-0.447c0.159-0.004,0.342-0.067,0.472-0.006c0.422,0.204,0.823,0.453,1.273,0.707
	c0.311-0.535,0.62-1.06,0.945-1.621c-0.351-0.217-0.697-0.426-1.04-0.639c0.13-0.533,0.13-0.533,1.299,0.13
	c0.294-0.502,0.585-1.013,0.913-1.573c-0.417-0.24-0.833-0.458-1.226-0.718c-0.136-0.092-0.194-0.292-0.29-0.443
	c0.177-0.007,0.378-0.075,0.518-0.011c0.413,0.192,0.802,0.437,1.238,0.682c0.244-0.405,0.482-0.808,0.735-1.228
	c-0.151-0.104-0.286-0.209-0.433-0.294c-0.703-0.403-0.713-0.405-0.322-1.103c0.953-1.689,1.908-3.375,2.873-5.058
	c0.468-0.825,0.682-0.9,1.707-0.627c0.488-0.847,1.023-1.674,1.445-2.557c0.246-0.511,0.526-0.462,0.886-0.26
	c0.991,0.556,1.975,1.135,2.964,1.696c1.227,0.696,2.462,1.387,3.701,2.062c0.139,0.074,0.484,0.06,0.49,0.038
	c0.054-0.29,0.528-0.494,0.183-0.869c-0.622-0.674-1.229-1.358-1.843-2.043c-0.715-0.804-1.387-1.65-2.148-2.405
	c-0.4-0.394-0.943-0.655-1.443-0.939c-1.431-0.819-2.873-1.619-4.318-2.423c-0.087-0.05-0.196-0.061-0.322-0.1
	c-0.265,0.452-0.566,0.883-0.781,1.352c-0.16,0.339-0.367,0.263-0.576,0.151c-0.64-0.351-1.275-0.705-1.895-1.097
	c-0.74-0.469-1.416-0.359-2.203,0.196c1.301,0.745,2.536,1.456,3.838,2.203C20.895,5.771,20.334,6.774,19.758,7.806z M24.592,11.027
	c-0.473-0.266-0.938-0.551-1.41-0.814c-0.416-0.229-0.646-0.104-0.88,0.326c-0.95,1.744-1.952,3.455-2.936,5.18
	c-0.03,0.057-0.026,0.132-0.048,0.234c0.662,0.363,1.31,0.737,1.978,1.072c0.097,0.05,0.347-0.02,0.401-0.108
	c0.5-0.85,0.964-1.723,1.471-2.57c0.07-0.124,0.336-0.221,0.489-0.196c0.905,0.144,1.806,0.324,2.706,0.505
	c1.226,0.251,2.443,0.524,3.67,0.765c0.113,0.022,0.306-0.059,0.377-0.155c0.154-0.22,0.258-0.478,0.386-0.739
	C28.695,13.346,26.645,12.188,24.592,11.027z M15.421,6.021c-0.355,0.874,0.088,1.831,1.109,2.386
	c0.563,0.303,0.573,0.36,0.273,0.894c-0.683,1.212-1.35,2.432-2.041,3.643c-0.139,0.243-0.162,0.363,0.13,0.508
	c0.562,0.281,1.101,0.614,1.721,0.966c1.098-1.934,2.187-3.851,3.311-5.837C18.417,7.725,16.92,6.874,15.421,6.021z"
      />
      <path
        fill="#FFFFFF"
        stroke={stroke ?? '#020202'}
        strokeWidth="0.5"
        d="M21.477,4.724
	c-0.58,1.047-1.145,2.051-1.717,3.081c0.102,0.075,0.201,0.164,0.312,0.227c0.41,0.239,0.466,0.555,0.24,0.955
	c-1.062,1.873-2.108,3.755-3.186,5.625c-0.291,0.507-0.172,0.622-1.224,0.011c-3.28,5.797-6.561,11.591-9.863,17.428
	c0.614,0.362,1.221,0.727,1.833,1.077c0.461,0.265,0.958,0.466,1.386,0.772c0.438,0.317,0.695,0.258,0.896-0.221
	c0.063-0.154,0.135-0.31,0.221-0.509c-0.243-0.142-0.527-0.272-0.763-0.462c-0.109-0.09-0.127-0.295-0.184-0.445
	c0.154-0.006,0.331-0.059,0.462-0.005c0.249,0.101,0.472,0.265,0.77,0.438c0.301-0.523,0.599-1.045,0.935-1.629
	c-0.457-0.262-0.885-0.493-1.294-0.758c-0.117-0.072-0.168-0.246-0.249-0.373c0.164-0.031,0.362-0.14,0.488-0.078
	c0.431,0.193,0.835,0.445,1.311,0.707c0.297-0.502,0.593-1.01,0.878-1.49c-0.11-0.516-1.105-0.299-0.903-1.188
	c0.439,0.219,0.808,0.403,1.215,0.606c0.301-0.522,0.601-1.056,0.915-1.606c-0.443-0.26-0.893-0.5-1.316-0.779
	c-0.103-0.063-0.132-0.254-0.196-0.385c0.145-0.022,0.322-0.109,0.431-0.062c0.44,0.209,0.86,0.461,1.342,0.723
	c0.301-0.502,0.594-1.011,0.934-1.585c-0.275-0.16-0.565-0.302-0.82-0.488c-0.1-0.078-0.126-0.264-0.184-0.397
	c0.141-0.022,0.301-0.104,0.418-0.062c0.266,0.102,0.512,0.266,0.841,0.447c0.301-0.518,0.599-1.033,0.921-1.593
	c-0.428-0.253-0.852-0.472-1.245-0.742c-0.128-0.085-0.168-0.296-0.25-0.448c0.159-0.006,0.344-0.066,0.472-0.006
	c0.422,0.206,0.823,0.454,1.275,0.706c0.309-0.534,0.618-1.058,0.943-1.618c-0.349-0.218-0.697-0.427-1.04-0.64
	c0.132-0.533,0.132-0.533,1.301,0.129c0.292-0.503,0.583-1.012,0.911-1.573c-0.417-0.239-0.833-0.458-1.226-0.717
	c-0.138-0.092-0.194-0.292-0.292-0.442c0.179-0.008,0.38-0.076,0.518-0.011c0.415,0.192,0.802,0.437,1.239,0.682
	c0.243-0.405,0.482-0.808,0.734-1.228c-0.152-0.104-0.286-0.209-0.432-0.293c-0.704-0.403-0.713-0.405-0.321-1.104
	c0.951-1.689,1.908-3.375,2.872-5.06c0.469-0.823,0.682-0.898,1.708-0.625c0.489-0.847,1.022-1.674,1.444-2.557
	c0.248-0.511,0.526-0.462,0.888-0.26c0.989,0.556,1.973,1.136,2.961,1.695c1.228,0.697,2.464,1.386,3.7,2.064
	c0.141,0.073,0.486,0.059,0.492,0.038c0.054-0.291,0.528-0.496,0.182-0.869c-0.622-0.674-1.229-1.358-1.842-2.043
	c-0.717-0.804-1.387-1.649-2.148-2.405c-0.4-0.394-0.943-0.655-1.443-0.94C26.3,3.62,24.857,2.819,23.411,2.016
	c-0.087-0.051-0.194-0.061-0.322-0.101c-0.265,0.452-0.565,0.883-0.782,1.352c-0.16,0.339-0.367,0.263-0.574,0.151
	c-0.641-0.351-1.277-0.704-1.896-1.097c-0.741-0.47-1.416-0.359-2.202,0.196C18.938,3.268,20.174,3.976,21.477,4.724z"
      />
      <path
        fill="#FFFFFF"
        stroke={stroke ?? '#020202'}
        strokeWidth="0.3"
        d="M30.794,14.53
	c-2.099-1.186-4.151-2.342-6.204-3.503c-0.473-0.266-0.938-0.551-1.41-0.814c-0.416-0.229-0.646-0.104-0.88,0.326
	c-0.95,1.744-1.952,3.455-2.937,5.18c-0.029,0.057-0.025,0.132-0.047,0.236c0.662,0.361,1.308,0.735,1.978,1.07
	c0.097,0.05,0.347-0.02,0.401-0.108c0.5-0.85,0.964-1.723,1.471-2.57c0.07-0.124,0.336-0.221,0.488-0.196
	c0.904,0.144,1.808,0.324,2.707,0.505c1.226,0.251,2.443,0.524,3.67,0.765c0.113,0.022,0.306-0.059,0.378-0.155
	C30.561,15.048,30.662,14.788,30.794,14.53z"
      />
      <path
        fill="#FFFFFF"
        stroke={stroke ?? '#020202'}
        strokeWidth="0.3"
        d="M19.924,8.582
	c-1.507-0.859-3.003-1.708-4.502-2.561c-0.355,0.874,0.088,1.831,1.109,2.386c0.563,0.303,0.573,0.36,0.273,0.894
	c-0.683,1.212-1.35,2.432-2.041,3.643c-0.139,0.243-0.162,0.363,0.13,0.508c0.562,0.281,1.101,0.614,1.721,0.966
	C17.711,12.485,18.799,10.566,19.924,8.582z"
      />
    </Svg>
  );
};
/* eslint-enable max-len */
