export const findClosestNbspIndex = (text: string, index: number): number => {
  if (!/\s/.test(text.charAt(index))) {
    let less = index - 1;
    let more = index + 1;

    while (less >= 0 && more <= text.length) {
      //in case distances to the end of the word are equal
      // then returns less over more
      if (/\s/.test(text.charAt(less))) {
        index = less;
        break;
      }
      if (/\s/.test(text.charAt(more))) {
        index = more;
        break;
      }
      less--;
      more++;
    }
  }
  return index;
};
