/** @jsxImportSource @emotion/react */
import { Check } from '../../icons/Check';
import { ReactElement } from 'react';
import { TButtonProps } from '../../common/types/buttonProps';
import BaseButton from './Button';

export default function ConfirmButton({
  onClick = (): void => undefined,
  disabled = false,
  label = 'Apply',
  icon,
  type = 'submit',
  fullWidth,
  style,
  className,
  ...rest
}: TButtonProps): ReactElement {
  return (
    <BaseButton
      icon={icon ?? <Check />}
      color={'success'}
      {...{
        fullWidth,
        onClick,
        disabled,
        type,
        style,
        className,
        ...rest,
      }}
    >
      {label}
    </BaseButton>
  );
}
