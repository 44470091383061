import { ReactElement, useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router } from 'react-router-dom';
import awsconfig from './amplifyconfiguration';
import RunsProvider from './stores/runs/provider';
import Grid from './layouts/Grid';
import Nav from './components/Nav';
import PageProvider from './pages';
import Header from './components/Header';
import AppFooterProvider from './components/AppFooter/provider';
import AppFooter from './components/AppFooter';
import RidersProvider from './stores/riders/provider';
import TestSessionsProvider from './stores/sessions/provider';
import UserProvider from './stores/user/provider';
import ConfigsProvider from './stores/configs/provider';
import CalibrationProvider from './stores/calibrations/provider';
import useCirrusLocation, { regexpSettings } from './hooks/useCirrusLocation';
import AppModalProvider from './components/SimpleModal/provider';
import { LocalStorageHandler } from './utils/toLocalStorage';
import { components } from './amplifyComponents';
import { formFields } from './amplifyFields';
import NavProvider from './components/Nav/provider';

Amplify.configure(awsconfig);

const storeToken = (user: any): void => {
  const accessToken = user?.signInUserSession?.accessToken;

  if (accessToken) {
    const { jwtToken } = accessToken;
    LocalStorageHandler.setUser(user.username);
    LocalStorageHandler.save('token', jwtToken);
  }
};

const App = (): ReactElement => {
  const [moveNav, setMoveNav] = useState(false);

  const { settings } = useCirrusLocation(regexpSettings, true);

  useEffect(() => {
    setMoveNav(settings);
  }, [settings]);

  return (
    <Authenticator components={components} formFields={formFields} hideSignUp>
      {({ signOut, user }): ReactElement => {
        storeToken(user);
        return user ? (
          <UserProvider signOut={signOut ?? ((): void => undefined)}>
            <RidersProvider>
              <TestSessionsProvider>
                <RunsProvider>
                  <ConfigsProvider>
                    <CalibrationProvider>
                      <Grid
                        columns={1}
                        rows={1}
                        justifyItems={'start'}
                        rowHeight={moveNav ? 'min-content 1fr min-content' : 'min-content min-content 1fr min-content'}
                        style={{ minHeight: '100vh', maxHeight: '100vh', position: 'relative', overflow: 'auto' }}
                      >
                        <Router>
                          <NavProvider>
                            <Header
                              signOut={signOut}
                              nav={moveNav ? <Nav padding={'10px 10px 0px 10px'} noLabels={true} /> : undefined}
                            />
                            {!moveNav && <Nav />}
                            <AppModalProvider>
                              <AppFooterProvider>
                                <PageProvider />
                                <AppFooter />
                              </AppFooterProvider>
                            </AppModalProvider>
                          </NavProvider>
                        </Router>
                      </Grid>
                    </CalibrationProvider>
                  </ConfigsProvider>
                </RunsProvider>
              </TestSessionsProvider>
            </RidersProvider>
          </UserProvider>
        ) : (
          <div>No User</div>
        );
      }}
    </Authenticator>
  );
};

export default App;
