import { AxiosResponse } from 'axios';
import axios from './axios';

export interface TRequestProps extends Record<string, any> {
  endpoint: string;
  verb: 'GET' | 'POST' | 'PUT' | 'DELETE';
  abortSignal?: AbortSignal;
  payload?: any[] | Record<string, any>;
  requestParams?: Record<string, any>;
}

const queryStr = (requestParams: any): string => {
  if (!requestParams) {
    return '';
  }
  return (
    '?' +
    Object.keys(requestParams)
      .reduce<string[]>((acc, key) => [...acc, key + '=' + requestParams[key]], [])
      .join('&')
  );
};

export const requestWithAxios = ({
  endpoint,
  verb = 'GET',
  abortSignal,
  payload,
  ...requestParams
}: TRequestProps): Promise<AxiosResponse> | undefined => {
  const axiosOptions = { signal: abortSignal };
  switch (verb) {
    case 'GET':
      return axios.get(endpoint + queryStr(requestParams), axiosOptions);
    case 'POST':
      return axios.post(endpoint, payload || requestParams, axiosOptions);
    case 'PUT':
      return axios.put(endpoint, payload || requestParams, axiosOptions);
    case 'DELETE':
      return axios.delete(endpoint);
  }
};

export class RequestError extends Error {
  constructor({ endpoint, verb, requestParams }: TRequestProps) {
    super(
      `Request generated an error! Please, contact administrator with: ${endpoint} \n ${verb} \n ${requestParams} `,
    );
  }
}

export function showRequestError(e: Error, options: TRequestProps) {
  const message = `URL: ${options.endpoint}! ${e}`;
  console.error(message);
  console.warn(options);
  return { errors: message };
}
