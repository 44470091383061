import { requestWithAxios, TRequestProps } from '../../utils/requestWithAxios';
import { TRun } from '../../common/types/run';

interface TAjaxRunsUpdate extends Record<string, any> {
  runs: (TRun & { session_id?: string })[];
  controller?: AbortController;
  verb?: TRequestProps['verb'];
}

export async function updateRuns({ runs, controller, verb = 'PUT' }: TAjaxRunsUpdate): Promise<Record<string, any>[]> {
  if (!runs.length) {
    return [];
  }
  const options: TRequestProps = {
    endpoint: verb === 'DELETE' ? `/runs/${runs[0].id}` : '/runs',
    verb,
    abortSignal: controller?.signal,
    payload: runs,
  };

  try {
    const resp = await requestWithAxios(options);
    return resp?.data?.length ? resp?.data : [resp];
  } catch (e: any) {
    const message = `URL: ${options.endpoint}! ${e}`;
    console.error(message);
    console.warn(options);
    return new Promise((resolve) => resolve([{ error: message }]));
  }
}
