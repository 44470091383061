import { FilterFieldProps } from '../../../../components/ListView/types';
import { ReactElement } from 'react';
import Row from '../../../../layouts/Row';
import { FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material';
import DateSelect from '../../../../components/FormComponents/DateSelect';

export default function FilterDateRange({ filter, setFilter }: FilterFieldProps): ReactElement {
  return (
    <Row display={'flex'} className={'cr-flex-col cr-items-start cr-text-start'}>
      <InputLabel shrink id="date-filter-label">
        Date filtering
      </InputLabel>
      <RadioGroup
        row
        aria-label="enable-date"
        name="enable-date"
        value={filter.run_ts?.dateEnabled ?? false} //
        onChange={(event): void => {
          setFilter({
            ...filter,
            run_ts: { ...filter.run_ts, dateEnabled: event.target.value !== 'false' },
          });
        }}
      >
        <FormControlLabel value="false" control={<Radio />} label="All Dates" />
        <FormControlLabel value="true" control={<Radio />} label="Date Range" />
      </RadioGroup>
      <div>Start Date:</div>
      <DateSelect
        name={'startDate'}
        defaultDate={filter.run_ts?.startDate}
        disabled={!filter.run_ts?.dateEnabled}
        onChange={(date) =>
          setFilter({
            ...filter,
            run_ts: {
              ...filter.run_ts,
              startDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            },
          })
        }
      />
      <div>End Date:</div>
      <DateSelect
        name={'endDate'}
        defaultDate={filter.run_ts?.endDate}
        disabled={!filter.run_ts?.dateEnabled}
        onChange={(date) =>
          setFilter({
            ...filter,
            run_ts: {
              ...filter.run_ts,
              endDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            },
          })
        }
      />
    </Row>
  );
}
