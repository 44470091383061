import { TRider } from '../../common/types/rider';

export type TRidersContext = {
  riders: TRider[];
  defaultRider: TRider;
  ridersById: Record<string, TRider>;
  selectedRiders: string[];
  isLoading: boolean;
};

export enum RidersActionTypes {
  SET_DATA = 'SET_DATA',
  SET_RIDERS = 'SET_RIDERS',
  SET_SELECTED = 'SET_SELECTED',
  SET_IS_LOADING = 'SET_IS_LOADING',
}
