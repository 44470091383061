import { TRunsContext } from '../runs/_types';
import { TConfigsContext } from './_types';

export const getGroupRuns = (runsById: TRunsContext['runsById']) => {
  return (state: {
    removeRuns: TConfigsContext['removeRuns'];
    selectedConfigId: TConfigsContext['selectedConfigId'];
  }): any[] => {
    return state.removeRuns.map((id: string) =>
      runsById[id].groups_runs?.find((groupRun) => state.selectedConfigId.includes(groupRun.group_id)),
    );
  };
};

export const getSessionRuns = (
  sessionId: string | number,
  configsByTestSessionId: TConfigsContext['configsByTestSessionId'],
): string[] => {
  return (
    configsByTestSessionId[sessionId]?.reduce<string[]>((acc, item) => {
      if (item.run_ids) {
        acc.push(...item.run_ids);
      }
      return acc;
    }, []) || []
  );
};

export const getSelectedConfig = (state: TConfigsContext): Record<string, any>[] => {
  return state.selectedConfigId ? state.selectedConfigId.map((id) => state.configsById[id]) : [];
};
export const getNewRuns = (runs: any[]) => {
  return (addRuns: string[]): any[] => runs.map((run) => addRuns.includes(run.id));
};
export const findConfigUsage = (state: TConfigsContext): { baseline: any[] } => ({
  baseline: state.configs.filter((config) => config.selectedConfigId.includes(config.baseline)),
});
