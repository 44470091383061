import { ReactElement, useEffect } from 'react';
import AppRoutes from './_Routes';
import { useSessions } from '../stores/sessions/useSessions';
import { useRuns } from '../stores/runs/useRuns';
import { useConfigs } from '../stores/configs/useConfigs';
import { useCalibrations } from '../stores/calibrations/useCalibrations';
import { useRiders } from '../stores/riders/useRiders';
import { useUser } from '../stores/user/useUser';

export default function PageProvider(): ReactElement {
  const [{ user_name, team_id }] = useUser();

  const [, , reloadRuns] = useRuns();
  const [, , reloadConfigs] = useConfigs();
  const [, , reloadCalibrations] = useCalibrations();
  const [, , reloadRiders] = useRiders();
  const [, , reloadSessions] = useSessions();

  useEffect(() => {
    if (user_name || !team_id) {
      reloadRuns();
      reloadConfigs();
      reloadCalibrations();
      reloadRiders();
      reloadSessions();
    }
  }, [user_name, team_id]);

  return (
    <div className="cr-relative cr-min-h-full cr-w-full">
      <AppRoutes />
    </div>
  );
}
