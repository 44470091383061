import { TTableColumn } from '../../../../components/ListView/types';

export const configTestSessionColumns: TTableColumn[] = [
  { label: 'Next', param: '_setNext' },
  { label: 'Cfg', param: 'letter' },
  { label: 'Parent', param: 'baseline' },
  { label: 'Description', param: 'description' },
  { label: 'Drag', param: 'average' },
  { label: '+/-', param: 'deltas' },
  { label: '_btn_Calibration', param: 'calibration_status' },
  { label: '_btn', param: '_calibration' },
  { label: '_icon', param: 'icon' },
  { label: '_btn_edit', param: 'edit' },
];
