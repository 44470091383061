import { useContext } from 'react';
import { FormContext, FormContextInterface } from './index';

export function useCustomFormContext(): FormContextInterface {
  const context = useContext(FormContext);
  if (!context) {
    throw Error('useCustomFormContext has to be used inside FormContextProvider');
  }
  return context;
}
