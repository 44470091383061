import { ReactElement } from 'react';
import { useUser } from '../../../stores/user/useUser';
import { useRiders } from '../../../stores/riders/useRiders';
import Column from '../../../layouts/Column';
import { getUserRole } from '../../../common/types/enums';
import { List, ListItem, ListItemText } from '@mui/material';

export default function Settings(): ReactElement {
  const [{ user_name, user_role, team_name_relationship, rider_id, email_address }] = useUser();
  const [{ ridersById }] = useRiders();
  return (
    <Column justifyItems={'start'}>
      <List>
        <ListItem>
          <ListItemText primary={user_name} secondary={'User Name'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={user_role ? getUserRole(user_role) : getUserRole(1000)} secondary={'User Role'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={email_address} secondary={'Email'} />
        </ListItem>
        {team_name_relationship ? (
          <ListItem>
            <ListItemText primary={team_name_relationship.name} secondary={'Team Name'} />
          </ListItem>
        ) : null}
        {rider_id ? (
          <ListItem>
            <ListItemText primary={ridersById[rider_id]?.displayName} secondary={'Rider Name'} />
          </ListItem>
        ) : null}
      </List>
    </Column>
  );
}
