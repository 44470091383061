import { LocalStorageKeys } from '../../common/interfaces';
import { createContext, Dispatch, ReactElement, useEffect, useReducer } from 'react';
import { TBaseContext } from '../types';
import { TConfigsContext } from './_types';
import usePrevious from '../../hooks/usePrevious';
import calibrationReducer from './reducer';
import { LocalStorageHandler } from '../../utils/toLocalStorage';
import { useCalibrationsGet } from '../ajax/calibrations';

const defaultEditedCalibration = (): string[] => {
  return LocalStorageHandler.get(LocalStorageKeys.EDITED_CALIBRATION) ?? [];
};
const defaultState: TConfigsContext = {
  configs: [],
  newConfigs: [],
  calibrations: [],
  calibrationsById: {},
  calibrationGates: {},
  selectedCalibrationId: defaultEditedCalibration(),
  isLoading: false,
};
export const CalibrationsContext = createContext<[TConfigsContext, Dispatch<any>, any, TConfigsContext | null]>([
  defaultState,
  (): void => undefined,
  null,
  null,
]);
CalibrationsContext.displayName = 'CalibrationContext';
export default function CalibrationProvider({ children, ...props }: TBaseContext): ReactElement {
  const [state, dispatch] = useReducer(calibrationReducer, defaultState);
  const prevState = usePrevious(state);

  useEffect(() => {
    LocalStorageHandler.save(LocalStorageKeys.EDITED_CALIBRATION, state.selectedCalibrationId);
  }, [state.selectedCalibrationId]);

  const { mutate } = useCalibrationsGet(dispatch);

  return <CalibrationsContext.Provider value={[state, dispatch, mutate, prevState]} {...{ ...props, children }} />;
}
