import { RunsActionTypes, TDefaultFilter, TDrawerPayload, TRunsContext } from './_types';
import { getSelectedRuns, selectedRunsReducer } from './getters';
import { toggleList } from '../utils/getUniqueList';

export const runsReducer = (
  state: TRunsContext,
  action: { type: RunsActionTypes; payload?: any | boolean | TDefaultFilter | TDrawerPayload },
): TRunsContext => {
  switch (action.type) {
    case RunsActionTypes.SET_SHOW_HIDDEN:
      return { ...state, showHidden: !state.showHidden };
    case RunsActionTypes.SET_DATA: {
      const runs = action.payload || [];
      const runsById = runs.reduce((acc: any, item: any) => ({ ...acc, [item.id]: item }), {});
      const newState = { ...state, runs, runsById };
      return {
        ...newState,
        mergedSelectedRuns: getSelectedRuns(newState).reduce(selectedRunsReducer, { count: 0 }),
      };
    }
    case RunsActionTypes.SET_RUNS_NEED_PROCESSING: {
      return {
        ...state,
        unprocessedRuns: action.payload,
      };
    }
    case RunsActionTypes.SET_EDITING_RUNS: {
      const updatedState = { ...state, selectedRuns: action.payload, plottedRuns: undefined };
      return {
        ...updatedState,
        mergedSelectedRuns: getSelectedRuns(updatedState).reduce(selectedRunsReducer, { count: 0 }),
      };
    }
    case RunsActionTypes.SET_PLOTTED_RUNS: {
      return { ...state, plottedRuns: action.payload };
    }
    case RunsActionTypes.SORT_RUNS:
      return {
        ...state,
        sortBy: action.payload,
      };
    case RunsActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case RunsActionTypes.SET_DATA_LOADING: {
      return {
        ...state,
        dataLoading: toggleList(state.dataLoading, action.payload),
      };
    }
    case RunsActionTypes.ADD_TO_DRAWER: {
      const currentDrawerState = state.drawers[action.payload.drawerName] ?? [];
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: [...currentDrawerState, action.payload.id],
        },
      };
    }
    case RunsActionTypes.REMOVE_FROM_DRAWER: {
      const currentDrawerState = state.drawers[action.payload.drawerName]?.filter((id) => id !== action.payload.id);
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: [...currentDrawerState],
        },
      };
    }
    default:
      return state;
  }
};
