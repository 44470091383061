import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';
/* eslint-disable max-len */
export const StreamlinesRider = ({ fill = 'none', stroke = 'currentColor', size }: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        fill={'currentColor'}
        d="M34.873,34.516c0.152,0.077,0.355-0.004,0.43-0.181
		c0.057-0.14,0.04-0.305-0.062-0.399l-2.116-2.011l-2.06-1.842l-1.016-0.856l-0.992-0.822l-1.461-1.135l-1.439-1.052l-1.405-0.94
		l-1.371-0.856l-1.522-0.856l-1.489-0.734l-0.729-0.322l-0.725-0.281l-1.128-0.398l-1.102-0.312l-1.073-0.238l-1.054-0.148
		l-1.024-0.084l-0.55-0.008l-0.561,0.025l-0.58,0.035l-0.563,0.073l-0.872,0.157L9.52,21.552L8.646,21.84l-0.888,0.359l-1.011,0.466
		l-1.002,0.551l-0.969,0.629L3.84,24.531l-0.881,0.758l-0.436,0.422l-0.41,0.424l-0.376,0.432l-0.352,0.42
		c-0.412,0.533-0.365,1.291,0.107,1.703c0.235,0.187,0.527,0.291,0.818,0.252h0.01l1.601-0.183l1.507-0.155l1.187-0.093l1.144-0.067
		l1.689-0.051l1.732,0.021l1.829,0.097l1.122,0.098l1.173,0.131l1.19,0.147l1.236,0.181l1.768,0.3l1.844,0.362l1.877,0.447
		l1.024,0.272l1.047,0.312l1.134,0.358l1.172,0.4l2.325,0.863l2.429,1.001L34.873,34.516"
      />
      <circle {...{ stroke, fill }} strokeWidth="1.5" strokeMiterlimit="10" cx="13.667" cy="8.709" r="6.897" />
      <path
        {...{ stroke, fill }}
        strokeWidth="1.9"
        strokeMiterlimit="10"
        d="M1.913,28.156
		c0-6.741,5.457-12.198,12.2-12.198"
      />
      <path
        {...{ stroke, fill }}
        strokeWidth="1.9"
        strokeMiterlimit="10"
        d="M25.936,28.156
		c0-6.741-5.455-12.198-12.199-12.198"
      />
    </Svg>
  );
};
/* eslint-enable max-len */
