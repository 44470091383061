import { ReactElement } from 'react';
import Row from '../../../layouts/Row';
import { TDefaultFilter } from '../../../stores/runs/_types';

export default function FilterTitle({ riders, run_ts }: TDefaultFilter): ReactElement {
  const { startDate = null, endDate = null, dateEnabled } = run_ts ?? {};
  return (
    <Row display={'flex'} justifyContent={'space-between'}>
      <span>{dateEnabled && endDate && startDate ? startDate + ' : ' + endDate : ' All Dates'}</span>
      <span>{riders?.join(', ')}</span>
    </Row>
  );
}
