import { forwardRef, Fragment } from 'react';
import ListItem from './ListItem';
import { ReactElement } from 'react';
import Row from '../../../layouts/Row';
import { TListViewItemDefault } from '../types';
import { useListView } from '../useListView';

const List = <Props,>(props: Props, ref: any): ReactElement | null => {
  const { list, addNewRow, listClassName, dataTip, ListItem: CustomListItem } = useListView();

  return list && list.length ? (
    <div ref={ref} className={'list-scroll'} {...props}>
      {addNewRow && (
        <Row
          id={'new_item'}
          className={`row-h ${listClassName}`}
          data-tip={dataTip}
          justifyItems={'start'}
          style={{
            backgroundColor: '#97FCF7',
            borderRadius: '5px',
            padding: '0.675em 0.375em',
            minHeight: '3em',
            color: 'inherit',
          }}
        >
          {addNewRow()}
        </Row>
      )}
      {list.map((item: TListViewItemDefault & { hidden?: boolean }, index) =>
        CustomListItem ? (
          <Fragment key={item?.id}>{CustomListItem(item, index)}</Fragment>
        ) : (
          <ListItem key={item?.id} {...{ item, index }} isHidden={item?.hidden} />
        ),
      )}
    </div>
  ) : null;
};

const ListWithRef = forwardRef(List);
export default ListWithRef;
