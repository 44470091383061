import { Component, ErrorInfo, PropsWithChildren, ReactElement, ReactNode } from 'react';

type Props = PropsWithChildren<{
  fallback: ReactElement;
  asyncLog?: (info: ErrorInfo) => Promise<any>;
  fallbackRender?: (error: unknown) => ReactNode;
}>;
type State = { hasError: boolean; error: unknown };

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    if (error instanceof Error) {
      return { hasError: true, error };
    }
  }

  componentDidCatch(error: unknown, info: ErrorInfo) {
    if (error instanceof Error) {
      console.warn(error, info.componentStack);
      this.props.asyncLog?.(info);
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallbackRender?.(this.state.error) ?? this.props.fallback;
    }

    return this.props.children;
  }
}
