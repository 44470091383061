import { SessionsActionTypes, TTestSessionContext } from './_types';
import { TTestSessionWithRuns } from '../../common/types/session';

export const sessionReducer = (
  state: TTestSessionContext,
  action: { type: SessionsActionTypes; payload?: any | boolean },
): TTestSessionContext => {
  switch (action.type) {
    case SessionsActionTypes.SET_DATA:
    case SessionsActionTypes.SET_SESSIONS: {
      const sessions = action.payload || [];
      return {
        ...state,
        sessions,
        byId: sessions.reduce(byIdSessionsReducer, {}),
      };
    }
    case SessionsActionTypes.SET_SELECTED: {
      return { ...state, selected: action.payload };
    }
    case SessionsActionTypes.ADD_SESSION: {
      const sessions = !action.payload.isNew ? [...state.sessions, action.payload] : state.sessions;
      const byId = !action.payload.isNew ? sessions.reduce(byIdSessionsReducer, {}) : state.byId;
      const newSession = action.payload.isNew ? action.payload : {};
      return {
        ...state,
        sessions,
        byId,
        newSession,
      };
    }
    case SessionsActionTypes.EDIT_SESSION: {
      const sessions = !action.payload.isNew ? [...state.sessions, action.payload] : state.sessions;
      const byId = !action.payload.isNew ? sessions.reduce(byIdSessionsReducer, {}) : state.byId;
      const newSession = action.payload ? action.payload : {};
      return {
        ...state,
        sessions,
        byId,
        newSession,
      };
    }
    case SessionsActionTypes.REMOVE_SESSION: {
      const id = action.payload;
      const sessions = 'new' !== id ? state.sessions.filter((session) => session.id !== id) : state.sessions;
      const byId = 'new' !== id ? sessions.reduce(byIdSessionsReducer, {}) : state.byId;
      const newSession = 'new' === id ? { id: '' } : state.newSession;
      return {
        ...state,
        sessions,
        byId,
        newSession,
      };
    }
    case SessionsActionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

const byIdSessionsReducer = (acc: any, item: TTestSessionWithRuns): Record<string, TTestSessionWithRuns> => {
  return { ...acc, [item.id ?? 'undefined']: item };
};
