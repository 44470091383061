/** @jsxImportSource @emotion/react */
import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ListViewItem,
  ListViewItemExtended,
  TListViewContext,
  TListViewItemDefault,
  TListViewProps,
  TListViewProviderProps,
} from './types';
import Headers from './Headers';
import List from './List';
import Filter from './Filter';
import { css } from '@emotion/react';
import { LocalStorageKeys } from '../../common/interfaces';
import { LocalStorageHandler } from '../../utils/toLocalStorage';
import { componentKey } from '../../utils/componentKeyGen';
import { Tooltip } from '@mui/material';
import { IconWrap } from '../Nav';
import EditButton from '../Buttons/EditButton';
import { Plus } from '../../icons/Plus';
import { useListView } from './useListView';
import { ListViewRow } from './ListViewRow';
import { strSpaceToUnderscore } from '../../utils/removeSpacesFromString';
import ListViewError from './ErrorBoundry/ListViewError';
import ListCellError from './ErrorBoundry/ListCellError';
import ListRowError from './ErrorBoundry/ListRowError';
import { colWidthObject, widthByParam } from '../../pages/columnWidths';
import { KeyOf } from '../../common/types';

function ListView<ListType extends TListViewItemDefault = TListViewItemDefault>({
  rowHeight,
  className,
  filterFields,
  customFilterField,
  noHeaders,
  children,
  ...otherProps
}: PropsWithChildren<TListViewProps<ListType>>): ReactElement {
  const refScrollWrap = useRef<HTMLDivElement>(null);
  const listStyle = css({
    width: '100%',
    textAlign: 'center',
    justifyItems: 'start',
    display: 'flex',
    flex: '0 1 min-content',
    flexDirection: 'column',
    minHeight: 0,
    '.list-scroll-wrap': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
    },
    '.list-scroll': {
      width: '100%',
      overflowY: 'auto',
      overscrollBehaviorY: 'contain',

      '&.filter-open': {
        maxHeight: 'calc(70vh - 220px)',
      },

      '.row-h': {
        height: rowHeight ?? 'max-content',
      },
    },
  });
  // console.log(boundingRect, boundingRect1);

  return (
    <ListViewError>
      <ListViewProvider filterFields={filterFields} noHeaders={noHeaders} {...otherProps}>
        <div className={className} css={listStyle}>
          {(filterFields || customFilterField) && <Filter {...{ customFilterField }} />}
          {!!(otherProps.list && otherProps.list.length) && (
            <div className={'list-scroll-wrap'}>
              {!noHeaders ? <Headers /> : null}
              <List ref={refScrollWrap} />
            </div>
          )}
        </div>
        <div style={{ overflow: 'scroll', flex: '1 1 0%' }}>
          <table className={'cr-table-auto cr-border-separate cr-border-spacing-0 ' + (className ?? '')}>
            <colgroup>
              {otherProps.columns.map((column) => (
                <col
                  key={componentKey('colgroup', column.param)}
                  span={column.span ?? 1}
                  className={column.className}
                />
              ))}
            </colgroup>
            {children}
          </table>
        </div>
      </ListViewProvider>
    </ListViewError>
  );
}

function ListViewEditRow(
  props: PropsWithChildren<{
    className?: string;
    rowIndex: number;
  }>,
): ReactElement {
  return (
    <ListRowError>
      <tr className={'cr-transition-[opacity,height] ' + (props.className ?? '')}>{props.children}</tr>
    </ListRowError>
  );
}

const dataFilter =
  (filter: TListViewContext['filter'], filterConditions: TListViewContext['filterConditions']) => (item: any) => {
    if (!filterConditions) return true;
    // console.log(filterConditions, filter);
    return Object.keys(filterConditions).reduce((acc, filterKey) => {
      if (
        filter[filterKey as keyof ListViewItem] === undefined ||
        filter[filterKey as keyof ListViewItem]?.includes?.('all') ||
        (Array.isArray(filter[filterKey as keyof ListViewItem]) &&
          filter[filterKey as keyof ListViewItem]?.length === 0)
      ) {
        return acc;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return filterConditions[filterKey as keyof ListViewItem](item, filter[filterKey]) && acc;
    }, true);
  };

function ListViewBody<T extends { [key: string]: any }>({
  render,
  onClick,
  data,
  className,
  sort,
}: {
  render: (options: {
    dataItem: T;
    index: number;
    onClick?: CallableFunction;
    className?: string;
  }) => ReactElement<ListViewRenderProp> | ReactElement<ListViewRenderProp>[];
  data: T[];
  onClick?: (dataItem: T) => any;
  className?: string;
  sort?: (itemA: T, itemB: T) => -1 | 0 | 1;
}): ReactElement | ReactElement[] | null {
  const { filterConditions, filter } = useListView();
  const printList = useMemo(() => {
    return !filterConditions ? data : data?.filter(dataFilter(filter, filterConditions));
  }, [filterConditions, data, filter]);

  if (sort && Array.isArray(printList)) {
    printList.sort(sort);
  }

  return (
    <tbody>
      {printList.length
        ? printList.map((dataItem, i) => {
            const children = render({ dataItem, index: i, className, onClick: () => onClick?.(dataItem) });
            return !Array.isArray(children) ? (
              <ListViewRow key={componentKey('row', dataItem.id)}>{children}</ListViewRow>
            ) : (
              children
            );
          })
        : null}
    </tbody>
  );
}

const ListViewHeaderCell: FC<PropsWithChildren<HeaderChildWithParam>> = (props) => {
  return (
    <ListCellError>
      <th
        scope={'col'}
        className={
          (props.className ?? '') +
          ' cr-bg-inherit cr-sticky cr-top-[-1px] cr-border-y cr-border-solid cr-border-y-blue-900 ' +
          ' ' +
          widthByParam(props.param)
        }
      >
        {props.children}
      </th>
    </ListCellError>
  );
};

function ListViewCell(
  props: PropsWithChildren<{
    className?: string;
    onClick?: CallableFunction;
    colSpan?: number;
    rowSpan?: number;
    style?: CSSProperties;
  }>,
): ReactElement {
  return (
    <ListCellError>
      <td
        style={props.style}
        scope={'row'}
        colSpan={props.colSpan}
        rowSpan={props.rowSpan}
        onClick={() => props.onClick?.()}
        className={'cr-relative ' + props.className}
      >
        {props.children}
      </td>
    </ListCellError>
  );
}

type HeaderChildWithParam = { param: string | KeyOf<typeof colWidthObject>; className?: string };
type HeaderChildParams = HeaderChildWithParam;
type HeaderChild = ReactElement<HeaderChildParams>;
type ListViewRowProps = {
  children: HeaderChild | HeaderChild[];
  className?: string;
};
type ListViewRenderProp = {
  className?: string;
  cells: <T>(listItem: T) => ReactElement[];
};

function ListViewHeader(props: Partial<ListViewRowProps> & { headers?: string[] }): ReactElement | ReactElement[] {
  const { onAdd } = useListView();
  if (props.children || props.headers) {
    return (
      <thead className={'cr-h-[38px]'}>
        <ListViewRow className={'font-head cr-uppercase cr-tracking-wide [&>th]:cr-text-left [&>th]:cr-align-middle '}>
          {props.headers?.map((header) => {
            const param = strSpaceToUnderscore(header).toLowerCase();
            return header[0] !== '_' ? (
              <ListViewHeaderCell
                className={props.className ?? ''}
                key={componentKey('table-header', param)}
                param={param}
              >
                {header}
              </ListViewHeaderCell>
            ) : null;
          })}
          {props.children}
          {onAdd ? (
            <ListViewHeaderCell param={'icon'} className={'cr-w-36 cr-bg-neutral-100 ' + (props.className ?? '')}>
              <AddNew />
            </ListViewHeaderCell>
          ) : null}
        </ListViewRow>
      </thead>
    );
  }
  return <></>;
}

const AddNew = (): ReactElement | null => {
  const { onAddLabel = 'Add new', onAdd } = useListView();
  return onAdd ? (
    <Tooltip title={onAddLabel} sx={{ zIndex: '9999' }}>
      <IconWrap>
        <EditButton
          data-testid={`btn-add-${onAddLabel}`}
          color="success"
          icon={<Plus />}
          onClick={() => onAdd?.()}
          label={onAddLabel}
          disabled={onAddLabel === 'Add disabled'}
        />
      </IconWrap>
    </Tooltip>
  ) : null;
};

export { ListViewHeader, ListViewEditRow, AddNew, ListViewCell, ListViewHeaderCell, ListViewBody };
const ListViewWithHelpers = ListView;
export default ListViewWithHelpers;

export const ListViewContext = React.createContext<TListViewContext<any> | null>(null);

const getFilterFromLS = (defaultFilter: any): any =>
  LocalStorageHandler.get(LocalStorageKeys.FILTERS) ??
  defaultFilter ?? {
    hidden: false,
    run_ts: { startDate: null, endDate: null },
  };

export function ListViewProvider<ListType extends TListViewItemDefault = TListViewItemDefault>({
  children,
  classes,
  columns,
  list,
  noHeaders,
  ListItem,
  icon,
  sort,
  filterFields,
  filterConditions,
  defaultFilter,
  listCellRender,
  listCellValueCallback,
  isSelected,
  columnWidths,
  onClick,
  editRow,
  addNewRow,
  withSelectAll,
  onAdd,
  onAddLabel,
  noSeparation,
  listClassName,
  selectedCount,
}: TListViewProviderProps<ListType>): ReactElement {
  // const [sort, setSort] = useState({});
  const [filter, setFilter] = useState<Record<keyof ListViewItemExtended<any>, any>>(getFilterFromLS(defaultFilter));

  useEffect(() => {
    LocalStorageHandler.save(LocalStorageKeys.FILTERS, filter);
  }, [filter.riders, filter.hidden, filter.run_ts?.dateEnabled, filter.run_ts?.startDate, filter.run_ts?.endDate]);

  if (!columnWidths) {
    columnWidths = useCallback((): CSSProperties['gridTemplateColumns'] => {
      return `repeat(${columns.length},minmax(100px,1fr))`;
    }, []);
  }
  const printList = useMemo(() => {
    return !filterConditions ? list : list?.filter(dataFilter(filter, filterConditions as any));
  }, [filterConditions, list, filter]);

  if (sort && Array.isArray(printList)) {
    printList.sort(sort);
  }

  const contextValue = useMemo(
    () => ({
      columns,
      classes,
      icon,
      selectedCount,
      list: printList,
      ListItem: ListItem,
      filterConditions,
      filterFields,
      noHeaders,
      // sort,
      // setSort,
      filter,
      setFilter,
      listCellRender,
      listCellValueCallback,
      listClassName,
      isSelected,
      onClick,
      editRow,
      addNewRow,
      selectAll: withSelectAll,
      columnWidths: columnWidths ?? ((): any => undefined),
      onAdd,
      onAddLabel,
      noSeparation,
    }),
    [
      columns,
      classes,
      icon,
      noHeaders,
      selectedCount,
      list,
      filter,
      filterConditions,
      filterFields,
      defaultFilter,
      sort,
      listCellRender,
      listCellValueCallback,
      isSelected,
      columnWidths,
      onClick,
      editRow,
      addNewRow,
      withSelectAll,
      onAdd,
      onAddLabel,
      noSeparation,
      listClassName,
    ],
  );

  return (
    <ListViewContext.Provider value={contextValue}>
      {printList || children ? children : 'There is no printable list'}
    </ListViewContext.Provider>
  );
}
