/** @jsxImportSource @emotion/react */
import { ReactElement } from 'react';
import EditButton from '../../../components/Buttons/EditButton';

export type ListEditButtonProps<T> = {
  item: T;
  name?: string;
  disabled?: boolean;
  [key: string]: any;
};
export default function ListEditButton<T extends Record<string, any>>({
  name,
  disabled,
  ...rest
}: ListEditButtonProps<T>): ReactElement {
  return (
    <>
      <EditButton {...rest} disabled={disabled} label={name ?? 'Edit'} />
      {`Edit${' ' + name}`}
    </>
  );
}
