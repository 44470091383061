export enum LocalStorageKeys {
  EDITED_RUNS = 'editedRuns',
  EDITED_CONFIG = 'editedGroup',
  EDITED_CALIBRATION = 'editedCalibration',
  MERGED_RUNS = 'mergedRuns',
  TOKEN = 'token',
  FILTERS = 'filters',
  PLOTS = 'plotsSettings',
  GRAPH_CONFIG = 'graphConfig',
  VIEWRESULTS_CATEGORIES = 'vr_categories',
  DATAPOINTS_CONFIG = 'data_points_config',
}
