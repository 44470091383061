import { requestWithAxios, TRequestProps } from '../../utils/requestWithAxios';

interface TAjaxCommonProps extends Record<string, any> {
  verb?: TRequestProps['verb'];
  controller?: AbortController;
}

type TAjaxGroup = TAjaxCommonProps &
  (
    | {
        groupId: string[];
        addRuns: string[];
        removeGroupRuns: string[];
        tags?: string | string[];
      }
    | {
        removeGroupRuns: string[];
        tags?: string | string[];
      }
  );

export async function updateGroupRuns({
  groupId,
  controller,
  addRuns,
  removeGroupRuns,
}: TAjaxGroup): Promise<{ added: any[]; removed: any[]; errors?: any }> {
  const returnData: { added: any[]; removed: any[] } = { added: [], removed: [] };
  const options: TRequestProps = { endpoint: '/groups-runs', verb: 'POST', abortSignal: controller?.signal };
  try {
    if (addRuns?.length) {
      const { data } = (await requestWithAxios({
        ...options,
        payload: addRuns.reduce(
          (acc: string[], runId: string) => [
            ...acc,
            ...groupId.map((groupId: string) => ({
              group_id: groupId,
              run_id: runId,
            })),
          ],
          [],
        ),
      })) ?? { data: {} };
      returnData.added = data;
    }
    if (removeGroupRuns?.length) {
      const allData = await Promise.all(
        removeGroupRuns.map((id) => {
          return requestWithAxios({ ...options, verb: 'DELETE', endpoint: `/groups-runs/${id}` });
        }),
      );
      returnData.removed = allData.map((item) => item?.data);
    }

    return returnData;
  } catch (e: any) {
    const message = `URL: ${options.endpoint}! ${e}`;
    console.error(message);
    console.warn(options);
    return new Promise((resolve) => resolve({ ...returnData, errors: message }));
  }
}
