import { createContext, ReactElement, useState } from 'react';
import { TBaseContext } from '../../stores/types';
import { TNavContext } from './useNav';

export const NavContext = createContext<TNavContext>([null, () => undefined]);
NavContext.displayName = 'NavContext';

export default function NavProvider({ children, ...props }: TBaseContext): ReactElement {
  const [state, setState] = useState<string | null>(null);
  return (
    <NavContext.Provider value={[state, setState]} {...{ ...props }}>
      {children}
    </NavContext.Provider>
  );
}
