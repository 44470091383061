import { IconWrap } from '../../../../components/Nav';
import { Tooltip } from '@mui/material';

export default function IsNextFlag({
  id,
  selected,
  disabled,
  name,
}: {
  id: string;
  selected: boolean;
  disabled?: boolean;
  name: string;
}) {
  return (
    <Tooltip title={'Next Config'}>
      <IconWrap>
        <label
          style={{
            position: 'relative',
          }}
        >
          <input
            disabled={disabled}
            checked={selected}
            readOnly
            name={name}
            type={'radio'}
            id={id + '-radio'}
            value={id}
            className={'cr-mx-0 cr-mt-1 cr-h-[25px] cr-w-[25px] cr-accent-blue-500'}
          />
          {selected && (
            <span
              style={{
                color: 'white',
                width: '15px',
                fontSize: '15px',
                lineHeight: '17px',
                position: 'absolute',
                left: 'calc(50% - 7.5px)',
                textAlign: 'center',
                top: '-9px',
              }}
            >
              N
            </span>
          )}
        </label>
      </IconWrap>
    </Tooltip>
  );
}
