import { LocalStorageHandler } from '../../utils/toLocalStorage';
import useSWR from 'swr';
import { requestWithAxios } from '../../utils/requestWithAxios';
import { useMemo } from 'react';

export class ProfileFetcher {
  static get key() {
    return ['/users/profile', LocalStorageHandler.getUser()];
  }

  static async fetcher(args: string[]) {
    const { data } =
      (await requestWithAxios({
        endpoint: args[0],
        verb: 'GET',
      })) || {};
    return data;
  }
}

export function useProfileGet() {
  const viewType = LocalStorageHandler.get('view-selected') ?? 'Standard';
  const user_role = LocalStorageHandler.get<number>('userRole') ?? 0;

  const { data, error, isLoading, isValidating, mutate } = useSWR(ProfileFetcher.key, ProfileFetcher.fetcher);

  if (error) {
    LocalStorageHandler.save('userRole', 0);
  }

  if (data) {
    LocalStorageHandler.save('userRole', data.user_role);
  }

  const memoizedUserData = useMemo(
    () => ({
      viewType,
      user_role,
      ...data,
      show_config: !isLoading && data?.user_role >= 1000,
    }),
    [data, viewType, user_role, isLoading],
  );
  return {
    data: memoizedUserData,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}
