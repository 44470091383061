import { useEffect, useRef, useState } from 'react';
import usePrevious from './usePrevious';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCirrusLocation(match: RegExp, refresh?: boolean): any {
  const [, setWindowPath] = useState(() => window.location.pathname);

  const prevPathName = usePrevious(window.location.pathname);
  let matches = window.location.pathname.match(match);

  if (window.location.pathname.match(regexpSessions)) {
    matches = window.location.pathname.match(/(?<pathname>\/session)$/);
  }
  const matchesRef = useRef(matches);

  const pathChangeListener = (): void => {
    if (refresh && window.location.pathname !== prevPathName) {
      setWindowPath(window.location.pathname);
    }
  };

  useEffect(() => {
    if (window.location.pathname !== prevPathName) {
      matchesRef.current = matches;
    }
  }, [prevPathName, window.location.pathname]);

  useEffect(() => {
    if (refresh) {
      document.addEventListener('click', pathChangeListener);
    }
    return (): void => {
      if (refresh) {
        document.removeEventListener('click', pathChangeListener);
      }
    };
  }, []);

  return {
    ...window.location,
    matched: !!matchesRef.current,
    matches: matchesRef.current,
    ...(matches ? matches.groups : []),
  };
}

export const regexpSessions = new RegExp(
  // eslint-disable-next-line max-len
  /(?<pathname>\/session\/(?<sessionUuid>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:\/configs\/(?<configsUuid>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))?)/,
);

export const regexpSettings = new RegExp(
  // eslint-disable-next-line max-len
  /(?<settings>\/settings\/user\/(?<uuid>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))\/?(?<pathname>[a-z]*)/,
);
export const regexpNavTab = new RegExp(
  // eslint-disable-next-line max-len
  /^(?<pathname>\/[a-zA-Z-]*)/,
);
