import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';
// import { Lock } from '@mui/icons-material';
/* eslint-disable max-len */
export const StreamlinesConfig = ({ fill, stroke, size }: TCirrusIconProps): ReactElement => {
  return (
    <div style={{ position: 'relative' }}>
      {/*<Lock fontSize={'inherit'} style={{ position: 'absolute', right: 10, top: 6 }} />*/}
      <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
        <path
          fill={fill ?? 'currentColor'}
          stroke={stroke ?? 'currentColor'}
          strokeWidth="0.5"
          d="M32.882,15.109c0.109,0.081,0.267,0.058,0.348-0.055
		c0.07-0.087,0.07-0.206,0.002-0.294l-1.324-1.8l-1.307-1.668l-0.647-0.789L29.311,9.75l-0.948-1.067l-0.943-0.996l-0.932-0.92
		l-0.92-0.849L24.531,5.04l-1.022-0.784l-0.51-0.354l-0.504-0.328l-0.796-0.48l-0.786-0.417L20.134,2.32l-0.769-0.292l-0.759-0.234
		l-0.417-0.104l-0.426-0.086L17.33,1.536l-0.44-0.05l-0.671-0.039L15.529,1.45l-0.704,0.048l-0.709,0.093l-0.821,0.157l-0.82,0.22
		l-0.813,0.271l-0.794,0.326L10.104,2.95L9.724,3.168L9.358,3.402l-0.336,0.23l-0.32,0.244c-0.377,0.302-0.44,0.85-0.141,1.229
		c0.145,0.181,0.355,0.298,0.585,0.324V5.426l1.229,0.15l1.156,0.162l0.898,0.141l0.871,0.155l1.279,0.268l1.297,0.315l1.363,0.393
		l0.83,0.265l0.867,0.294l0.869,0.313l0.909,0.347l1.288,0.524l1.336,0.585l1.349,0.646l0.738,0.376l0.749,0.404l0.804,0.456
		l0.822,0.482l1.636,1.026l1.691,1.135l1.749,1.246"
        />
        <path
          fill={fill ?? 'currentColor'}
          stroke={stroke ?? 'currentColor'}
          strokeWidth="0.5"
          d="M6.216,7.104C6.232,6.972,6.134,6.844,5.997,6.83
		C5.888,6.813,5.784,6.874,5.739,6.974L4.831,9.016l-0.804,1.961l-0.365,0.952l-0.338,0.929l-0.459,1.355l-0.399,1.308l-0.338,1.267
		l-0.285,1.215l-0.25,1.336l-0.176,1.277l-0.055,0.612l-0.039,0.604L1.299,22.76l0.026,0.891l0.074,0.853l0.125,0.812l0.171,0.776
		l0.116,0.414l0.136,0.412l0.155,0.411l0.175,0.407l0.296,0.606l0.345,0.599l0.389,0.586l0.433,0.571l0.542,0.637l0.595,0.604
		l0.639,0.571l0.675,0.53l0.713,0.474l0.378,0.219l0.385,0.205l0.366,0.177l0.372,0.157c0.449,0.181,0.957-0.034,1.137-0.483
		c0.085-0.216,0.083-0.456-0.006-0.667l-0.003,0.001l-0.477-1.142l-0.432-1.086l-0.321-0.85l-0.296-0.832l-0.399-1.241l-0.366-1.288
		l-0.333-1.379l-0.181-0.851l-0.174-0.898l-0.157-0.913l-0.148-0.96l-0.18-1.379l-0.153-1.452L5.806,16.76l-0.037-0.825l-0.02-0.849
		L5.748,14.16l0.012-0.955l0.083-1.929l0.151-2.034l0.218-2.136"
        />
        <path
          fill={fill ?? 'currentColor'}
          stroke={stroke ?? 'currentColor'}
          strokeWidth="0.5"
          d="M12.971,34.363c-0.126,0.057-0.184,0.203-0.126,0.328
		c0.041,0.102,0.146,0.164,0.256,0.148l2.22-0.263l2.096-0.312l1.001-0.174l0.973-0.186l1.399-0.299l1.329-0.327l1.262-0.356
		l1.189-0.379l1.275-0.47l1.188-0.5l0.559-0.268l0.536-0.271l0.809-0.455l0.754-0.479l0.692-0.503l0.638-0.522l0.578-0.546
		l0.295-0.312l0.286-0.329l0.275-0.344l0.26-0.356l0.366-0.564l0.336-0.602l0.306-0.634l0.269-0.666l0.269-0.789l0.219-0.819
		l0.162-0.844l0.111-0.851l0.042-0.857l-0.004-0.437l-0.022-0.432l-0.033-0.408l-0.056-0.4c-0.073-0.477-0.521-0.803-0.995-0.729
		c-0.23,0.036-0.438,0.162-0.572,0.348l0.003,0.002l-0.738,0.996l-0.712,0.925l-0.562,0.708l-0.566,0.683l-0.861,0.979l-0.917,0.973
		l-1.013,0.994l-0.64,0.59l-0.683,0.609l-0.705,0.602l-0.749,0.619l-1.096,0.86l-1.166,0.873l-1.229,0.854l-0.692,0.454l-0.72,0.455
		l-0.794,0.473l-0.827,0.476l-1.702,0.917l-1.822,0.91l-1.947,0.904"
        />
      </Svg>
    </div>
  );
};
/* eslint-enable max-len */
