import { Button, Dialog } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { TButtonProps } from '../../common/types/buttonProps';
import { TActionGuard } from './useActionGuard';

export const ActionGuardBtn = (props: TButtonProps): ReactElement => (
  <Button {...props} variant="contained" className="classicButton " />
);

function ActionGuard(props: TActionGuard): ReactNode {
  return !props.skip ? (
    <>
      {props.children}
      <Dialog
        data-testid={'action-guard'}
        className="modal"
        open={props.open}
        sx={{ zIndex: 9999 }}
        onClose={(): void => props.toggle()}
        aria-labelledby="Destructive action warning"
        aria-describedby="Do you want to perform action which leads to lost data?"
      >
        <p style={{ whiteSpace: 'break-spaces' }}>{props.message}</p>
        <div className="line">
          {props.okOnly ?? (
            <ActionGuardBtn
              data-testid={'action-guard-no-btn'}
              onClick={(e) => {
                e.stopPropagation();
                props.onNo?.(e);
                props.toggle();
              }}
            >
              No
            </ActionGuardBtn>
          )}
          <ActionGuardBtn
            data-testid={'action-guard-yes-btn'}
            onClick={(e) => {
              e.stopPropagation();
              props.onYes(e);
              props.toggle();
            }}
          >
            {props.okOnly ? 'OK' : 'Yes'}
          </ActionGuardBtn>
        </div>
      </Dialog>
    </>
  ) : (
    props.children
  );
}

ActionGuard.Button = ActionGuardBtn;
export { ActionGuard };
