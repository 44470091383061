import { v4 as randomUUID, validate, version } from 'uuid';

export function UUID(): string;
export function UUID(
  uuidToValidation?: ReturnType<typeof randomUUID>,
): typeof uuidToValidation extends undefined ? string : ReturnType<typeof randomUUID> | undefined {
  if (!uuidToValidation) {
    uuidToValidation = randomUUID();
  } else {
    if (!validate(uuidToValidation) && version(uuidToValidation) !== 4) {
      return undefined;
    }
  }
  return uuidToValidation;
}
