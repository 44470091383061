import { ReactElement } from 'react';
import { ButtonProps } from '@mui/material';
import { CSSObject } from '@emotion/react/dist/emotion-react.cjs';
import { StreamlinesSaveAndExit } from '../../icons/StreamlinesSaveAndExit';
import { StreamlinesRefresh } from '../../icons/StreamlinesRefresh';
import { StreamlinesSave } from '../../icons/StreamlinesSave';
import { Clear } from '@mui/icons-material';

export type ButtonItem = {
  icon: ReactElement;
  id: string;
  onClick: CallableFunction;
  color?: ButtonProps['color'];
  style?: CSSObject;
  disabled?: boolean;
};

export type DefaultModalButtonsProps = {
  onClose?: CallableFunction;
  disableOnClose?: boolean;
  onRefresh?: CallableFunction;
  disableOnRefresh?: boolean;
  onSaveAndExit?: CallableFunction;
  disableOnSaveAndExit?: boolean;
  onSave?: CallableFunction;
  disableOnSave?: boolean;
};

export default function defaultModalButtons({
  onClose,
  disableOnClose,
  onRefresh,
  disableOnRefresh,
  onSaveAndExit,
  disableOnSaveAndExit,
  onSave,
  disableOnSave,
}: DefaultModalButtonsProps = {}): ButtonItem[] {
  return [
    {
      icon: <StreamlinesRefresh size={'md'} />,
      id: 'refresh',
      onClick: onRefresh ?? ((): void => undefined),
      color: 'secondary',
      disabled: disableOnRefresh,
    },
    {
      icon: <StreamlinesSaveAndExit size={'md'} />,
      id: 'exit',
      onClick: onSaveAndExit ?? ((): void => undefined),
      color: 'success',
      disabled: disableOnSaveAndExit,
    },
    {
      icon: <StreamlinesSave size={'md'} />,
      id: 'save',
      onClick: onSave ?? ((): void => undefined),
      color: 'success',
      disabled: disableOnSave,
    },
    {
      icon: <Clear fontSize={'medium'} />,
      id: 'close',
      onClick: onClose ?? ((): void => undefined),
      color: 'error',
      disabled: disableOnClose,
    },
  ];
}
