import { useState, ChangeEvent, useContext } from 'react';
import ThemeHandler from '../index';
import { Theme, ThemeTypes } from '../../common/interfaces';
import { StreamlinesThemeContext } from './index';
import { createStyles } from '../styles/createStyles';
import { Interpolation } from '@emotion/react/dist/emotion-react.cjs';

export type ThemeUpdate = {
  createStyles: <T extends { [key: string]: Interpolation<Theme> }>(arg: T) => T;
  themeType: ThemeTypes;
  current: Theme;
  setType: (type: ChangeEvent<HTMLInputElement> | { target: { value: any } }) => void;
};

export default function useThemeUpdate(): ThemeUpdate {
  const [themeType, setThemeType] = useState(ThemeHandler.getType());

  return {
    createStyles,
    themeType,
    current: ThemeHandler.current(),
    setType(type): void {
      switch (type.target.value) {
        case ThemeTypes.DARK:
          return setThemeType(ThemeHandler.toDarkTheme());
        case ThemeTypes.CONTRAST:
          return setThemeType(ThemeHandler.toContrastTheme());
        default:
          return setThemeType(ThemeHandler.toLightTheme());
      }
    },
  };
}

export function useTheme(): ThemeUpdate {
  const theme = useContext(StreamlinesThemeContext);

  if (!theme) {
    throw Error('useTheme must be used in the StreamlinesThemeProvider component');
  }

  return theme;
}
