import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';

/* eslint-disable max-len */
export const StreamlinesRun = ({ fill, stroke, size }: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M34.383,28.41c0.149,0.102,0.361,0.075,0.473-0.069
	c0.092-0.11,0.092-0.263,0.003-0.374l-1.793-2.288l-1.765-2.121l-0.874-0.997l-0.868-0.957l-1.284-1.359l-1.272-1.265l-1.262-1.168
	l-1.241-1.079l-1.404-1.114l-1.384-0.996l-0.688-0.45l-0.681-0.419l-1.077-0.609l-1.062-0.529l-1.053-0.455l-1.04-0.372
	l-1.026-0.298l-0.565-0.132l-0.575-0.108l-0.585-0.087l-0.596-0.063l-0.907-0.049l-0.931,0.005l-0.952,0.061l-0.959,0.119
	l-1.11,0.201l-1.109,0.276l-1.098,0.345L4.624,12.47L3.59,12.959l-0.512,0.278l-0.495,0.298l-0.454,0.29l-0.434,0.311
	c-0.51,0.385-0.595,1.079-0.19,1.562c0.196,0.229,0.48,0.38,0.791,0.411v-0.004l1.663,0.191l1.563,0.207l1.214,0.179l1.176,0.196
	l1.729,0.338l1.754,0.403l1.845,0.498l1.12,0.336l1.171,0.373l1.177,0.398l1.229,0.44l1.74,0.669l1.806,0.743l1.824,0.819
	l0.994,0.479l1.015,0.512l1.087,0.579l1.111,0.612l2.21,1.306l2.288,1.441l2.363,1.582"
      />
    </Svg>
  );
};
/* eslint-enable max-len */
