/** @jsxImportSource @emotion/react */
import { css, CSSObject } from '@emotion/react';
import React, { forwardRef, PropsWithChildren, ReactElement } from 'react';

type TRow = PropsWithChildren & {
  style?: CSSObject;
  display?: string;
  columns?: number | 'auto-fill' | 'auto-fit';
  columnWidth?: string;
  columnStart?: number;
  columnEnd?: number;
  rowStart?: number;
  rowEnd?: number;
  columnGap?: string;
  gridAutoFlow?: string;
  rowGap?: string;
  justifyContent?: string;
  justifyItems?: string;
  textAlign?: undefined;
  alignItems?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  id?: string;
  [otherProps: string]: any;
};

const Row = forwardRef<HTMLDivElement | null, TRow>((props, ref) => {
  const {
    style = {},
    display = 'grid',
    columns = 1,
    columnWidth = 'auto',
    columnStart = undefined,
    columnEnd = undefined,
    rowStart = undefined,
    rowEnd = undefined,
    columnGap = '0.25rem',
    gridAutoFlow = undefined,
    rowGap = '0.25rem',
    justifyContent = 'grid' === display ? undefined : 'center',
    justifyItems = 'grid' === display ? undefined : 'center',
    textAlign = 'center',
    alignItems = 'center',
    height = undefined,
    ...otherProps
  } = props;

  const newStyle: CSSObject = {
    display,
    columnGap,
    rowGap,
    justifyContent,
    justifyItems,
    textAlign,
    gridAutoFlow: 'grid' !== display ? undefined : gridAutoFlow,
    gridColumnStart: 'grid' !== display ? undefined : columnStart,
    gridColumnEnd: 'grid' !== display ? undefined : columnEnd,
    gridRowStart: 'grid' !== display ? undefined : rowStart,
    gridRowEnd: 'grid' !== display ? undefined : rowEnd,
    gridTemplateRows: 'grid' !== display ? undefined : 'auto',
    gridTemplateColumns: display === 'grid' ? `repeat( ${columns}, ${columnWidth} )` : undefined,
    height: height || 'inherit',
    width: '100%',
    alignItems: alignItems,
    ...style,
  };

  const rowStyle = css(newStyle);

  return <div ref={ref} {...otherProps} css={rowStyle} />;
});
Row.displayName = 'Row';
export default Row;

export function FormRow({
  columnWidth = 'minmax(75px, 1fr) minmax(150px, 3fr)',
  columns = 'auto-fit',
  columnGap = '1.875em',
  alignItems = 'end',
  justifyItems = 'stretch',
  children,
  className = '',
  style,
  ...rest
}: TRow): ReactElement {
  return (
    <Row
      {...{ columnWidth, columns, columnGap, alignItems, justifyItems }}
      {...rest}
      className={'cr-w-full sm:cr-w-3/4 md:cr-w-6/12 ' + className}
      style={{
        gridAutoFlow: 'row',
        gridTemplateRows: '1fr',
        '@media all and (max-width: 480px)': { gridTemplateColumns: 'unset' },
        ...style,
      }}
    >
      {children}
    </Row>
  );
}
