export default function setUrlSearchParams(query?: Record<string, any>): string {
  return query
    ? Object.keys(query ?? {}).reduce((acc, key, index) => {
        if (query[key] === undefined || query[key] === null) {
          return acc;
        }
        return acc + (index > 0 ? '&' : '') + key + '=' + query[key];
      }, '?')
    : '';
}
