import { Amplify } from 'aws-amplify';
import cognito from './config/client_cognito.json' assert { type: 'json' };
// import dotenv from 'dotenv';
// dotenv.config();

Amplify.configure({
  Auth: {
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',
    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },
    Cognito: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: '', //'eu-west-2:62461d80-2c4f-4603-8ff6-850f8c995e5a',

      // REQUIRED - Amazon Cognito Region
      // region: cognito.region,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'eu-west-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: cognito.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: cognito.userPoolWebClientId,

      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: false,

      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      //   cookieStorage: null /*{
      //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //   //domain: '.yourdomain.com',
      //   domain: import.meta.env..REACT_APP_AWS_COOKIE_DOMAIN ? import.meta.env..REACT_APP_AWS_COOKIE_DOMAIN : 'code8.link',
      //   // OPTIONAL - Cookie path
      //   path: '/',
      //   // OPTIONAL - Cookie expiration in days
      //   expires: 365,
      //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //   sameSite: 'lax',
      //   // OPTIONAL - Cookie secure flag
      //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //   secure: false
      // }*/,
      // OPTIONAL - customized storage object
      // storage: 'MyStorage',
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          //domain: 'https://www.velosense.com',
          domain: import.meta.env.VITE_APP_AWS_DOMAIN
            ? import.meta.env.VITE_APP_AWS_DOMAIN
            : 'https://velosense.code8.link',
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: ['https://velosense.code8.link'],
          redirectSignOut: ['https://velosense.code8.link'],
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
});

// You can get the current config object
const currentConfig = Amplify.getConfig();

export default currentConfig;
