import { Dispatch, useContext } from 'react';
import { TestSessionsContext } from './provider';
import { TTestSessionContext } from './_types';
import { validate } from '../utils/validate';

function useSessions(): [TTestSessionContext, Dispatch<any>, any] {
  return validate<[TTestSessionContext, Dispatch<any>, any]>(useSessions.name, useContext(TestSessionsContext));
}

export { useSessions };
