/** @jsxImportSource @emotion/react */
import React, { ReactElement, memo } from 'react';
import Row from '../../../layouts/Row';
import LineDivider from '../../../layouts/LineDivider';
import { TListViewProps } from '../types';
import { css } from '@emotion/react';
import { useListView } from '../useListView';
import { hiddenClassName } from '../../../utils/tailwind';

type TListItemProps = {
  item: NonNullable<TListViewProps['list']>[number];
  index: number;
  isHidden?: boolean | null;
  noBottomLine?: boolean;
  edit?: ReactElement;
};

function ListItem({ item, noBottomLine, isHidden, index: RowIndex }: TListItemProps): ReactElement {
  if (typeof item === 'undefined') return <>null</>;
  const {
    columns,
    listClassName,
    dataTip,
    onClick,
    isSelected,
    listCellRender,
    list,
    columnWidths,
    editRow,
    icon,
    selectAll,
    noSeparation,
    selectedCount,
  } = useListView();
  const selected = isSelected && item && isSelected(item);
  const InLineRowEdit = editRow && item ? editRow(item, RowIndex) : undefined;

  // eslint-disable-next-line max-len
  const editRowClassName = `${RowIndex % 2 ? 'cr-bg-blue-100 cr-border-blue-500' : 'cr-bg-green-100 cr-border-green-500'} ${noSeparation ? 'cr-my-2.5' : 'cr-my-1'} cr-border-solid cr-border-2 cr-rounded-md`;
  const rowStyle = InLineRowEdit
    ? {
        borderRadius: InLineRowEdit ? '5px' : undefined,
        padding: InLineRowEdit ? '0.675em 0.375em' : undefined,
        height: 'auto',
        width: InLineRowEdit ? '100%' : undefined,
        minHeight: '3em',
        color: item.color ?? 'inherit',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'start',
      }
    : {
        backgroundColor: selected ? '#daeade' : 'inherit',
        fontWeight: selected ? 500 : 'inherit',
        height: 'auto',
        minHeight: '3em',
        color: selected ? '#2a4d32' : item.color ?? 'inherit',
        gridAutoFlow: 'column',
        gridTemplateColumns: columnWidths({ columns, withIcon: !!icon, withSelectAll: selectAll }),
      };

  const cellStyle = css({
    backgroundColor: 'inherit',
    color: 'inherit',
    display: 'grid',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    '>*': {
      margin: 'auto 0',
    },
  });

  const rowOnClick = (label: string, render?: CallableFunction): undefined | void => {
    if (label === '_btn_edit' || render) {
      return undefined;
    }
    return onClick ? onClick(item) : undefined;
  };

  return (
    <>
      <Row
        id={`${item?.id}`}
        className={`row-h ${listClassName ?? ''} ${InLineRowEdit ? editRowClassName : ''}` + hiddenClassName(isHidden)}
        data-tip={dataTip}
        display={InLineRowEdit ? 'flex' : undefined}
        justifyItems={'start'}
        style={rowStyle}
        data-testid={`list-item-${RowIndex}`}
      >
        {onClick && selectAll ? (
          <div className={'cr-relative'} onClick={(): void => onClick(item)}>
            {selected && (
              <span
                className={
                  'cr-absolute cr-h-max cr-w-max cr-min-w-[14px] cr-rounded-full cr-bg-blue-900 cr-text-[14px] cr-font-semibold cr-leading-[14px] cr-text-blue-100'
                }
              >
                {selectedCount}
              </span>
            )}
            <input readOnly type="checkbox" checked={selected} style={{ zoom: 1.5 }} />
          </div>
        ) : null}
        {!!icon && (
          <div onClick={(): void => (onClick ? onClick(item) : undefined)}>
            {typeof icon === 'function' ? icon(item) : icon}
          </div>
        )}
        {!InLineRowEdit
          ? columns.map(({ label, param, roundTo, render }, index) => {
              const key = `item_${item?.id}_cell_${param}_${label}_${index}`;
              const value = (): any => {
                return render ? (
                  <React.Fragment key={key}>
                    {render({ onClick: onClick ? (): void => onClick(item) : undefined, item, list, index: RowIndex })}
                  </React.Fragment>
                ) : (
                  listCellRender?.({
                    item,
                    index: RowIndex,
                    param,
                    roundTo,
                    label,
                    onClick: (): void => (onClick ? onClick(item) : undefined),
                  })[param]?.(key) ??
                    listCellRender?.({
                      item,
                      index: RowIndex,
                      param,
                      roundTo,
                      label,
                      onClick: (): void => (onClick ? onClick(item) : undefined),
                    }).default?.(key)
                );
              };

              return (
                <div key={key} onClick={() => rowOnClick(label, render)} css={cellStyle}>
                  {value()}
                </div>
              );
            })
          : InLineRowEdit}
      </Row>
      {!noBottomLine && !noSeparation ? <LineDivider /> : null}
    </>
  );
}

const ListItemWithMemo = memo(ListItem);
export default ListItemWithMemo;
