import Chevron from '../../icons/Chevron';
import Button from '../SimpleButton';
import { CSSProperties, ReactElement } from 'react';

const rotate = (direction: string | undefined, isAction: boolean): string | undefined => {
  switch (direction) {
    case 'down':
      return isAction ? 'rotate(180deg)' : undefined;
    case 'right':
      return isAction ? 'rotate(90deg)' : 'rotate(90deg)';
    default:
      return isAction ? 'rotate(180deg)' : undefined;
  }
};

export type TAccordionButtonProps = {
  icon?: ReactElement;
  on: boolean;
  order: CSSProperties['order'];
  padding: CSSProperties['padding'];
  direction?: 'down' | 'right' | string;
  onClick?: (index?: number | undefined) => void;
};

const AccordionButton = ({ on, order, padding, direction, icon, onClick }: TAccordionButtonProps): ReactElement => {
  return (
    <Button
      onClick={onClick}
      style={{
        order,
        padding,
        backgroundColor: 'transparent',
        border: 'none',
        transform: on && !icon ? rotate(direction, true) : undefined,
      }}
    >
      {icon ?? <Chevron transform={direction ? rotate(direction, false) : undefined} />}
    </Button>
  );
};

export default AccordionButton;
