import { TRunsContext } from './_types';
import { TRun } from '../../common/types/run';
import { RunStatus } from '../../common/types/enums';
import { KEEP_UNCHANGED } from '../../const';

export const getSelectedRuns = (state: TRunsContext): any[] =>
  state.selectedRuns.reduce((acc: any[], id) => {
    if (state.runsById[id]) {
      acc.push(state.runsById[id]);
    }
    return acc;
  }, []);

export const getDrawerState = (drawerName: string, drawers: TRunsContext['drawers']): string[] =>
  drawers[drawerName] ?? [];

export const getRunsWithData = <T extends boolean>(
  runIds: string[],
  runsById: Record<string, TRun>,
  idsOnly?: T,
): T extends true ? string[] : TRun[] =>
  runIds.reduce((acc: any, runId: string) => {
    const runStatus = runsById[runId]?.run_status ?? RunStatus.CREATED;
    return runStatus >= RunStatus.PROCESSED_SUCCESS
      ? idsOnly
        ? [...acc, runsById[runId].id]
        : [...acc, runsById[runId]]
      : acc;
  }, []);

export const getRunsByCalibrationId = (runs: TRunsContext['runs']) => {
  return (calibrationId: string): TRunsContext['runs'] =>
    runs.filter((run) => Object.values(run).includes(calibrationId));
};

export const getRunId = (run: Record<string, any>): string => run.id;
export const getRunIds = (runs: any[]): string[] => runs.map(getRunId);

export const getGroupsRuns = (selectedRuns: any[], onlySelected?: any[]): any[] => {
  return selectedRuns.reduce((acc, selectedRun) => {
    return acc.concat(
      selectedRun.groups_runs.filter((groupRun: any) =>
        onlySelected
          ? !acc.includes(groupRun.group_id) && onlySelected.includes(groupRun.group_id)
          : !acc.includes(groupRun.group_id),
      ),
    );
  }, []);
};

export const selectedRunsReducer = (acc: Record<string, any>, run: Record<string, any>): Record<string, any> => {
  if (run) {
    Object.keys(run).forEach((key) => {
      acc[key] = acc[key] === run[key] || !acc[key] ? run[key] : KEEP_UNCHANGED;
    });
    acc.count++;
  }
  return acc;
};
