import { ReactElement } from 'react';

type TReadMoreProps = {
  readMoreText?: string;
  readLessText?: string;
  toggle: () => void;
  on: boolean;
};

const ReadMore = ({
  readMoreText = 'Read More',
  readLessText = 'Show Less',
  toggle,
  on,
}: TReadMoreProps): ReactElement => {
  return (
    <>
      {!on ? (
        <span
          className={'btn-link'}
          role={'button'}
          onClick={(): void => toggle()}
          tabIndex={0}
          onKeyDown={(): void => toggle()}
        >
          {readMoreText}
        </span>
      ) : null}
      {on ? (
        <span
          className={'btn-link'}
          role={'button'}
          onClick={(): void => toggle()}
          tabIndex={0}
          onKeyDown={(): void => toggle()}
        >
          {readLessText}
        </span>
      ) : null}
    </>
  );
};

export default ReadMore;
