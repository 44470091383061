/** @jsxImportSource @emotion/react */
import { css, CSSObject } from '@emotion/react';
import { ReactNode, ReactElement, forwardRef } from 'react';

type TGrid = {
  style?: CSSObject;
  columns?: number;
  columnWidth?: string;
  columnGap?: string;
  rows?: number;
  rowHeight?: string;
  rowGap?: string;
  justifyItems?: string;
  children?: ReactNode | ReactNode[];
  gridTemplateAreas?: CSSObject['gridTemplateAreas'];
  [key: string]: any;
};

const Grid = forwardRef<HTMLDivElement | null, TGrid>((props: TGrid, ref): ReactElement => {
  const {
    style,
    columns = 1,
    columnWidth = '1fr',
    columnGap = '0.25rem',
    rows = 1,
    rowHeight = 'auto',
    rowGap = '0.25rem',
    justifyItems = 'center',
    gridTemplateAreas,
    ...otherProps
  } = props;

  const newStyle: CSSObject = {
    display: 'grid',
    gridTemplateColumns: gridTemplateAreas ? undefined : `repeat( ${columns}, ${columnWidth} )`,
    gridTemplateRows: gridTemplateAreas ? undefined : `repeat( ${rows}, ${rowHeight} )`,
    columnGap,
    rowGap,
    width: '100%',
    justifyItems,
    gridTemplateAreas,
    ...style,
  };

  const gridStyle = css({ ...newStyle });

  return <div ref={ref} css={gridStyle} {...otherProps} />;
});
Grid.displayName = 'Grid';

export default Grid;
