import { editRowClassName, editRowToggleClassName } from '../../../../utils/tailwind';
import { ListViewCell, ListViewEditRow } from '../../../../components/ListView';
import Row from '../../../../layouts/Row';
import { Outlet } from 'react-router-dom';

type Props = {
  itemId: string | number;
  index: number;
  className?: string;
  show: boolean;
  handleCdaParamChange: CallableFunction;
  cdaParam: any;
};

export default function RunViewRow({ cdaParam, handleCdaParamChange, ...props }: Props) {
  return (
    <ListViewEditRow rowIndex={props.index} className={props.className + editRowToggleClassName(props.show)}>
      <ListViewCell colSpan={10}>
        <div className={editRowClassName('blue') + props.className + ' cr-bg-white'}>
          <Row columnWidth={'min-content 1fr'} alignItems={'start'}>
            <h5
              className={'cr-w-0 sm:cr-w-min'}
              style={{
                writingMode: 'vertical-lr',
                transform: 'rotate(180deg)',
                padding: '0 0 0.575rem 0.375rem',
              }}
            >
              Config Runs
            </h5>
            {props.show && <Outlet context={[props.itemId, { handleCdaParamChange, cdaParam }]} />}
          </Row>
        </div>
      </ListViewCell>
    </ListViewEditRow>
  );
}
