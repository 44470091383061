import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';
/* eslint-disable  max-len */
export const StreamlinesSession = ({ fill, stroke, size }: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M25.066,5.771c0.063,0.017,0.134-0.014,0.151-0.075
			c0.023-0.048,0.008-0.1-0.037-0.128l-0.864-0.571l-0.838-0.52l-0.413-0.241l-0.401-0.224l-0.592-0.315l-0.57-0.285l-0.567-0.253
			L20.39,2.933l-0.601-0.219l-0.585-0.184l-0.285-0.075l-0.282-0.065l-0.437-0.086L17.78,2.243L17.37,2.21L16.972,2.2l-0.384,0.014
			l-0.21,0.019l-0.208,0.028l-0.21,0.035l-0.214,0.046l-0.314,0.084l-0.322,0.108l-0.321,0.125L14.47,2.807l-0.359,0.189
			l-0.353,0.218l-0.339,0.241L13.094,3.71L12.792,3.99l-0.146,0.151l-0.137,0.156l-0.124,0.149L12.27,4.598
			c-0.134,0.186-0.086,0.431,0.105,0.547c0.09,0.056,0.205,0.074,0.316,0.051V5.195l0.593-0.123l0.561-0.104l0.437-0.075
			l0.426-0.063l0.63-0.081l0.648-0.058l0.689-0.036l0.42-0.012l0.445-0.005l0.447,0.004l0.469,0.014l0.673,0.029l0.702,0.049
			l0.717,0.075l0.394,0.05l0.407,0.06l0.439,0.075l0.448,0.083l0.901,0.194L24.085,5.5L25.066,5.771"
      />
      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M11.561,6.354c-0.01-0.059-0.074-0.101-0.14-0.086
			c-0.053,0.012-0.094,0.051-0.101,0.101L11.182,7.38L11.079,8.34l-0.038,0.462L11.014,9.25L10.988,9.9l-0.004,0.624l0.018,0.591
			l0.037,0.565l0.068,0.609l0.095,0.573l0.059,0.271l0.066,0.266l0.118,0.401l0.137,0.376l0.152,0.353l0.172,0.33l0.187,0.307
			l0.11,0.159l0.121,0.157l0.13,0.154l0.135,0.146l0.225,0.214l0.243,0.206l0.262,0.192l0.28,0.178l0.342,0.19l0.359,0.172
			l0.377,0.145l0.387,0.127l0.396,0.095l0.207,0.036l0.208,0.029l0.196,0.021l0.193,0.017c0.234,0.008,0.439-0.161,0.46-0.38
			c0.008-0.105-0.025-0.208-0.096-0.284h0l-0.378-0.417l-0.352-0.398l-0.267-0.316l-0.255-0.312l-0.358-0.469l-0.347-0.495
			l-0.348-0.542l-0.201-0.339l-0.203-0.357l-0.201-0.367l-0.201-0.39l-0.276-0.562l-0.272-0.599l-0.256-0.625l-0.132-0.347
			l-0.125-0.362l-0.131-0.395l-0.125-0.413l-0.229-0.838L11.755,7.3L11.561,6.354"
      />
      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M18.477,17.011c-0.051,0.045-0.055,0.115-0.016,0.162
			c0.037,0.037,0.092,0.047,0.144,0.023l0.993-0.445l0.93-0.449l0.443-0.224l0.427-0.227l0.611-0.34l0.573-0.34l0.535-0.342
			l0.501-0.342l0.527-0.395l0.482-0.394l0.224-0.198l0.211-0.196l0.309-0.316l0.287-0.319l0.25-0.318l0.225-0.319l0.19-0.323
			l0.101-0.176l0.085-0.184l0.08-0.189l0.071-0.19l0.093-0.299l0.072-0.306l0.056-0.317l0.031-0.327l0.014-0.379l-0.01-0.384
			l-0.039-0.385l-0.069-0.38L26.709,7.82L26.648,7.63l-0.073-0.181l-0.074-0.168l-0.083-0.163c-0.103-0.192-0.352-0.268-0.564-0.163
			c-0.103,0.05-0.179,0.134-0.217,0.234v0.001l-0.202,0.537L25.233,8.23l-0.165,0.39l-0.171,0.378l-0.26,0.549L24.343,10.1
			l-0.333,0.576l-0.214,0.351L23.56,11.39l-0.244,0.364l-0.262,0.376l-0.394,0.534l-0.421,0.548l-0.45,0.552l-0.262,0.299
			l-0.27,0.301l-0.304,0.322l-0.316,0.328l-0.664,0.65l-0.726,0.662l-0.779,0.682"
      />

      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M13.486,19.357c0.066-0.008,0.117-0.062,0.113-0.127
			c0.001-0.052-0.031-0.093-0.083-0.103l-1.019-0.197l-0.973-0.157l-0.473-0.064l-0.459-0.054l-0.665-0.064l-0.643-0.044
			L8.67,18.529H8.08l-0.642,0.025L6.83,18.61l-0.292,0.043l-0.284,0.048l-0.436,0.088l-0.413,0.106l-0.394,0.122l-0.372,0.146
			l-0.35,0.161l-0.188,0.095l-0.183,0.104l-0.18,0.114l-0.18,0.126L3.301,19.96l-0.255,0.221l-0.247,0.241l-0.237,0.254l-0.26,0.315
			l-0.242,0.334L1.837,21.68l-0.201,0.363l-0.172,0.372L1.388,22.61l-0.068,0.194L1.262,22.99l-0.048,0.185
			c-0.053,0.227,0.085,0.431,0.307,0.464c0.105,0.02,0.219-0.008,0.312-0.07v-0.002l0.502-0.343l0.478-0.307l0.374-0.24l0.369-0.222
			l0.552-0.315l0.575-0.301l0.622-0.297l0.384-0.174l0.409-0.175l0.413-0.167l0.439-0.169l0.633-0.229L8.251,20.4l0.69-0.202
			l0.385-0.104l0.397-0.101l0.432-0.101l0.445-0.092l0.907-0.167l0.962-0.146L13.486,19.357"
      />
      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M1.233,25.073c-0.033-0.051-0.106-0.062-0.162-0.025
			c-0.045,0.025-0.066,0.08-0.054,0.132l0.26,0.987l0.274,0.931l0.142,0.44l0.146,0.426l0.225,0.607l0.233,0.574l0.244,0.539
			l0.249,0.51l0.298,0.535l0.307,0.492l0.16,0.231l0.162,0.219l0.262,0.325l0.27,0.295l0.276,0.27l0.285,0.24l0.289,0.21
			l0.163,0.106l0.173,0.097l0.178,0.094l0.18,0.081l0.29,0.11l0.302,0.096l0.317,0.083l0.328,0.058l0.387,0.045l0.396,0.02
			l0.404-0.008l0.406-0.033l0.405-0.062l0.204-0.047L9.434,33.6l0.191-0.055l0.186-0.062c0.217-0.079,0.344-0.317,0.28-0.527
			c-0.031-0.099-0.103-0.184-0.198-0.227v0.002l-0.51-0.242l-0.478-0.236l-0.367-0.188l-0.355-0.19l-0.512-0.3l-0.51-0.324
			l-0.529-0.366l-0.315-0.233l-0.326-0.254l-0.326-0.26l-0.335-0.285l-0.47-0.411l-0.48-0.451l-0.475-0.476l-0.255-0.27l-0.253-0.29
			l-0.273-0.311l-0.274-0.336l-0.533-0.685L1.777,25.88L1.233,25.073"
      />
      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M11.706,32.267c-0.03,0.061-0.007,0.128,0.049,0.151
			c0.047,0.023,0.103,0.01,0.14-0.03l0.747-0.792l0.688-0.77l0.324-0.38l0.305-0.372l0.436-0.545l0.398-0.531l0.365-0.523
			l0.332-0.505l0.336-0.569l0.295-0.549l0.134-0.268l0.118-0.266l0.167-0.407l0.138-0.407l0.111-0.39l0.084-0.382l0.055-0.373
			l0.02-0.195l0.01-0.204l0.003-0.203l-0.01-0.206l-0.027-0.306l-0.054-0.312l-0.07-0.314l-0.096-0.313l-0.127-0.361l-0.158-0.351
			l-0.185-0.34l-0.21-0.327l-0.233-0.305l-0.129-0.146l-0.136-0.142l-0.133-0.13l-0.136-0.118c-0.168-0.14-0.431-0.114-0.583,0.064
			c-0.076,0.083-0.115,0.191-0.111,0.298l0.001,0.002l0.017,0.574l0.005,0.541l-0.002,0.423l-0.012,0.411l-0.034,0.606l-0.058,0.625
			l-0.084,0.661l-0.065,0.403l-0.075,0.427l-0.086,0.432l-0.098,0.45l-0.156,0.642l-0.181,0.671l-0.206,0.681l-0.126,0.376
			l-0.135,0.383l-0.156,0.414l-0.168,0.421l-0.366,0.855l-0.41,0.894l-0.459,0.925"
      />

      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M22.932,19.279l1.014,0.13l0.962,0.146l0.907,0.169
			l0.446,0.093l0.431,0.099l0.397,0.1l0.386,0.104l0.688,0.207l0.669,0.224l0.632,0.229l0.44,0.167l0.411,0.174l0.405,0.175
			l0.386,0.169l0.626,0.299l0.574,0.302l0.553,0.316l0.366,0.223l0.376,0.235l0.478,0.312l0.502,0.34h-0.002
			c0.095,0.063,0.206,0.091,0.312,0.075c0.219-0.033,0.356-0.244,0.305-0.465l-0.049-0.185l-0.055-0.187l-0.071-0.195l-0.076-0.194
			l-0.174-0.374l-0.198-0.364l-0.221-0.35l-0.246-0.337l-0.258-0.312l-0.237-0.258l-0.246-0.238l-0.256-0.221l-0.254-0.2
			l-0.183-0.124l-0.182-0.114l-0.183-0.104l-0.188-0.096l-0.351-0.162l-0.372-0.146l-0.396-0.122l-0.411-0.105l-0.437-0.087
			l-0.283-0.047l-0.293-0.042L28.97,18.48l-0.644-0.029h-0.588l-0.616,0.02l-0.641,0.043l-0.667,0.065l-0.459,0.053l-0.472,0.065
			l-0.975,0.159l-1.017,0.192c-0.051,0.012-0.083,0.05-0.081,0.103C22.815,19.214,22.867,19.271,22.932,19.279"
      />
      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M35.185,24.994l-0.545,0.803l-0.537,0.74l-0.531,0.687
			l-0.275,0.333l-0.273,0.314l-0.25,0.285l-0.257,0.271l-0.473,0.479l-0.482,0.448l-0.468,0.413l-0.337,0.285l-0.326,0.26
			l-0.326,0.252l-0.312,0.236l-0.529,0.361l-0.509,0.327l-0.516,0.301l-0.354,0.19l-0.37,0.191l-0.474,0.234l-0.512,0.241l0,0
			c-0.097,0.04-0.169,0.126-0.197,0.225c-0.066,0.211,0.062,0.446,0.282,0.529l0.185,0.061l0.19,0.058l0.199,0.051l0.204,0.046
			l0.405,0.062l0.405,0.031l0.407,0.008l0.397-0.021l0.39-0.045l0.327-0.058l0.316-0.078l0.299-0.099l0.291-0.11l0.182-0.082
			l0.181-0.093l0.171-0.097l0.166-0.105l0.289-0.211l0.285-0.24l0.275-0.27l0.27-0.297l0.262-0.324l0.161-0.219l0.159-0.23
			l0.308-0.489l0.297-0.537l0.248-0.508l0.246-0.539l0.235-0.577l0.225-0.607l0.146-0.427l0.14-0.441l0.275-0.926l0.25-0.986
			c0.012-0.049-0.01-0.104-0.053-0.136C35.289,24.93,35.219,24.943,35.185,24.994"
      />
      <path
        stroke={stroke ?? 'currentColor'}
        fill={fill ?? 'currentColor'}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M24.71,32.184l-0.46-0.927l-0.411-0.89l-0.364-0.854
			l-0.169-0.425l-0.157-0.411l-0.136-0.384l-0.126-0.374l-0.205-0.685l-0.183-0.669l-0.153-0.642l-0.099-0.448l-0.085-0.431
			l-0.078-0.427l-0.063-0.405l-0.084-0.663l-0.06-0.624l-0.033-0.605l-0.012-0.416l-0.004-0.421l0.006-0.543l0.018-0.57l0.004-0.002
			c0.002-0.108-0.039-0.215-0.112-0.301c-0.153-0.176-0.415-0.201-0.584-0.063l-0.134,0.118l-0.136,0.132l-0.134,0.138l-0.13,0.149
			l-0.236,0.306l-0.208,0.328l-0.187,0.339l-0.155,0.35l-0.134,0.356l-0.095,0.312l-0.068,0.312l-0.055,0.313l-0.028,0.307
			l-0.008,0.207l0.004,0.2l0.01,0.205l0.019,0.198l0.057,0.372l0.083,0.382l0.111,0.392l0.136,0.403l0.17,0.411l0.121,0.264
			l0.132,0.27l0.295,0.549l0.338,0.564l0.333,0.506l0.361,0.525l0.399,0.533l0.436,0.543l0.305,0.371l0.324,0.38l0.687,0.77
			l0.746,0.789c0.037,0.043,0.095,0.055,0.14,0.033c0.057-0.025,0.079-0.093,0.047-0.153"
      />
    </Svg>
  );
};
