import { useTheme, View, Image, Text, Heading, Button, useAuthenticator } from '@aws-amplify/ui-react';
import logo from 'icons/logo-streamlines.png';

export const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Streamlines logo" src={logo} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      return (
        <>
          <h1 className={'font-forma cr-text-center cr-text-[2rem] cr-underline'}>
            <span>CIRRUS</span> Portal
          </h1>
          <div className="headline cr-pt-4" data-testid={'headline'}>
            <h2>Sign in to your account</h2>
          </div>
        </>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
            Forgotten Password
          </Button>
        </View>
      );
    },
  },
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      const params = `subject=${encodeURIComponent('CIRRUS: Logging problems!')}`;
      return (
        <Text>
          If you have trouble logging in contact{' '}
          <a href={`mailto:info@streamlines.aero?${params}`}>info@streamlines.aero</a>
        </Text>
      );
    },
  },
};
