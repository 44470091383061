import { TConfig } from '../../common/types/config';

export type TConfigsContext = {
  configs: any[];
  newConfigs: TConfig[];
  calibrations: any[];
  calibrationsById: Record<string, any>;
  calibrationGates: Record<string, any>;
  selectedCalibrationId: string[];
  isLoading: boolean;
  isNewCalibration?: string;
};

export enum CalibrationActionTypes {
  SET_DATA = 'SET_DATA',
  SET_ALL_CALIBRATIONS = 'SET_ALL_CALIBRATIONS',
  SET_NEW_CALIBRATION = 'SET_NEW_CALIBRATION',
  SET_CALIBRATIONS = 'SET_CALIBRATIONS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  CLEAR_EDITING_CALIBRATIONS = 'CLEAR_EDITING_CALIBRATIONS',
  SET_SELECTED_CALIBRATIONS = 'SET_SELECTED_CALIBRATIONS',
}
