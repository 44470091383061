/** @jsxImportSource @emotion/react */
import { ReactElement, useCallback, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useConfigs } from '../../../stores/configs/useConfigs';
import Column from '../../../layouts/Column';
import { sortSessionRuns, useRuns, useSessionRuns } from '../../../stores/runs/useRuns';
import ListView, { ListViewBody } from '../../../components/ListView';
import { defaultRun, TRun } from '../../../common/types/run';
import { ListWarning } from './ListWarnings';
import moment from 'moment/moment';
import RunListWarnings from './RunListWarnings';
import SimpleModal from '../../../components/SimpleModal';
import useGetCalcDataList from '../../../components/InteractivePlotter/data/fetching/useGetCalcDataList';
import { runColumns } from './TestSessionRuns/columns';
import HeaderRow from './TestSessionRuns/HeaderRow';
import ReadRow from './TestSessionRuns/ReadRow';
import { componentKey } from '../../../utils/componentKeyGen';
import { updateRuns } from '../../../stores/ajax/updateRuns';
import { updateGroupRuns } from '../../../stores/ajax/updateGroupRuns';
import { useSessions } from '../../../stores/sessions/useSessions';
import { sortRunsByNumber } from '../../../common/classes/RunList';

export default function SessionRunsList(): ReactElement {
  const [configUuid = '', { handleCdaParamChange, cdaParam }] = useOutletContext<[string, Record<string, any>]>();
  const [{ runsById, isLoading: runsLoading, showHidden }, , refreshRuns] = useRuns();
  const [{ configsById }, , refreshConfigs] = useConfigs();
  const [{ byId }] = useSessions();
  const { sessionUuid = '' } = useParams();
  const SessionRuns = useSessionRuns(sessionUuid);
  const [calcDataList] = useGetCalcDataList(sessionUuid, { only: 'configs' });
  const SessionLocked = byId[sessionUuid]?.status === 2000;
  let hasNewRuns = false;

  const onAddNewRun = useCallback(async () => {
    if (SessionLocked || !SessionRuns.last) return undefined;
    const newRun = defaultRun({
      rider_id: SessionRuns.last.rider_id,
      description: `${SessionRuns.last.description}`,
      calibration: SessionRuns.last.calibration,
      run_number: SessionRuns.newRunNumber,
      run_ts: moment().add(5, 'minutes').format(),
    });

    const savedNewRuns = await updateRuns({
      verb: 'POST',
      runs: [newRun],
    });
    if (configUuid && !savedNewRuns?.[0].errors) {
      await updateGroupRuns({
        groupId: [configUuid],
        addRuns: savedNewRuns.map(({ id }) => id),
        removeGroupRuns: [],
      });
    }

    await refreshRuns();
    await refreshConfigs();
  }, [SessionRuns]);

  const ConfigRunList: (TRun & { listWarning: ListWarning })[] = useMemo(
    () =>
      runsById
        ? configsById[configUuid].run_ids.reduce((acc: any, runId: string) => {
            if (!hasNewRuns && runsById[runId]?.run_status === 1000) {
              hasNewRuns = true;
            }
            return runsById[runId] && (!runsById[runId]?.hidden || showHidden) ? [...acc, runsById[runId]] : acc;
          }, [])
        : [],
    [runsById, runsLoading, configsById, configUuid],
  );

  ConfigRunList.sort(sortRunsByNumber);
  const printList = RunListWarnings<TRun>(ConfigRunList).map(
    (
      run: any,
    ): TRun & { listWarning: ListWarning } & {
      average: Record<string, number[]>;
      deltas: (param: string) => number;
    } => {
      if (calcDataList) {
        const calcRun = calcDataList[configUuid].runs.find(({ id }: any) => run.id === id);
        const baseline = calcDataList[configUuid].baseline;
        run.average = calcRun && calcRun.average ? calcRun.average : {};
        run.deltas = (param: string) =>
          baseline &&
          calcRun &&
          typeof calcRun.average[param]?.[0] === 'number' &&
          typeof calcDataList[baseline].average[param]?.[0] === 'number'
            ? calcRun.average[param][0] - calcDataList[baseline].average[param][0]
            : null;
      }

      return run;
    },
  );

  return (
    <>
      {configUuid && (
        <Column data-testid={'session-runs'}>
          {printList.length ? (
            <>
              <ListView columns={runColumns} sort={sortSessionRuns} onAddLabel={'Add Planned Run'} onAdd={onAddNewRun}>
                <HeaderRow />
                <ListViewBody
                  className={'cr-border-b cr-border-solid cr-border-b-blue-900 [&>td]:cr-text-left'}
                  render={({ dataItem, onClick, className, index }) => [
                    <ReadRow
                      key={componentKey('tr-run-data', dataItem.id)}
                      dataItem={dataItem}
                      index={index}
                      onClick={onClick}
                      className={className}
                      hasNewRuns={hasNewRuns}
                      {...{ handleCdaParamChange, cdaParam }}
                    />,
                  ]}
                  data={printList}
                />
              </ListView>
            </>
          ) : (
            'No runs for this config'
          )}
          <SimpleModal />
        </Column>
      )}
    </>
  );
}
