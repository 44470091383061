export const colors = {
  aeroLightBlue: '#99E7F8B5',
  aeroBlue: '#1879B9', //
  aeroTextBlue: '#4cdffb',
  aeroDarkBlue: '#1879b9',
  aeroNavyBlue: '#03365D', //
  aeroGreen: '#00B566', //
  aeroTextGreen: '#40b465',
  aeroDarkGreen: '#228a76',
  aeroNavyGreen: '#176f80',
  aeroDark: '#0a0a0a',
  aeroGrey: '#f2f2f2',
  aeroDarkGrey: '#333333',
  aeroTextDarkGrey: '#545454',
  aeroTextGrey: '#c1c1c1',
  jumboLightYellow: '#fad576',
  jumboYellow: '#fbbd1a',
  jumboDarkYellow: '#8f6900',
  aeroLightRed: '#fcbdc4',
  aeroRed: '#dc3545',
  aeroDarkRed: '#a71d2a',
};
