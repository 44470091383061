import { Tooltip } from '@mui/material';
import { IconWrap } from '../../../../components/Nav';
import { StreamlinesCalibration } from '../../../../icons/StreamlinesCalibration';
import { ListWarning } from '../ListWarnings';
import { ReactNode } from 'react';

type Props<T> = {
  dataItem: T;
};

const toolTipTitle = (listWarning?: ListWarning): ReactNode => {
  const title = [];
  if (listWarning?.DIFFERENT_CALIBRATION) {
    title.push('DIFFERENT CALIBRATION in Configuration');
  }
  if (listWarning?.WARNING) {
    title.push('Parent config has DIFFERENT CALIBRATION ');
  }
  if (listWarning?.ERROR) {
    title.push('CALIBRATION is missing');
  }
  return (
    <>
      {title.map((message) => (
        <span key={message}>{message}</span>
      ))}
    </>
  );
};

export default function CalibrationStatusWarning<T extends { [key: string]: any }>({ dataItem }: Props<T>) {
  return dataItem.listWarning?.ERROR || dataItem.listWarning?.WARNING || dataItem.listWarning?.DIFFERENT_CALIBRATION ? (
    <Tooltip title={toolTipTitle(dataItem.listWarning)} sx={{ zIndex: '9999' }}>
      <IconWrap>
        <StreamlinesCalibration
          fill={dataItem.listWarning?.ERROR ? 'red' : dataItem.listWarning.DIFFERENT_CALIBRATION ? 'red' : undefined}
          stroke={dataItem.listWarning?.ERROR ? 'red' : dataItem.listWarning?.WARNING ? 'orange' : undefined}
        />
      </IconWrap>
    </Tooltip>
  ) : null;
}
