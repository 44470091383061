import { Dispatch, useContext } from 'react';
import { RidersContext } from './provider';
import { TRidersContext } from './_types';
import { validate } from '../utils/validate';

function useRiders(): [TRidersContext, Dispatch<any>, any] {
  return validate<[TRidersContext, Dispatch<any>, any]>(useRiders.name, useContext(RidersContext));
}

export { useRiders };
