import { LocalStorageKeys } from '../../common/interfaces';
import { createContext, Dispatch, ReactElement, useReducer } from 'react';
import { TBaseContext } from '../types';
import { TConfigsContext } from './_types';
import configsReducer from './reducer';
import usePrevious from '../../hooks/usePrevious';
import { LocalStorageHandler } from '../../utils/toLocalStorage';
import { useConfigsGet } from '../ajax/configs';

const defaultEditedConfig = (): string[] => {
  const editedConfigIdsFromLS = localStorage.getItem(LocalStorageKeys.EDITED_CONFIG);
  return editedConfigIdsFromLS ? JSON.parse(editedConfigIdsFromLS) : [];
};
const defaultState: TConfigsContext = {
  configs: [],
  // newConfigs: [],
  configsById: {},
  // newConfigsHasChanged: false,
  configsByTestSessionId: {},
  selectedConfigId: defaultEditedConfig(),
  currentIndexConfigId: 0,
  selectedCalibrationId: LocalStorageHandler.get(LocalStorageKeys.EDITED_CALIBRATION) || '',
  addRuns: [],
  removeRuns: [],
  isLoading: false,
  dataLoading: new Set(),
};
export const ConfigsContext = createContext<[TConfigsContext, Dispatch<any>, any, TConfigsContext | null]>([
  defaultState,
  (): void => undefined,
  null,
  null,
]);
ConfigsContext.displayName = 'ConfigsContext';

// preload(ConfigsFetcher.key, ConfigsFetcher.fetcher);
export default function ConfigsProvider({ children, ...props }: TBaseContext): ReactElement {
  const [state, dispatch] = useReducer(configsReducer, defaultState);
  const prevState = usePrevious(state);

  LocalStorageHandler.save(LocalStorageKeys.EDITED_CONFIG);

  const { mutate } = useConfigsGet(dispatch);

  return (
    <ConfigsContext.Provider value={[state, dispatch, mutate, prevState]} {...{ ...props }}>
      {children}
    </ConfigsContext.Provider>
  );
}
