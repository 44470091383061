import { ReactElement } from 'react';
import { TCirrusIconProps } from './types';
import Svg from './Svg';
/* eslint-disable max-len */
export const StreamlinesBroom = ({ fill, stroke, size }: TCirrusIconProps): ReactElement => {
  return (
    <Svg viewBox={[0, 0, 36, 36]} {...{ size }}>
      <path
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
        strokeWidth="0.5"
        d="M28.8,0.6c-0.7,0.1-1.5,0.6-2,1.1c-0.4,0.4-0.7,1-2.8,5l-2.3,4.5l-0.8,0.1c-0.4,0-1.1,0.2-1.5,0.3c-1.5,0.5-2.3,1-3.5,2.4
	c-2.4,2.6-4,4.1-5.3,5c-1.6,1-3.8,2-5.6,2.3c-1,0.2-1.2,0.3-1.7,0.8c-0.6,0.6-0.7,1.5-0.4,2.2c0.3,0.7,2.6,2.8,5.1,4.7
	c3.6,2.7,7.2,4.8,10.8,6.1c0.9,0.3,1.1,0.4,1.7,0.4c0.9,0,1.4-0.2,2-0.9c0.7-0.8,2.7-4.3,3.8-6.5c1.1-2.4,2.9-7.2,3.1-8.3
	c0.2-0.7,0.1-2.1-0.1-2.8c-0.1-0.3-0.3-0.8-0.5-1.2l-0.3-0.7l2.3-4.4c1.3-2.4,2.4-4.6,2.5-4.9c0.1-0.4,0.2-0.7,0.1-1.3
	c0-0.7-0.1-0.9-0.4-1.5c-0.5-1.2-1.6-2-2.8-2.2C29.7,0.5,29.4,0.5,28.8,0.6z M30.3,3c0.6,0.3,0.9,0.9,0.8,1.5c0,0.2-0.9,1.9-2.2,4.4
	c-1.2,2.2-2.1,4.1-2.1,4.1c0,0-0.2-0.1-0.4-0.2c-0.2-0.1-0.8-0.5-1.4-0.7l-1-0.5l0.3-0.6c1.1-2.2,3.9-7.6,4-7.7
	C28.8,2.9,29.7,2.7,30.3,3z M22.8,13.7c0.7,0.2,2.5,1.2,3,1.6c0.9,0.8,1.4,1.9,1.4,3.1c0,0.6-0.1,0.9-0.3,1.7c-0.2,0.5-0.4,1-0.4,1
	c0,0.1-9.3-4.7-9.5-4.9c0,0,0.7-0.9,1.2-1.4C19.4,13.6,21.2,13.2,22.8,13.7z M18.8,19.8c5.3,2.8,6.8,3.6,6.8,3.6
	c0.1,0.1-1.1,2.8-1.8,4.2c-1.2,2.4-2.8,5.3-3.2,5.4c-0.2,0.1-0.9-0.2-2.1-0.7c-0.4-0.1-0.7-0.3-0.8-0.3c-0.1,0-0.2-0.1-0.2-0.1
	c0,0,0.2-0.3,0.5-0.7c1.1-1.3,1.7-2.3,1.7-2.7c0-0.9-1-1.5-1.8-1c-0.1,0.1-0.6,0.6-0.9,1.1c-0.4,0.5-0.9,1.2-1.2,1.6l-0.5,0.6
	l-0.5-0.2c-0.9-0.5-3.4-2.1-3.4-2.2c0,0,0.2-0.2,0.4-0.4c0.5-0.4,0.8-0.8,0.8-1.2c0-0.5-0.2-0.8-0.7-1c-0.5-0.2-0.8-0.2-1.5,0.4
	c-0.3,0.2-0.7,0.5-0.9,0.6l-0.3,0.2l-1-0.8c-1.1-0.9-2.9-2.5-2.9-2.6c0,0,0.5-0.1,1-0.3c2.9-0.8,5.8-2.4,8-4.5
	c0.4-0.4,0.8-0.7,0.8-0.7C15.2,18,16.8,18.8,18.8,19.8z"
      />
    </Svg>
  );
};
/* eslint-enable max-len */
