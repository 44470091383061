import { createContext, ReactElement, useEffect } from 'react';
import { TBaseContext } from '../types';
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator';
import { TUser } from '../../common/types/user';
import { KeyedMutator, preload } from 'swr';
import { ProfileFetcher, useProfileGet } from '../ajax/profile';

preload(ProfileFetcher.key, ProfileFetcher.fetcher);

export type UserContextType = [TUser, SignOut, KeyedMutator<any>];
export const UserContext = createContext<UserContextType | null>(null);
UserContext.displayName = 'UserContext';

export default function UserProvider({
  children,
  signOut,
  ...props
}: TBaseContext & {
  signOut: SignOut;
}): ReactElement {
  const { data, error, mutate } = useProfileGet();

  useEffect(() => {
    if (error) {
      signOut({ global: true });
    }
  }, []);

  return (
    <UserContext.Provider value={[data, signOut, mutate]} {...{ ...props, children }}>
      {data ? children : null}
    </UserContext.Provider>
  );
}
