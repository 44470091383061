import { ReactElement } from 'react';
import { Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useRiders } from '../../../../stores/riders/useRiders';
import { FilterFieldProps } from '../../../../components/ListView/types';
import { useSessions } from '../../../../stores/sessions/useSessions';

export default function SessionSelect({ filter, setFilter }: FilterFieldProps): ReactElement {
  const [{ sessions, byId }] = useSessions();
  const [{ ridersById }] = useRiders();
  const onSessionChange = (event: any): void => {
    const value = event.target.value;

    if (value?.includes('all') || value.length === sessions.length) {
      const hasAll = filter.rider_id?.includes('all') ? [] : ['all'];
      setFilter({
        ...filter,
        testsession_id: hasAll,
        sessions: hasAll,
      });
    } else {
      setFilter({ ...filter, testsession_id: value, sessions: value?.map((id: string) => byId[id]?.description) });
    }
  };
  return (
    <>
      <InputLabel shrink id="select-four">
        Session
      </InputLabel>
      <Select
        labelId="select-one"
        id={'riders'}
        input={<OutlinedInput notched label={'Rider'} />}
        value={
          (filter.testsession_id?.includes('all') ? sessions.map((session) => session.id) : filter.testsession_id) ?? []
        }
        fullWidth
        multiple
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={(selected): string => {
          return selected.indexOf('all') > -1 || selected.length === sessions.length
            ? 'All'
            : selected.map((sessionId: string) => byId[sessionId].description).join(', ');
        }}
        onChange={onSessionChange}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={filter.testsession_id?.indexOf('all') > -1}
              indeterminate={filter.testsession_id?.length > 0 && filter.testsession_id?.length < sessions.length}
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {sessions.map((item, index: number) => {
          return (
            <MenuItem key={`riders_${index}`} value={item.id}>
              <ListItemIcon>
                <Checkbox
                  checked={filter.testsession_id?.indexOf(item.id) > -1 || filter.testsession_id?.indexOf('all') > -1}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {ridersById[item.rider_id || ''].nickname} - {item.description}
                  </>
                }
              />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}
