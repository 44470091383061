import { requestWithAxios, TRequestProps } from '../../utils/requestWithAxios';
import { TConfig } from '../../common/types/config';

interface TAjaxGroup extends Record<string, any> {
  groupId?: string;
  groups?: TConfig[];
  verb?: TRequestProps['verb'];
  tags?: string | string[] | null;
  controller?: AbortController;
}

export async function updateGroup({
  groupId,
  groups,
  tags,
  controller,
  verb = 'PUT',
  ...rest
}: TAjaxGroup): Promise<Record<string, any>> {
  let tagsArr = tags ?? [];

  if (typeof tags === 'string') {
    tagsArr = tags.split(',').map((tag: string) => tag.trim().replace(/\s/g, '_'));
  }

  const options: TRequestProps = {
    endpoint: !groupId || groups ? '/groups' : `/groups/${groupId}`,
    payload: groups ?? undefined,
    verb: verb,
    abortSignal: controller?.signal,
    ...{ ...rest, tags: tagsArr },
  };

  try {
    const resp = (await requestWithAxios(options)) || { data: [], error: {} };
    return resp?.data ? resp.data : resp;
  } catch (e: any) {
    const message = `URL: ${options.endpoint}! ${e}`;
    console.error(message);
    console.warn(options);
    return new Promise((resolve) => resolve({ errors: message }));
  }
}
