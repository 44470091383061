import { PropsWithChildren, ReactElement } from 'react';
import { iconSize } from './utils';
import { TCirrusIconProps } from './types';

interface TSvgProps extends Omit<TCirrusIconProps, 'viewBox'> {
  viewBox: [number, number, number, number];
}

export default function Svg({ children, size, viewBox }: PropsWithChildren<TSvgProps>): ReactElement {
  return (
    <svg x="0px" y="0px" width={iconSize(size)} height={iconSize(size)} viewBox={viewBox.join(' ')}>
      {children}
    </svg>
  );
}
