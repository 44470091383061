import Column from '../../layouts/Column';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

export default function PageNotFound(): ReactElement {
  const { pathname } = useLocation();
  return (
    <Column rows={2}>
      <h1>Path Not Found (Error 404)</h1>
      <div>Sorry but it seems you access wrong path: {pathname}</div>
    </Column>
  );
}
