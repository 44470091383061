/** @jsxImportSource @emotion/react */
import React, { CSSProperties, memo, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CirrusRoutes from '../../pages/_Routes/CirrusRoutes';
import useMuiTabs from '../../hooks/useMuiTabs';
import useCirrusLocation, { regexpNavTab } from '../../hooks/useCirrusLocation';
import { Hidden, Tooltip } from '@mui/material';
import { useUser } from '../../stores/user/useUser';

const Nav = memo(
  ({ padding, noLabels = false }: { padding?: CSSProperties['padding']; noLabels?: boolean }): ReactElement => {
    const navigate = useNavigate();
    const { pathname } = useCirrusLocation(regexpNavTab);

    const [tabValue, handleChange] = useMuiTabs({
      initialValue: pathname,
      navigate,
      navigationTabs: CirrusRoutes(),
    });
    const [user] = useUser();

    return user.show_config ? (
      <Tabs
        value={CirrusRoutes().find(({ path }) => path === tabValue) ? tabValue : false}
        classes={{
          root: `cr-bg-green-100 cr-min-h-[42px] ${noLabels && 'cr-max-h-[42px]'}`,
          indicator: 'cr-bg-green-500',
        }}
        onChange={handleChange}
        variant={'scrollable'}
        scrollButtons={'auto'}
        aria-label="navigation tabs: runs, configs, calibrations, view runs' data, view plot "
      >
        {CirrusRoutes().map(({ path, label, icon }, index) => (
          <Tab
            sx={{
              padding,
              fontFamily: 'Bakbak One',
              minWidth: 'auto',
              minHeight: 'auto',
            }}
            classes={{ root: 'cr-text-blue-900', selected: 'cr-text-green-500' }}
            key={path + '-' + index}
            label={!noLabels && <Hidden mdDown>{label}</Hidden>}
            icon={
              icon ? (
                <Tooltip className={'cr-z-999 cr-size-auto'} title={label}>
                  <IconWrap>{icon}</IconWrap>
                </Tooltip>
              ) : undefined
            }
            value={path}
          />
        ))}
        <Tab
          sx={{
            padding,
            minWidth: 'auto',
            minHeight: 'auto',
          }}
          classes={{ root: 'cr-text-blue-900 cr-hidden', selected: 'cr-text-green-500' }}
          value={'hidden'}
        />
      </Tabs>
    ) : (
      <>&nbsp;</>
    );
  },
);
Nav.displayName = 'Nav';
export default Nav;

export const IconWrap = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sx, ...rest } = props;
  return <div ref={ref} {...rest} />;
});
IconWrap.displayName = 'IconWrap';
