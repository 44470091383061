import { OneOrMany } from '../../common/types';
import { PropsWithChildren, ReactElement } from 'react';
import useSimpleToggle, { TSimpleToggle } from '../../hooks/useSimpleToggle';
import { TButtonProps } from '../../common/types/buttonProps';
import { withInjectedKeys } from '../../HOC/withInjectedKeys';
import { ActionGuard } from './index';

export type TActionGuard = PropsWithChildren<{
  open: boolean;
  message: OneOrMany<ReactElement> | string;
  toggle: TSimpleToggle['toggle'];
  onNo?: TButtonProps['onClick'];
  okOnly?: boolean;
  skip?: boolean;
  onYes: Required<Pick<TButtonProps, 'onClick'>>['onClick'];
}>;
export const useActionGuard = (
  initialToggle = false,
): {
  ActionGuard: ReturnType<typeof withInjectedKeys<TActionGuard, 'toggle' | 'open'>>;
  toggle: (args?: any) => void;
  on: boolean;
} => {
  const { on, toggle } = useSimpleToggle({ initial: initialToggle });
  return { ActionGuard: withInjectedKeys(ActionGuard, { toggle, open: on }), toggle, on };
};
