import { KeyOf } from '../common/types';

type TColumnStylesProps = {
  columns: any[];
  withIcon?: boolean;
  withSelectAll?: boolean;
};

class WidthClass {
  get date() {
    return 'cr-w-[80px] sm:cr-w-[120px]';
  }

  get rider() {
    return this.date;
  }

  get description() {
    return 'cr-w-6-12 sm:cr-w-auto';
  }

  get icon() {
    return 'cr-w-36';
  }

  get letter() {
    return this.icon;
  }

  get baseline() {
    return 'cr-w-[65px]';
  }

  get average() {
    return 'cr-w-[45px]';
  }

  get deltas() {
    return this.average;
  }
}

export const colWidthObject = new WidthClass();

export const widthByParam = (param?: KeyOf<typeof colWidthObject> | string) =>
  param ? colWidthObject[param as KeyOf<typeof colWidthObject>] : '';
export const columnWidths = ({ columns, withIcon, withSelectAll }: TColumnStylesProps): string => {
  return [
    withIcon ? '40px' : undefined,
    withSelectAll ? 'min-content' : undefined,
    ...columns.map(({ label }) => {
      switch (label) {
        case 'Date':
          return 'minmax(50px, 100px)';
        case 'Rider':
          return 'minmax(75px, 150px)';
        case 'Description':
        case 'Calibration':
        case 'Display Name':
        case 'Planned runs':
        case 'Edit':
          return 'minmax(150px, 1fr)';
        case 'next_run':
          return '2.5fr';
        case 'Run #':
        case 'Status':
          return 'minmax(0, 65px)';
        case 'Mass':
          return 'minmax(0, 0.5fr)';
        case 'Cfg':
        case 'Drag':
        case '+/-':
        case '_delete':
        case '_btn':
        case '_btn_edit':
          return 'minmax(50px, min-content)';
        case 'TZ':
        case 'Parent':
          return 'minmax(85px, min-content)';
        case 'view_result':
        case 'fix_run_numbers':
          return 'minmax(50px, max-content)';
        case ' ':
          return 'minmax(50px, min-content)';
        case 'Tags':
          return '33%';
        default:
          return 'minmax(0, 1fr)';
      }
    }),
  ].join(' ');
};
