import { FunctionComponent, ReactNode } from 'react';
import { StreamlinesThemeContext } from './index';
import useThemeUpdate from './useThemeUpdate';
import { themeMUI } from '../materialUi';
import { ThemeProvider } from '@mui/material';
import { Theme } from '../../common/interfaces';

interface StreamlinesThemeProviderProps {
  children: ReactNode | ReactNode[];
  overwriteTheme?: Theme;
}

const StreamlinesThemeProvider: FunctionComponent<StreamlinesThemeProviderProps> = ({ children, overwriteTheme }) => {
  return (
    <StreamlinesThemeContext.Provider value={useThemeUpdate()}>
      <ThemeProvider theme={themeMUI(overwriteTheme ?? useThemeUpdate().current)}>{children}</ThemeProvider>
    </StreamlinesThemeContext.Provider>
  );
};

export default StreamlinesThemeProvider;
