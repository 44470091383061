import { createContext, useContext } from 'react';
import { TSimpleToggle } from '../../hooks/useSimpleToggle';

export type TIsOnFunction = (index?: number) => boolean;
export type TAccordionContext = [TIsOnFunction, TSimpleToggle['toggle'], boolean?, number?];

const AccordionContext = createContext<TAccordionContext | null>(null);
AccordionContext.displayName = 'AccordionContext';

export function useAccordion(): TAccordionContext {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('Accordion must be used within AccordionWrapper');
  }
  return context;
}

export { AccordionContext };
