import { TTestSession } from '../../../common/types/session';
import { TTableColumn } from '../../../components/ListView/types';

export const sessionConfigColumns: TTableColumn[] = [
  { label: 'Date', param: 'date' },
  { label: 'Rider', param: 'rider_id' },
  { label: 'Description', param: 'description' },
  { label: '_icon', param: 'sleepSwitch' },
  { label: '_icon', param: 'icon' },
  { label: '_btn_edit', param: 'edit' },
];
export const sortSessions = (itemA: Partial<TTestSession>, itemB: Partial<TTestSession>): -1 | 1 | 0 => {
  return itemA.date === itemB.date
    ? 0
    : itemA.date && itemB.date && new Date(itemA.date) > new Date(itemB.date)
      ? -1
      : 1;
};
